import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {
  Alert, Container, Row, Col, Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Error from '../components/Error';
import Info from '../components/Info';
import LoaderButton from '../components/LoaderButton';
import { validateEmailAddress } from '../validators';
import { emailValidationError } from '../validationErrors';
import { handleFieldBlur, handleFieldChange, validateForm } from '../formMethods';


class ForgotPassword extends Component {
  validators = {
    email: (email) => {
      if (!validateEmailAddress(email)) {
        return emailValidationError;
      }

      return null;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      fieldValues: {
        email: ''
      },
      fieldErrors: {
        email: null
      },
      fieldsValidated: {
        email: false
      }
    };

    this.handleFieldBlur = handleFieldBlur.bind(this);
    this.handleFieldChange = handleFieldChange.bind(this);
    this.validateForm = validateForm.bind(this);

    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  sendPasswordResetEmail = async (event) => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({
      loading: true
    });

    const { fieldValues: { email } } = this.state;

    try {
      await Auth.forgotPassword(email);

      if (this.unmounted) {
        return;
      }

      this.setState({
        resetEmailSent: true,
        loading: false
      });
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        this.setState({
          fieldErrors: {
            email: 'Please check your email address for your Typo account'
          },
          loading: false
        });
      } else if (e.code === 'LimitExceededException') {
        this.setState({
          error: (
            <>
              <Alert.Heading>Limit exceeded</Alert.Heading>
              <p>
                You have reached the maximum password reset email sends for an allowed time period.
                If you have not received it, please check your spam folder or try again later.
              </p>
            </>
          ),
          loading: false
        });
      } else if (e.code === 'InvalidParameterException') {
        this.setState({
          error: (
            <>
              <Alert.Heading>Email not verified</Alert.Heading>
              <p>
                It looks like you have registered, but not verified your email address.
                Before you can reset your password you must verify it by following the link in our welcome email.
              </p>
              <p>
                If you have not received it, please&nbsp;
                <Link
                  to={{ pathname: '/login', state: { resendVerificationEmail: email } }}
                  className="text-danger"
                >
                  click here
                </Link>
                &nbsp;to get a new email sent.
              </p>
            </>
          ),
          loading: false
        });
      } else {
        this.setState({
          error: 'GenericError',
          loading: false
        });
      }
    }
  };

  render() {
    const {
      error, fieldErrors, fieldValues: { email }, fieldsValidated,
      loading, resetEmailSent
    } = this.state;

    return (
      <Container>
        <Row className="justify-content-center">
          <Col className="small-centered-container">
            {error ? (
              <>
                {error === 'GenericError' ? <Error /> : (
                  <Alert variant="danger">
                    {error}
                  </Alert>
                )}
              </>
            ) : (
              <>
                <h2>Reset your password</h2>
                {resetEmailSent ? (
                  <Info
                    variant="primary"
                    wrappingParagraph={false}
                    iconElement={<FontAwesomeIcon icon={faEnvelope} size="3x" />}
                    className="mt-4"
                    title="Email sent"
                  >
                    <p>Please check your inbox and follow the instructions on our email to reset your password.</p>
                  </Info>
                ) : (
                  <Form onSubmit={this.sendPasswordResetEmail} noValidate>
                    <Form.Group controlId="email" variant="large">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        autoFocus
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleFieldBlur}
                        isInvalid={fieldErrors.email !== null}
                        isValid={fieldsValidated.email && fieldErrors.email === null}
                      />
                      {fieldErrors.email && (
                        <Form.Control.Feedback type="invalid">
                          {fieldErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <LoaderButton
                      block
                      type="submit"
                      isLoading={loading}
                      text="Reset"
                      loadingText="Sending email…"
                    />
                  </Form>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ForgotPassword;
