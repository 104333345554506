import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Container, Row, Col
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { faCheck, faClock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Info from '../components/Info';
import SpinnerIcon from '../components/SpinnerIcon';
import { SUPPORT_EMAIL } from '../constants';
import './EmailVerification.scss';

const EmailVerification = ({ history, match }) => {
  const email = atob(match.params.email);
  const confirmationCode = match.params.code;
  const [errorCode, setErrorCode] = useState(null);
  const [resendingEmail, setResendingEmail] = useState(false);

  useEffect(() => {
    const verifyEmailAddress = async () => {
      try {
        await Auth.confirmSignUp(email, confirmationCode);

        history.push('/login', {
          emailVerified: true
        });
      } catch (e) {
        if (e.code) {
          setErrorCode(e.code);
        } else {
          setErrorCode('GenericError');
        }
      }
    };

    verifyEmailAddress();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resendEmail = async () => {
    setResendingEmail(true);
    setErrorCode(null);

    try {
      await Auth.resendSignUp(email);
      history.push('/login', {
        newUser: true
      });
    } catch (e) {
      setErrorCode('GenericError');
      setResendingEmail(false);
    }
  };

  const getErrorMessage = () => {
    switch (errorCode) {
      case 'ExpiredCodeException':
        return (
          <Info variant="danger" title="Link expired" icon={faClock}>
            Your verification link has expired,
            please&nbsp;
            <span
              role="button"
              tabIndex="0"
              onClick={resendEmail}
              onKeyDown={(e) => { if (e.keyCode === 13) resendEmail(); }}
              className="text-danger text-link"
            >
              <b>click here</b>
            </span> to get a new link sent to your inbox.
          </Info>
        );
      case 'NotAuthorizedException':
        return (
          <Info variant="success" title="Email verified successfuly" icon={faCheck}>
            Your email has already been verified.<br />
            <Link to="/login" className="text-success"><b>Click here</b></Link> to log in.
          </Info>
        );
      case 'CodeMismatchException':
        return (
          <Info variant="danger" title="Invalid link" icon={faCheck} wrappingParagraph={false}>
            <p>
              Your verification link is invalid,
              please check your inbox for a more recent verification email or&nbsp;
              <span
                role="button"
                tabIndex="0"
                onClick={resendEmail}
                onKeyDown={(e) => { if (e.keyCode === 13) resendEmail(); }}
                className="text-danger text-link"
              >
                <b>click here</b>
              </span> to get a new link sent.
            </p>
            <p>
              If the problem persists please
              contact <a href={`mailto:${SUPPORT_EMAIL}`} className="text-danger"><b>{SUPPORT_EMAIL}</b></a>.
            </p>
          </Info>
        );
      default:
        return (
          <Info variant="danger" title="Email verification error" icon={faExclamationTriangle}>
            An error occurred while validating your email address. Please try again later.
            If the problem persists please
            contact <a href={`mailto:${SUPPORT_EMAIL}`} className="text-danger"><b>{SUPPORT_EMAIL}</b></a>.
          </Info>
        );
    }
  };

  return (
    <Container className="email-verification">
      <Row className="justify-content-center">
        <Col sm={{ span: 5 }}>
          {errorCode ? getErrorMessage() : (
            <Row>
              <Col className="flex-grow-0">
                <SpinnerIcon spinDuration="1.1s" />
              </Col>
              <Col>
                {resendingEmail ? (
                  <>
                    <h2>Resending verification message</h2>
                    <p>Please wait a moment.</p>
                  </>
                ) : (
                  <>
                    <h2>Verifying your email address</h2>
                    <p>Please wait a moment.</p>
                  </>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

EmailVerification.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

export default EmailVerification;
