import React from 'react';
import { Badge } from 'react-bootstrap';
import {
  textFilter, selectFilter
} from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import formatDate from './formatDate';
import { LABEL_GOOD, LABEL_BAD } from '../../constants';
import ResultLabel from './ResultLabel';
import LabelEditor from '../LabelEditor';
import QualityLabelBadge from './QualityLabelBadge';

export const generateGetCellClasses = (field) => (column, row, rowIndex, colIndex) => {
  let cellClasses = 'selectable-cell';
  let fieldName = field;

  if (!colIndex) {
    return '';
  }

  fieldName = fieldName.replace('data.', '');

  if (row.qualityFeedback[fieldName] === LABEL_GOOD) {
    cellClasses += ' bg-good text-white';
  }

  if (row.qualityFeedback[fieldName] === LABEL_BAD) {
    cellClasses += ' bg-bad text-white';
  }

  if (row.hasMultivariateAnomalies) {
    cellClasses += ' bg-multivariate-anomaly';
  }

  return cellClasses;
};

export const generateGetCellInlineStyles = (field) => (cell, row) => {
  if (row.sourceOfErrors && row.sourceOfErrors.includes(field)) {
    return {
      border: '3px solid red'
    };
  }
  if (row.errorsFields.includes(field)) {
    return {
      border: '3px dotted orange'
    };
  }
  return {};
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const renderUserAction = (userAction) => (userAction && userAction !== '' ? (
  <span>
    <FontAwesomeIcon icon={faCircle} size="xs" color={userAction.toLowerCase() === 'fix' ? '#007bff' : 'red'} />
    &nbsp;{capitalize(userAction)}
  </span>
) : (
  <span>-</span>
));

export const getBaseColumns = (
  isAudit, showViewScreenshots, onScreenshotButtonClick, customLabelOptions,
  onRecordUpdate, trackedFilters
) => [
  {
    dataField: 'qualityLabel',
    text: 'Quality Label',
    formatter: (cell, row) => <QualityLabelBadge qualityLabel={row.qualityLabel} />,
    filter: selectFilter({
      placeholder: 'Any',
      options: {
        Good: 'Good',
        Bad: 'Bad',
        'Not Set': 'Not Set'
      },
      getFilter: (filter) => {
        trackedFilters['qualityLabel'] = filter; // eslint-disable-line no-param-reassign
      }
    }),
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
      <>
        {filterElement}<br />
        <div className="datagrid-header">
          {column.text}
          {sortElement}
        </div>
      </>
    ),
    editable: false
  },
  {
    dataField: 'tag',
    text: 'Tag',
    classes: 'typeahead-cell',
    headerClasses: 'tag-header',
    filter: textFilter({
      placeholder: ' ',
      getFilter: (filter) => {
        trackedFilters['tag'] = filter; // eslint-disable-line no-param-reassign
      },
      className: 'form-control-sm'
    }),
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
      <>
        {filterElement}<br />
        <div className="datagrid-header">
          {column.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {sortElement}
        </div>
      </>
    ),
    hidden: false,
    formatter: (cell, row) => (
      <div className="typeahead-row-container">
        <Badge variant="primary">{row.tag}</Badge>
      </div>
    ),
    editorRenderer: (editorProps, value, row) => (
      <LabelEditor
        {...editorProps} // eslint-disable-line react/jsx-props-no-spreading
        onUpdate={(selected) => {
          if (customLabelOptions.indexOf(selected) === -1) {
            customLabelOptions = [...customLabelOptions, selected]; // eslint-disable-line no-param-reassign
          }
          editorProps.onUpdate(selected);
          row.tag = selected; // eslint-disable-line no-param-reassign
          onRecordUpdate(row, customLabelOptions);
        }}
        onEsc={() => {
          editorProps.onBlur();
        }}
        onBlur={() => {
          editorProps.onBlur();
        }}
        value={value}
        options={customLabelOptions}
      />
    )
  },
  {
    dataField: 'createdAt',
    text: 'Typo Timestamp',
    formatter: (cell, row) => formatDate(new Date(row.createdAt)),
    editable: false,
    headerFormatter: (column) => (
      <>
        <div className="datagrid-header">
          {column.text}
        </div>
      </>
    )
  },
  {
    dataField: 'hasErrors',
    text: 'Result',
    formatter: (cell, row) => (
      <ResultLabel
        record={row}
        showViewScreenshots={showViewScreenshots}
        onScreenshotButtonClick={onScreenshotButtonClick}
      />
    ),
    editable: false,
    filter: selectFilter({
      placeholder: 'Any',
      options: {
        0: 'OK',
        1: 'Error'
      },
      getFilter: (filter) => {
        trackedFilters['hasErrors'] = filter; // eslint-disable-line no-param-reassign
      }
    }),
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
      <>
        {filterElement}<br />
        <div className="datagrid-header">
          {column.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {sortElement}
        </div>
      </>
    )
  },
  {
    dataField: 'userAction',
    text: 'User Action',
    formatter: (cell, row) => renderUserAction(row.userAction),
    filter: textFilter({
      placeholder: ' ',
      className: 'form-control-sm'
    }),
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
      <>
        {filterElement}<br />
        <div className="datagrid-header">
          {column.text}
          {sortElement}
        </div>
      </>
    ),
    editable: false,
    hidden: isAudit
  },
  {
    dataField: 'userFingerprintDisplay',
    text: 'User Fingerprint',
    filter: textFilter({
      placeholder: ' ',
      className: 'form-control-sm'
    }),
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
      <>
        {filterElement}<br />
        <div className="datagrid-header">
          {column.text}
          {sortElement}
        </div>
      </>
    ),
    editable: false,
    hidden: isAudit
  }
];
