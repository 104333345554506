import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import config from 'react-global-configuration';
import PropTypes from 'prop-types';
import { STATUS_POLL_MILLISECONDS } from '../constants';

class ClusterStatusIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthy: true,
      isLoading: true
    };

    this.updateInterval = null;
    this.unmounted = false;
  }

  componentWillUnmount() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    this.unmounted = true;
  }

  checkClusterStatus = async () => {
    const { clusterEndpoint } = this.props;
    try {
      if (!clusterEndpoint) {
        return;
      }
      const session = await Auth.currentSession();

      if (this.unmounted) return;

      const token = session.idToken.jwtToken;
      const url = `${config.get('clusterManagementEndpoint')}/status`;

      await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: STATUS_POLL_MILLISECONDS
      });

      if (this.unmounted) return;

      this.setState({
        healthy: true,
        isLoading: false
      });
    } catch (err) {
      this.setState({
        healthy: false,
        isLoading: false
      });
    }
  }

  componentDidMount = async () => {
    // TODO: Get tenant settings

    // Get the initial status for the cluster.
    await this.checkClusterStatus();

    if (this.unmounted) return;

    // Poll for cluster status.
    this.updateInterval = setInterval(() => this.checkClusterStatus(), STATUS_POLL_MILLISECONDS);
  }

  renderContent() {
    const { healthy } = this.state;
    return (
      healthy ? (
        <OverlayTrigger
          placement="bottom"
          key="tooltip-cluster-healthy"
          overlay={(
            <Tooltip id="tooltip-cluster-healthy">
              Cluster is&nbsp;<strong>online</strong>.
            </Tooltip>
          )}
        >
          <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle}>&nbsp;</FontAwesomeIcon>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="bottom"
          key="tooltip-cluster-unhealthy"
          overlay={(
            <Tooltip id="tooltip-cluster-unhealthy">
              Cluster appears to be&nbsp;<strong>offline</strong>.
            </Tooltip>
          )}
        >
          <FontAwesomeIcon style={{ color: 'red' }} icon={faExclamationCircle} />
        </OverlayTrigger>
      ));
  }

  render() {
    const { isLoading } = this.state;
    return (
      !isLoading ? this.renderContent() : <FontAwesomeIcon style={{ color: 'grey' }} icon={faCircle} />
    );
  }
}

ClusterStatusIcon.propTypes = {
  clusterEndpoint: PropTypes.string
};

ClusterStatusIcon.defaultProps = {
  clusterEndpoint: ''
};

export default ClusterStatusIcon;
