import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import InfoHelp from '../InfoHelp';

const RefreshDatagrid = ({ onClick }) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <InfoHelp dismissible={false}>
      Data has changed in the background.&nbsp;
      <Button size="sm" variant="primary" onClick={onClickHandler}>
        <FontAwesomeIcon icon={faSyncAlt} size="sm" />&nbsp;
        Refresh Data
      </Button>
    </InfoHelp>
  );
};

RefreshDatagrid.propTypes = {
  onClick: PropTypes.func
};

RefreshDatagrid.defaultProps = {
  onClick: undefined
};

export default RefreshDatagrid;
