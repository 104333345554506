function getExplanationsForRequestConfig(def) {
    var message = '';
    if (def.url === undefined && (def.method === 'POST' || def.method === 'GET')) {
        message = 'Capture Ajax requests using the ' + def.method + ' method for any URL. This is not recomended!.';
    } else if (def.method === undefined && def.url !== undefined) {
        message =  'Capture POST and GET ajax requests which matches the URL ' + def.url + '.';
    } else if (def.url !== undefined && (def.method === 'POST' || def.method === 'GET')) {
        message = 'Capture Ajax ' + def.method + ' requests which matches the URl ' + def.url + '.';
    }

    if (Array.isArray(def.validations) && def.validations.length > 0) {
        var validationMessages = def.validations.map(function (validationDef, i) {
            return ((i === 0)? ' Validate' : ' validate') + ' the field ' + validationDef.field + ' using the regex ' + validationDef.regex;
        });
        message += validationMessages.join(' and');
    }

    return message;
}

export default function (configString) {
    try {
        const NOT_CAPTURE_AJAX_REQUESTS_WHEN_ARRAY_EMPTY = 'Not capture Ajax requests as there aren\'t any definitions.';
        const CAPTURE_AJAX_REQUESTS_WHEN_UNDEFINED = 'Capture all Ajax requests since the request property is not defined.';
        const CAPTURE_FORMS_WHEN_UNDEFINED = 'Capture all Forms since the forms property is not defined.';
        const NOT_CAPTURE_FORMS_WHEN_ARRAY_EMPTY = 'Not capture Forms as there aren\'t any definitions.';

        let explanations = [];
        var config = eval('(' + configString + ')'); // eslint-disable-line

        if (config.requests === undefined) {
            explanations.push(CAPTURE_AJAX_REQUESTS_WHEN_UNDEFINED);
        }
        if (Array.isArray(config.requests)) {
            if (config.requests.length === 0) {
                explanations.push(NOT_CAPTURE_AJAX_REQUESTS_WHEN_ARRAY_EMPTY);
            } else {
                explanations = explanations.concat(config.requests.map(getExplanationsForRequestConfig));
            }
        }

        if (config.forms === undefined) {
            explanations.push(CAPTURE_FORMS_WHEN_UNDEFINED);
        }
        if (Array.isArray(config.forms)) {
            if (config.forms.length === 0) {
                explanations.push(NOT_CAPTURE_FORMS_WHEN_ARRAY_EMPTY);
            } else {
                config.forms.forEach(function (def) {
                    var message = 'Capture Forms matching CSS selector: ' + def.selector;
                    explanations.push(message);
                });
            }
        }

        if (config.takeScreenshot === undefined || config.takeScreenshot) {
            let message = 'Capture screenshots during Ajax requests or Forms submissions using ';
            message += (config.screenshotSelector === undefined) ? 'the whole document body' : 'the selector: ' + config.screenshotSelector;
            explanations.push(message);
        } else {
            explanations.push('Screenshots will be disabled.');
        }

        return explanations;
    }
    catch (err) {
        return [];
    }
}
