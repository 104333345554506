import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import InfoHelp from '../InfoHelp';

const ClearFilter = ({ onClick }) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <InfoHelp dismissible={false}>
      Table filtered. Clear filters to display all records.
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button size="sm" variant="secondary" onClick={onClickHandler}>
        <FontAwesomeIcon icon={faTrash} size="sm" />&nbsp;
        Clear
      </Button>
    </InfoHelp>
  );
};

ClearFilter.propTypes = {
  onClick: PropTypes.func
};

ClearFilter.defaultProps = {
  onClick: undefined
};

export default ClearFilter;
