import React from 'react';
import { Alert } from 'react-bootstrap';

const NoData = ({ variant, message }) => {
    variant = variant || 'secondary';
    message = message || 'No data available';
    return (
        <Alert variant={variant}>
            {message}
        </Alert>
    );

}

export default NoData;