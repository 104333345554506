import {
  CONTEXT_MENU_OPENED, DESELECT_CELL, MOVE_CURSOR, RESET_CELL_STATE, SELECT_CELL, SELECT_RECTANGLE
} from './datagridCellTypes';
import {
  getCellKey
} from './datagridCellUtils';


export default (state, action) => {
  switch (action.type) {
    case MOVE_CURSOR: {
      const { row, column } = action.payload;
      return {
        ...state,
        cursorPosition: {
          row,
          column
        },
        selectedCells: new Set()
      };
    }

    case DESELECT_CELL: {
      const { selectedCells } = state;
      const { row, column } = action.payload;

      const cellKey = getCellKey(row, column);
      if (!selectedCells.has(cellKey)) {
        return state;
      }

      const newSelectedCells = new Set(selectedCells);
      newSelectedCells.delete(cellKey);

      return {
        ...state,
        selectedCells: newSelectedCells,
        cursorPosition: {
          row,
          column
        }
      };
    }

    case SELECT_CELL: {
      const { cursorPosition, selectedCells } = state;
      const { row, column } = action.payload;

      const cellKey = getCellKey(row, column);

      const newSelectedCells = new Set(selectedCells).add(cellKey);

      if (cursorPosition) {
        newSelectedCells.add(getCellKey(cursorPosition.row, cursorPosition.column));
      }

      return {
        ...state,
        selectedCells: newSelectedCells,
        cursorPosition: {
          row,
          column
        }
      };
    }

    case SELECT_RECTANGLE: {
      const { cursorPosition, selectedCells } = state;
      const { to: providedTo } = action.payload;

      let from;
      let to;
      let startCursorPosition;

      // If no cell has been clicked yet, both "from" and "to" cursor positons are
      // set to the clicked cell.
      if (cursorPosition.row === null && cursorPosition.column === null) {
        startCursorPosition = {
          row: providedTo.row,
          column: providedTo.column
        };
      } else {
        startCursorPosition = { ...cursorPosition };
      }

      if (providedTo.row > startCursorPosition.row && providedTo.column >= startCursorPosition.column) {
        // 1st corner is top left, 2nd bottom right
        from = {
          row: startCursorPosition.row,
          column: startCursorPosition.column
        };

        to = {
          row: providedTo.row,
          column: providedTo.column
        };
      } else if (providedTo.row >= startCursorPosition.row && providedTo.column < startCursorPosition.column) {
        // 1st corner is top right, 2nd bottom left
        from = {
          row: startCursorPosition.row,
          column: providedTo.column
        };
        to = {
          row: providedTo.row,
          column: startCursorPosition.column
        };
      } else if (providedTo.row < startCursorPosition.row && providedTo.column < startCursorPosition.column) {
        // 1st corner is bottom right, 1st is top left
        from = {
          row: providedTo.row,
          column: providedTo.column
        };
        to = {
          row: startCursorPosition.row,
          column: startCursorPosition.column
        };
      } else {
        // 1st corner is  bottom left, 2nd is top right
        from = {
          row: providedTo.row,
          column: startCursorPosition.column
        };
        to = {
          row: startCursorPosition.row,
          column: providedTo.column
        };
      }

      const initialSelectedCells = selectedCells;

      const newSelectedCells = new Set(initialSelectedCells);
      for (let r = from.row; r <= to.row; r += 1) {
        for (let c = from.column; c <= to.column; c += 1) {
          const cellKey = getCellKey(r, c);
          newSelectedCells.add(cellKey);
        }
      }

      return {
        ...state,
        selectedCells: newSelectedCells
      };
    }

    case CONTEXT_MENU_OPENED: {
      const { selectedCells } = state;

      if (selectedCells.size === 0) {
        const { row, column } = action.payload;

        return {
          ...state,
          cursorPosition: {
            row,
            column
          }
        };
      }

      return state;
    }

    case RESET_CELL_STATE: {
      return {
        cellState: {},
        cursorPosition: {
          row: null,
          column: null
        },
        selectedCells: new Set(),
        rectangularSelections: []
      };
    }

    default:
      return state;
  }
};
