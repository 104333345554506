class AsyncValidationEvent {
  constructor(value, validatorFunction, validateResult) {
    this.canceled = false;

    this.validatorFunction = validatorFunction;
    this.returnValidateResult = validateResult;
    this.value = value;

    this.validate();
  }

  async validate() {
    try {
      const result = await this.validatorFunction(this.value);

      if (!this.canceled) {
        this.returnValidateResult(result);
      }
    } catch (e) {
      this.returnValidateResult('Network error: please verify your internet connection.');
    }
  }

  cancel() {
    this.canceled = true;
  }
}

export default AsyncValidationEvent;
