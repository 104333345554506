import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Col, Container, Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Auth, Hub } from 'aws-amplify';

import SpinnerIcon from '../components/SpinnerIcon';

const OAuthAuthentication = ({ history, userHasAuthenticated }) => {
  // Listen for Amplify Hub's messages
  // Must always receive two messages, one for signIn
  // and a separate one with the additional state data
  useEffect(() => {
    const signInAction = async () => {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const signupCompleted = user.attributes['custom:signup_completed'] !== undefined
        && user.attributes['custom:signup_completed'] === '1';

      userHasAuthenticated({
        authenticated: true,
        email: user.attributes.email,
        sub: user.attributes.sub,
        signupCompleted
      });
    };

    Hub.listen('auth', (authData) => {
      const searchParams = new URLSearchParams(window.location.search);
      const origin = /origin-microsoft$/.test(searchParams.get('state')) ? 'Microsoft' : 'Google';

      switch (authData.payload.event) {
        case 'signIn':
          signInAction();
          break;
        case 'emailAlreadyExists':
          history.push({
            pathname: '/login',
            state: {
              errorMessage: 'There is an existing Typo account with your email address. You must log '
              + `in the same way the account was created, it is not possible to use your ${origin} account. `
              + 'Please try logging in with an email and password instead.'
            }
          });
          break;
        default:
          break;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <>
            <SpinnerIcon
              className="mb-4 text-primary"
              spinDuration="1.1s"
              style={{ fontSize: '3.5em', marginTop: '0.3em' }}
            />
            <h2>Authenticating, please wait a moment.</h2>
          </>
        </Col>
      </Row>
    </Container>
  );
};

OAuthAuthentication.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired
};

export default OAuthAuthentication;
