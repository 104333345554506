import awsConfigs from './awsConfigs';

if (process.env.REACT_APP_AWS_ENVIRONMENT === undefined) {
  throw new Error(
    `You must define an REACT_APP_AWS_ENVIRONMENT environment variable.
Please add a .env file or add it to your shell context.`
  );
}

const awsConfigNames = Object.keys(awsConfigs);

if (!awsConfigNames.includes(process.env.REACT_APP_AWS_ENVIRONMENT)) {
  throw new Error(
    `REACT_APP_AWS_ENVIRONMENT must be one of ${awsConfigNames.join(', ')}.`
  );
}

const awsConfig = awsConfigs[process.env.REACT_APP_AWS_ENVIRONMENT];

export default awsConfig;
