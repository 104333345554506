import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AsyncDownloaderContext from '../../context/asyncDownloader/asyncDownloaderContext';
import { AUDIT_STATE_PROCESSING, dataExportFormats } from '../../constants';
import ModalOkCancel from '../ModalOkCancel';
import SpinnerIcon from '../SpinnerIcon';

const ExportDataDropdown = ({
  repositoryName, datasetName, auditId, auditState, auditName, disabled, filtersAndSorting
}) => {
  const { isGenerating: contextIsGenerating, startDownload } = useContext(AsyncDownloaderContext);
  const [partialDownloadConfirmationParams, setPartialDownloadConfirmationParams] = useState(false);

  const getDownloadParams = (fileType) => ({
    repositoryName,
    datasetName,
    fileType,
    auditId,
    auditName,
    filtersAndSorting
  });

  const isGenerating = (fileType) => contextIsGenerating(getDownloadParams(fileType));

  // Generate file download
  const download = (fileType) => {
    if (isGenerating(fileType)) return;

    if (auditState && auditState === AUDIT_STATE_PROCESSING) {
      setPartialDownloadConfirmationParams(getDownloadParams(fileType));
    } else {
      startDownload(getDownloadParams(fileType));
    }
  };

  const closeConfirmationModal = () => {
    setPartialDownloadConfirmationParams(false);
  };

  const confirmPartialDownload = (downloadParams) => {
    startDownload(downloadParams);
    setPartialDownloadConfirmationParams(false);
  };

  
  return (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle variant="primary" size="sm" disabled={disabled} >
          <FontAwesomeIcon icon={faDownload} />&nbsp;
          Export Data
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.entries(dataExportFormats).map(([fileType, options]) => {
            const generating = isGenerating(fileType);

            return (
              <Dropdown.Item onClick={() => download(fileType)} key={fileType} disabled={generating}>
                {isGenerating(fileType) ? (
                  <>
                    <SpinnerIcon spinDuration="1.1s" />
                  </>
                ) : (
                  <FontAwesomeIcon icon={options.icon} />
                )}
                &nbsp;{options.displayName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {partialDownloadConfirmationParams && (
        <ModalOkCancel
          cancelOnClick={closeConfirmationModal}
          title="Confirm Partial Export"
          message="The audit is currently processing.  Your export will contain partial results."
          onHide={closeConfirmationModal}
          okLabel="Export"
          okOnClick={() => { confirmPartialDownload(partialDownloadConfirmationParams); }}
          show
        />
      )}
    </>
  );
};

ExportDataDropdown.propTypes = {
  datasetName: PropTypes.string,
  repositoryName: PropTypes.string,
  auditId: PropTypes.number,
  auditName: PropTypes.string,
  auditState: PropTypes.string,
  filtersAndSorting: PropTypes.object

};

ExportDataDropdown.defaultProps = {
  datasetName: '',
  repositoryName: '',
  auditId: null,
  auditName: '',
  auditState: '',
  filtersAndSorting: {}
};

export default ExportDataDropdown;
