import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { parseCellKey } from '../../context/datagridCell/datagridCellUtils';
import { cellStatePropType, refPropType } from '../../prop-types';
import MenuItemContainer from './MenuItemContainer';

// Right click popup menu
const DatagridMenu = ({
  selectedCells, onMenuItemClick, tableRef
}) => useMemo(() => {
  // Object keys are row numbers
  // Object values are an array of cell numbers
  const individualSelectedCells = {};

  const getRowId = (rowNumber) => {
    const relativeRowIndex = (parseInt(rowNumber, 10)
      - (tableRef.paginationContext.currPage - 1) * tableRef.paginationContext.currSizePerPage);
    return tableRef.paginationContext.props.data[relativeRowIndex].id;
  };

  // Get individual selected cells, grouped by row
  selectedCells.forEach((cellKey) => {
    const { column, row } = parseCellKey(cellKey);
    const rowId = getRowId(parseInt(row, 10));

    if (individualSelectedCells[rowId] === undefined) {
      individualSelectedCells[rowId] = [];
    }

    individualSelectedCells[rowId].push(column);
  });

  const cellSelectionActive = Object.keys(individualSelectedCells).length > 0;
  return (
    <Menu id="datagrid_menu">
      <MenuItemContainer
        cellSelectionActive={cellSelectionActive}
        selectedCells={individualSelectedCells}
        onMenuItemClick={onMenuItemClick}
      />
    </Menu>
  );
}, [selectedCells]); // eslint-disable-line react-hooks/exhaustive-deps

DatagridMenu.propTypes = {
  selectedCells: cellStatePropType.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  tableRef: refPropType
};

DatagridMenu.defaultProps = {
  tableRef: undefined
};

export default DatagridMenu;
