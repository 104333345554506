import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const InfoHelp = ({ children, dismissible, variant, icon }) => (
  <Alert dismissible={dismissible} variant={variant}>
    <Row>
      <Col className="flex-grow-0">{icon}</Col>
      <Col>{children}</Col>
    </Row>
  </Alert>
);

InfoHelp.propTypes = {
  children: PropTypes.node,
  dismissible: PropTypes.bool,
  variant: PropTypes.string,
  icon: PropTypes.node
};

InfoHelp.defaultProps = {
  children: <></>,
  dismissible: false,
  variant: 'success',
  icon: <FontAwesomeIcon size="2x" icon={faInfoCircle} />
};

export default InfoHelp;
