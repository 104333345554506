import React from 'react';
import { API } from 'aws-amplify';

import { INVALID_FORMAT, REPOSITORY_ALREADY_EXISTS } from './errorCodes';
import PasswordValidationMessage from './components/PasswordValidationMessage';
import { existingRepositoryName, invalidRepositoryName, requiredFieldError } from './validationErrors';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
const containsLowercaseRegex = /(?=.*[a-z])/;
const containsUppercaseRegex = /(?=.*[A-Z])/;
const containsNumberRegex = /(?=.*\d)/;
const containsSpecialCharacterRegex = /(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,<>':;|_~`])/;

export const validateEmailAddress = (email) => emailRegex.test(email);

export const validateRequired = (value) => {
  if (!value.trim()) {
    return requiredFieldError;
  }

  return null;
};

export const passwordValidator = (password) => {
  const validations = {
    minLength: false,
    lowercaseLetter: false,
    uppercaseLetter: false,
    number: false,
    specialCharacter: false
  };

  if (password.length >= 8) {
    validations.minLength = true;
  }

  if (containsLowercaseRegex.test(password)) {
    validations.lowercaseLetter = true;
  }

  if (containsUppercaseRegex.test(password)) {
    validations.uppercaseLetter = true;
  }

  if (containsNumberRegex.test(password)) {
    validations.number = true;
  }

  if (containsSpecialCharacterRegex.test(password)) {
    validations.specialCharacter = true;
  }

  if (Object.keys(validations).every((key) => validations[key] === true)) {
    return null;
  }

  return (
    <PasswordValidationMessage validations={validations} />
  );
};

export const validateRepositoryName = async (repositoryName) => {
  try {
    await API.post('typo-dashboard', '/validate-repository', {
      body: {
        repositoryName
      }
    });
  } catch (e) {
    if (typeof (e.response) !== 'undefined' && e.response.status === 400) {
      if (!e.response.data.success) {
        let errorMessage = '';

        switch (e.response.data.errorCode) {
          case INVALID_FORMAT:
            errorMessage = invalidRepositoryName;
            break;
          case REPOSITORY_ALREADY_EXISTS:
            errorMessage = existingRepositoryName;
            break;
          default:
        }

        return errorMessage;
      }

      return '';
    }

    // Not an expected exception, probably network or server error
    throw (e);
  }

  // Validation successful
  return '';
};
