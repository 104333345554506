import React, {
  useContext, useEffect, useReducer, useRef
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import AsyncDownloaderContext from './asyncDownloaderContext';
import asyncDownloaderReducer from './asyncDownloaderReducer';
import DatasetService from '../../services/dataset';
import {
  START_DOWNLOAD, DOWNLOAD_GENERATION_STARTED, DOWNLOAD_GENERATION_COMPLETED,
  DOWNLOAD_RESUMED, START_DOWNLOADING, BROWSER_DOWNLOAD_STARTED,
  DOWNLOAD_ERROR, CLOSE_NOTIFICATION, CLEAR_STATE,
  DOWNLOAD_PENDING,
  STATE_NEW, STATE_PENDING, STATE_COMPLETED, STATE_EXPIRED
} from './asyncDownloaderTypes';
import localStorageContext from '../localStorage/localStorageContext';

const POST_DATASET_EXPORT_SUCCESS = 'POST_DATASET_EXPORT_SUCCESS';
const POST_AUDIT_EXPORT_SUCCESS = 'POST_AUDIT_EXPORT_SUCCESS';

// Store references to setIntervals
const intervalReferences = {};

// Give each generated download a unique identifier
let downloadCounter = 0;


const AsyncDownloaderStateProvider = ({ children }) => {
  const datasetService = new DatasetService(); // TODO: Use only one global instance of the service

  const initialState = {
    downloads: []
  };

  const [state, dispatch] = useReducer(asyncDownloaderReducer, initialState);
  const { getItem, setItem } = useContext(localStorageContext);

  // Prevent DOM modifications if user logs out (this
  // component is never unmounted through page navigation)
  const requestsCanceled = useRef(false);

  // Trigger error for download
  const dispatchError = (downloadData) => {
    dispatch({
      type: DOWNLOAD_ERROR,
      payload: {
        downloadData
      }
    });
  };

  const getNewDownloadId = () => {
    const newDownloadId = downloadCounter;

    downloadCounter += 1;

    return newDownloadId;
  };

  const confirmBrowserDownloadStarted = (downloadId) => {
    dispatch({
      type: BROWSER_DOWNLOAD_STARTED,
      downloadId
    });
  };

  const stopPollerForDownload = (downloadId) => {
    clearInterval(intervalReferences[`${downloadId}`]);
    delete intervalReferences[`${downloadId}`];
  };

  const getLocalStorageDownloads = () => {
    const storageCurrentDownloads = getItem('asyncDownloads');
    return storageCurrentDownloads ? JSON.parse(storageCurrentDownloads) : [];
  };

  const saveLocalStorageDownloads = (downloads) => {
    setItem('asyncDownloads', JSON.stringify(downloads));
  };

  const removeDownloadFromLocalStorage = (exportId) => {
    const storedDownloads = getLocalStorageDownloads();

    saveLocalStorageDownloads(storedDownloads.filter((download) => download.exportId !== exportId));
  };

  // Close (dismiss) notification for a download
  const closeNotification = (downloadId) => {
    const downloadData = state.downloads.find((download) => download.downloadId === downloadId);

    if (downloadData.resumed || downloadData.state === STATE_COMPLETED) {
      // If resumed, we'll make sure it doesn't open again when page reloads
      removeDownloadFromLocalStorage(downloadData.exportId);
    }

    dispatch({
      type: CLOSE_NOTIFICATION,
      downloadId
    });
  };

  // Download state polling function
  const pollDownloadState = async (downloadId, downloadData, exportId, resumed) => {
    if (requestsCanceled.current) return;

    let completed = false;
    let downloadUrl = '';

    try {
      const newDownloadState = downloadData.auditId
        ? await datasetService.getAuditExportStates(
          downloadData.repositoryName,
          downloadData.datasetName,
          downloadData.auditId,
          exportId
        ) : await datasetService.getDatasetExportStates(
          downloadData.repositoryName,
          downloadData.datasetName,
          exportId
        );


      // Check if component has been unmounted after await
      if (requestsCanceled.current) {
        return;
      }

      // Download completed
      if (newDownloadState.completed !== undefined && newDownloadState.completed) {
        completed = true;
        downloadUrl = newDownloadState.downloadUrl;

      // Download expired
      } else if (newDownloadState.state === STATE_EXPIRED) {
        // Stop poller
        stopPollerForDownload(downloadId);
        removeDownloadFromLocalStorage(exportId);

      // RESUMED download is now PENDING
      } else if (resumed && newDownloadState.state === STATE_PENDING) {
        dispatch({
          type: DOWNLOAD_PENDING,
          downloadId
        });
      }

      if (completed) {
        // Stop poller
        stopPollerForDownload(downloadId);

        // Mark as completed and add download URL
        dispatch({
          type: DOWNLOAD_GENERATION_COMPLETED,
          payload: {
            downloadUrl
          },
          downloadId
        });

        // Start downloading immediately
        if (!resumed) {
          dispatch({
            type: START_DOWNLOADING,
            downloadId
          });

          confirmBrowserDownloadStarted(downloadId);
        }
      }
    } catch {
      dispatchError(downloadId);
    }
  };

  // Start polling for a download
  const startDownloadPolling = (downloadData, downloadId, exportId, resumed) => {
    intervalReferences[`${downloadId}`] = setInterval(
      () => pollDownloadState(downloadId, downloadData, exportId, resumed),
      5000
    );
  };

  // Get downloads in progress from local storage and resume polling
  const resumeDownloads = () => {
    const storedDownloads = getLocalStorageDownloads();

    storedDownloads.forEach((download) => {
      const downloadId = getNewDownloadId();
      const { data, exportId } = download;

      dispatch({
        type: DOWNLOAD_RESUMED,
        payload: {
          downloadData: data
        },
        downloadId,
        exportId
      });

      startDownloadPolling(data, downloadId, exportId, true);
    });
  };

  // Save to local storage for resuming
  const saveDownloadToLocalStorage = (downloadData, exportId) => {
    const storedDownloads = getLocalStorageDownloads();

    storedDownloads.push({
      data: downloadData,
      exportId
    });

    saveLocalStorageDownloads(storedDownloads);
  };

  // Only resume downloads on component mount
  useEffect(() => {
    resumeDownloads();

    // Stop polling and prevent returning requests actions when unmounted
    return () => {
      Object.keys(intervalReferences).forEach((downloadId) => {
        clearInterval(intervalReferences[downloadId]);
        delete intervalReferences[downloadId];
      });
      requestsCanceled.current = true;
      dispatch({ type: CLEAR_STATE });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Start downloading
  const startDownload = async (downloadData) => {
    const downloadId = getNewDownloadId();

    dispatch({
      type: START_DOWNLOAD,
      payload: downloadData,
      downloadId
    });

    try {
      let response;

      const {
        auditId, datasetName, fileType, repositoryName, filtersAndSorting
      } = downloadData;

      if (auditId) {
        response = await datasetService.createAuditExport(
          repositoryName, datasetName, auditId, fileType, filtersAndSorting
        );
      } else {
        response = await datasetService.createDatasetExport(
          repositoryName, datasetName, fileType, filtersAndSorting
        );
      }

      if (requestsCanceled.current) return;

      if (response.code !== POST_DATASET_EXPORT_SUCCESS && response.code !== POST_AUDIT_EXPORT_SUCCESS) {
        dispatchError(downloadId);
        return;
      }

      const exportId = response.data.id;

      dispatch({
        type: DOWNLOAD_GENERATION_STARTED,
        payload: {
          exportId
        },
        downloadId
      });

      saveDownloadToLocalStorage(downloadData, exportId);

      startDownloadPolling(downloadData, downloadId, exportId, false);
    } catch {
      dispatchError(downloadId);
    }
  };

  const startResumedDownload = (downloadId) => {
    dispatch({
      type: START_DOWNLOADING,
      downloadId
    });
  };

  // Check if file is generating
  const isGenerating = (params) => state.downloads.some(
    (download) => isEqual(download.data, params)
      && [STATE_NEW, STATE_PENDING].includes(download.state)
  );

  // Render
  return (
    <AsyncDownloaderContext.Provider
      value={{
        downloads: state.downloads,
        closeNotification,
        isGenerating,
        startDownload,
        startResumedDownload
      }}
    >
      {children}
    </AsyncDownloaderContext.Provider>
  );
};

AsyncDownloaderStateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired
};

export default AsyncDownloaderStateProvider;
