import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Hub } from '@aws-amplify/core'; // eslint-disable-line import/no-extraneous-dependencies
import OAuth from '@aws-amplify/auth/lib-esm/OAuth/OAuth'; // eslint-disable-line import/no-extraneous-dependencies
import { parse } from 'url'; // Used for OAuth parsing of Cognito Hosted UI

import { unregister as unregisterServiceWoker } from './registerServiceWorker';
import './index.css';
import App from './App';
import awsConfig from './awsConfig';

// NOTE: This is a hack. Trying to use the original function generates many errors.
// IMPORTANT: If we ever update amplify we should check if the pre-signup lambda issue
// if fixed or check if we need to update this function with latest version.
//
// Amplify-js reported bug: https://github.com/aws-amplify/amplify-js/issues/4319
OAuth.prototype.handleAuthResponse = async function handleAuthResponse(currentUrl) {
  const urlParams = currentUrl
    ? ({
      ...(parse(currentUrl).hash || '#')
        .substr(1)
        .split('&')
        .map((entry) => entry.split('='))
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return (acc[k], acc);
        }, {}),
      ...(parse(currentUrl).query || '')
        .split('&')
        .map((entry) => entry.split('='))
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return (acc[k], acc);
        }, {})
    })
    : {};

  const { error, error_description: errorDescription } = urlParams;

  if (error) {
    setTimeout(
      () => {
        Hub.dispatch('auth', { event: 'emailAlreadyExists', data: {}, message: errorDescription });
      }, 0
    );
    return {};
  }

  const state = this._validateState(urlParams); // eslint-disable-line no-underscore-dangle

  if (this._config.responseType === 'code') { // eslint-disable-line no-underscore-dangle
    return { ...(await this._handleCodeFlow(currentUrl)), state }; // eslint-disable-line no-underscore-dangle
  }

  return { ...(await this._handleImplicitFlow(currentUrl)), state }; // eslint-disable-line no-underscore-dangle
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    oauth: {
      domain: awsConfig.cognito.OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${awsConfig.application.BASE_URL}/oauth-authentication`,
      redirectSignOut: `${awsConfig.application.BASE_URL}/oauth-logout`,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'typo-dashboard',
        endpoint: awsConfig.apiGateway.URL,
        region: awsConfig.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>, document.getElementById('root')
);

unregisterServiceWoker();
