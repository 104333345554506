import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { childPropsPropType } from '../prop-types';

const AppliedRoute = ({ component: C, props: childProps, ...rest }) => (
  <Route
    {...rest} // eslint-disable-line react/jsx-props-no-spreading
    render={(props) => {
      if (childProps.isAuthenticated && !childProps.signupCompleted) {
        return (
          <Redirect to="/complete-signup" />
        );
      }
      return (
        <C {...props} {...childProps} /> // eslint-disable-line react/jsx-props-no-spreading
      );
    }}
  />
);

AppliedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: childPropsPropType.isRequired
};

export default AppliedRoute;
