import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

export default class Loader extends Component {
    render() {
        return (
            <div className="jumbotron">
                <h4>{this.props.message || 'Loading...'}</h4>
                <ProgressBar animated now={100} ></ProgressBar>    
            </div>
        );
    }
}

