import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PlotContext from './plotContext';

const PlotStateProvider = ({ children }) => {
  const [plotPointHover, setPlotPointHover] = useState(null);
  const [pointDetailsHover, setPointDetailsHover] = useState(false);

  const showTimeout = useRef(null);
  const hideTimeout = useRef(null);

  const pointDetailsHoverRef = useRef(pointDetailsHover);

  const onHover = (e) => {
    const point = e.points[0]; // TODO: Navigate between plot points.
    setPlotPointHover(null);

    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }

    showTimeout.current = setTimeout(() => {
      setPlotPointHover(point);
    }, 300);
  };

  const onUnhover = () => {
    if (showTimeout.current !== null) {
      // Don't display the tooltip if the user left the point before it
      // was displayed
      clearTimeout(showTimeout.current);
      showTimeout.current = null;
    }

    hideTimeout.current = setTimeout(() => {
      // Don't hide it right away, let the user go into the tooltip
      if (!pointDetailsHoverRef.current) {
        // Don't hide it if the user went into the tooltip
        setPlotPointHover(null);
        setPointDetailsHover(null);
      }
    }, 300);
  };

  const onPointDetailsHover = () => {
    // Cancel hiding
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }

    setPointDetailsHover(true);
  };

  const onPointDetailsUnhover = () => {
    // Set hiding timeout
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }

    hideTimeout.current = setTimeout(() => {
      setPointDetailsHover(false);
      setPlotPointHover(null);
    }, 300);
  };

  return (
    <PlotContext.Provider
      value={{
        onHover,
        onUnhover,
        onPointDetailsHover,
        onPointDetailsUnhover,
        pointDetailsHover,
        plotPointHover
      }}
    >
      {children}
    </PlotContext.Provider>
  );
};

PlotStateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired
};

export default PlotStateProvider;
