// eslint-disable-next-line import/prefer-default-export
export const findSelectedRecordsIndexes = (records, selectedTypoIds) => {
  const indexes = [];
  selectedTypoIds.forEach((rec) => {
    const indexNumber = records.findIndex((r) => {
      if (typeof (rec) === 'number') {
        return r['__typo_id'] === rec;
      }

      return r === rec['__typo_id'];
    });

    if (indexNumber !== -1) {
      indexes.push(indexNumber);
    }
  });
  return indexes;
};
