import React, { Component } from 'react';
import {
  Alert, Button, Container, Row, Col, FormGroup, FormControl, FormLabel
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';

import Error from '../components/Error';
import Info from '../components/Info';
import AuthButton from '../components/AuthButton';
import LoaderButton from '../components/LoaderButton';
import './Login.css';
import { validateEmailAddress } from '../validators';
import { emailValidationError, requiredFieldError } from '../validationErrors';
import { handleFieldBlur, handleFieldChange, validateForm } from '../formMethods';
import SpinnerIcon from '../components/SpinnerIcon';

class Login extends Component {
  validators = {
    email: (email) => {
      if (!validateEmailAddress(email)) {
        return emailValidationError;
      }

      return null;
    },
    password: (password) => {
      if (!password) {
        return requiredFieldError;
      }

      return null;
    }

  }

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      resendingVerificationEmail: false,
      resendLimitError: false,
      resentVerificationEmail: false,
      // User tried to log in but hasn't confirmed email yet
      unconfirmedUser: false,
      unconfirmedUserEmail: '',
      userNotFoundError: false,
      fieldValues: {
        email: '',
        password: ''
      },
      fieldErrors: {
        email: null,
        password: null
      },
      fieldsValidated: {
        email: false,
        password: false
      }
    };

    this.handleFieldBlur = handleFieldBlur.bind(this);
    this.handleFieldChange = handleFieldChange.bind(this);
    this.validateForm = validateForm.bind(this);

    this.unmounted = false;
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.state && location.state.resendVerificationEmail) {
      this.resendVerificationEmail(location.state.resendVerificationEmail);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleChange = (event) => {
    const { fieldValues } = this.state;
    this.setState({
      fieldValues: {
        ...fieldValues,
        [event.target.id]: event.target.value
      }
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { userHasAuthenticated } = this.props;
    const { fieldValues: { email, password } } = this.state;

    this.setState({
      loading: true,
      resendLimitError: false,
      resentVerificationEmail: false,
      unconfirmedUser: false,
      unconfirmedUserEmail: '',
      userNotFoundError: false
    });

    try {
      const signInData = await Auth.signIn(email, password);
      if (this.unmounted) {
        return;
      }
      const { attributes: { sub } } = signInData;

      let signupCompleted = false;

      if (signInData.signInUserSession.idToken.payload['custom:signup_completed'] !== undefined
          && signInData.signInUserSession.idToken.payload['custom:signup_completed'] === '1') {
        signupCompleted = true;
      }

      userHasAuthenticated({
        authenticated: true, email, sub, signupCompleted
      });
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        this.setState({
          loading: false,
          unconfirmedUser: true,
          unconfirmedUserEmail: email
        });
      } else if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
        this.setState({
          loading: false,
          userNotFoundError: true
        });
      } else {
        this.setState({
          loading: false,
          error: true
        });
      }
    }
  }

  resetToLoginForm = () => {
    // Set the state as accessing Login page for the first time
    this.setState({
      resendLimitError: false,
      resentVerificationEmail: false,
      unconfirmedUser: false,
      unconfirmedUserEmail: '',
      userNotFoundError: false
    });
  }

  resendVerificationEmail = async (email) => {
    const { unconfirmedUserEmail } = this.state;

    const unconfirmedEmail = email || unconfirmedUserEmail;

    this.setState({
      resendingVerificationEmail: true
    });

    try {
      await Auth.resendSignUp(unconfirmedEmail);

      this.setState({
        resendingVerificationEmail: false,
        resentVerificationEmail: true,
        unconfirmedUser: false,
        unconfirmedUserEmail: ''
      });
    } catch (e) {
      if (e.code === 'LimitExceededException') {
        this.setState({
          resendingVerificationEmail: false,
          resentVerificationEmail: true,
          unconfirmedUser: false,
          unconfirmedUserEmail: '',
          resendLimitError: true
        });
      } else {
        this.setState({
          error: true
        });
      }
    }
  };

  signInWithGoogle = () => {
    Auth.federatedSignIn({ provider: 'accounts.google.com', customState: 'origin-google' });
  }

  signInWithMicrosoft = () => {
    Auth.federatedSignIn({ provider: 'Microsoft', customState: 'origin-microsoft' });
  }

  render() {
    const { location } = this.props;
    const {
      fieldValues: { email, password }, fieldErrors, fieldsValidated, error, loading,
      resendingVerificationEmail, resendLimitError, resentVerificationEmail, unconfirmedUser,
      userNotFoundError
    } = this.state;

    if (error) {
      return (
        <Container>
          <Row className="justify-content-center">
            <Col sm={{ span: 5 }}>
              <Error />
            </Col>
          </Row>
        </Container>
      );
    }

    if (resendLimitError) {
      return (
        <Container>
          <Row className="justify-content-center">
            <Col sm={{ span: 5 }}>
              <Alert variant="danger">
                <Alert.Heading>Too many attempts</Alert.Heading>
                <p>
                  You have reached the maximum verification email sends for an allowed time period.
                  If you have not received it, please check your spam folder or try
                  again later.
                </p>
                <Button
                  onClick={this.resetToLoginForm}
                  variant="danger"
                >
                  Back to Login
                </Button>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    if (resendingVerificationEmail) {
      return (
        <Container>
          <Row className="justify-content-center">
            <Col className="flex-grow-0">
              <SpinnerIcon spinDuration="1.1s" style={{ fontSize: '3em', marginTop: '0.3em' }} />
            </Col>
            <Col>
              <h2>Resending verification message</h2>
              <p>Please wait a moment.</p>
            </Col>
          </Row>
        </Container>
      );
    }

    if (unconfirmedUser) {
      return (
        <Container>
          <Row className="justify-content-center">
            <Col sm={{ span: 5 }}>
              <h2>Log In</h2>
              <Info title="Pending email verification" variant="primary" wrappingParagraph={false}>
                <p>
                  Your account has been created, but your email address is pending verification.
                </p>
                <p>
                  Please check your inbox and verify your email address by clicking on the link in the email.
                  If you have not received it, please check your spam folder or click on the following
                  button to resend the verification email.
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <LoaderButton
                    type="button"
                    isLoading={resendingVerificationEmail}
                    text="Resend verification email"
                    loadingText="Sending…"
                    onClick={() => { this.resendVerificationEmail(); }}
                  />
                  <Button
                    onClick={this.resetToLoginForm}
                    variant="link"
                  >
                    &laquo; Back to Login
                  </Button>
                </div>
              </Info>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row className="justify-content-center">
          <Col className="small-centered-container">
            <h2>Log In</h2>
            {location.state && location.state.errorMessage && (
              <Alert variant="danger">
                <p>{location.state.errorMessage}</p>
              </Alert>
            )}
            {location.state && location.state.newUser && !resentVerificationEmail && (
              <Info icon={faEnvelope} title="Email verification" variant="primary">
                We have just sent you an email address verification message. Please check your
                inbox and follow the link in the email to activate your account before logging in.
              </Info>
            )}
            {location.state && location.state.emailVerified && (
              <Info icon={faCheck} title="Email verified successfully" variant="success">
                Your email has been verified sucessfuly, you can now access the Typo Console by
                using the form below.
              </Info>
            )}
            {location.state && location.state.passwordReset && (
              <Info icon={faCheck} title="Password changed successfully" variant="success">
                Your password has been changed successfully, you can now access the Typo Console by
                using the form below.
              </Info>
            )}
            {resentVerificationEmail && (
              <Info icon={faEnvelope} title="Email verification" variant="primary">
                We sent you an email address verification message. Please check your
                inbox and follow the link in the email to activate your account before logging in.
              </Info>
            )}
            <form onSubmit={this.handleSubmit} noValidate>
              <FormGroup controlId="email" variant="large">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  value={email}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.email !== null}
                  isValid={fieldsValidated.email && fieldErrors.email === null}
                />
                {fieldErrors.email && (
                  <FormControl.Feedback type="invalid">
                    {fieldErrors.email}
                  </FormControl.Feedback>
                )}
              </FormGroup>
              <FormGroup controlId="password" variant="large">
                <FormLabel>Password</FormLabel>
                <FormControl
                  type="password"
                  value={password}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.password !== null}
                  isValid={fieldsValidated.password && fieldErrors.password === null}
                />
                {fieldErrors.password && (
                  <FormControl.Feedback type="invalid">
                    {fieldErrors.password}
                  </FormControl.Feedback>
                )}
              </FormGroup>
              {userNotFoundError && (
                <Alert variant="danger">
                  Wrong email and password combination. Please try again or click
                  on the Forgot password link to reset it.
                </Alert>
              )}
              <LoaderButton
                block
                type="submit"
                isLoading={loading}
                text="Login"
                loadingText="Logging in…"
              />
              <p className="text-center text-secondary mt-2 mb-2" style={{ fontSize: '0.8em' }}>OR</p>
              <AuthButton onClick={this.signInWithGoogle} type="google" text="Sign in with Google" />
              <AuthButton onClick={this.signInWithMicrosoft} type="microsoft" text="Sign in with Microsoft" />
              <div className="text-center mt-4">
                <Link to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired
};

export default Login;
