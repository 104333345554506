import React, { Component } from 'react';
import {
  Alert, Row, Col, Popover, OverlayTrigger, Tooltip, Breadcrumb
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar, faCog, faBug, faClone, faTasks, faTrash, faPlay
} from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import CustomPaginationPageListRenderer from '../components/CustomPaginationPageListRenderer';
import CustomPaginationTotalRenderer from '../components/CustomPaginationTotalRenderer';
import CustomSizePerPageRenderer from '../components/CustomSizePerPageRenderer';
import Error from '../components/Error';
import IconButton from '../components/IconButton';
import InfoHelp from '../components/InfoHelp';
import Info from '../components/Info';
import DatasetConfig from '../components/DatasetConfig';
import ModalOk from '../components/ModalOk';
import ModalOkCancel from '../components/ModalOkCancel';
import Loader from '../components/Loader';
import Title from '../components/Title';
import DatasetService from '../services/dataset';
import tableHeaderFormatterFactory from '../components/tableHeaderFormatterFactory';
import './Datasets.scss';

const POST_AUDIT_FAILED = 'POST_AUDIT_FAILED';
// Key codes in the word "superuser"
const acceptedKeyCodes = [69, 80, 82, 83, 85];


export default class Datasets extends Component {
  constructor(props) {
    super(props);

    this.datasetService = new DatasetService();

    this.state = {
      confirmDeleteDataset: null,
      deletingDataset: false,
      deleteDatasetError: null,
      datasets: [],
      isLoading: true,
      error: undefined,
      confirmAuditRunModal: null,
      displayAlgorithmConfiguration: false
    };

    this.unmounted = false;

    this.keyPressStack = '';
    this.keyPressTimeout = null;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getDatasets = async () => this.datasetService.getDatasetsDetailed();

  componentDidMount = async () => {
    document.addEventListener('keydown', this.handleKeyDown);
    this.fetchAllData();
  }

  fetchAllData = async () => {
    const { isLoading } = this.state;
    if (!isLoading) {
      this.setState({ isLoading: true });
    }

    try {
      const datasets = await this.getDatasets();

      if (this.unmounted) return;

      this.setState({
        datasets,
        isLoading: false
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        error: err
      });
    }
  }

  handleKeyDown = (e) => {
    if (this.keyPressTimeout != null) {
      clearTimeout(this.keyPressTimeout);
    }

    if (!acceptedKeyCodes.includes(e.keyCode)) {
      this.keyPressStack = '';
    } else {
      this.keyPressStack += String.fromCharCode(e.keyCode).toLowerCase();
      if (this.keyPressStack === 'superuser') {
        this.setState({
          displayAlgorithmConfiguration: true
        });
        this.keyPressStack = '';
      } else {
        this.keyPressTimeout = setTimeout(() => {
          this.keyPressStack = '';
        }, 5000);
      }
    }
  }

  updateDatasetParams = (datasets, repositoryName, datasetName, newParamObj) => {
    const newDatasets = datasets.map((dataset) => {
      if (dataset.repository.name === repositoryName && dataset.name === datasetName) {
        return { ...dataset, ...newParamObj };
      }
      return dataset;
    });

    return newDatasets;
  }

  confirmAuditRun = (repositoryName, datasetName) => {
    this.setState({
      confirmAuditRunModal: {
        repositoryName,
        datasetName
      }
    });
  }

  closeAuditRunModal = () => {
    this.setState({ confirmAuditRunModal: null });
  }

  runAudit = async (repositoryName, datasetName) => {
    const { datasets } = this.state;

    this.setState({
      confirmAuditRunModal: null,
      datasets: this.updateDatasetParams(datasets, repositoryName, datasetName, { has_pending_audits: true })
    });

    try {
      await this.datasetService.putAuditRecord(repositoryName, datasetName);

      if (this.unmounted) return;
    } catch (e) {
      if (typeof (e.response) !== 'undefined' && e.response.status === 409) {
        if (e.response.data.code === POST_AUDIT_FAILED) {
          // An audit is already running.
          // If we got here, it's because it wasn't mark as pending audits when it should.
          this.setState({
            datasets: this.updateDatasetParams(datasets, repositoryName, datasetName, { has_pending_audits: true })
          });
          alert('There are audits running or schedule to be run. '
            + 'Please wait until the audits complete to create more.');
        }
      } else {
        // Undo marking the row as pending audits
        this.setState({
          datasets: this.updateDatasetParams(datasets, repositoryName, datasetName, { has_pending_audits: false })
        });
        alert(e);
      }
    }
  }

  confirmDeleteDataset = (repositoryName, datasetName) => {
    this.setState({
      confirmDeleteDataset: {
        repositoryName,
        datasetName
      }
    });
  };

  closeConfirmDeleteDatasetModal = () => {
    this.setState({
      confirmDeleteDataset: null
    });
  }

  deleteDataset = async () => {
    const { confirmDeleteDataset: { repositoryName, datasetName } } = this.state;

    this.setState({
      deletingDataset: true
    });

    try {
      await this.datasetService.deleteDataset(repositoryName, datasetName);

      this.setState({
        confirmDeleteDataset: null,
        deletingDataset: false
      });
      this.fetchAllData();
    } catch (e) {
      let errorMessage = '';

      if (e.response) {
        if (e.response.status === 404) {
          errorMessage = (
            <Alert variant="danger">
              We were unable to find the selected dataset. It may have already been deleted.
              Please reload the page and try again.
            </Alert>
          );
        } else if (e.response.status === 409) {
          errorMessage = (
            <Alert variant="danger">
              <p>The selected dataset cannot be deleted at this time.</p>
              <p className="mb-0">{e.response.data.message}</p>
            </Alert>
          );
        }
      }

      if (!errorMessage) {
        errorMessage = (
          <Alert variant="danger">
            An error occurred while deleting the dataset. Please try again.
          </Alert>
        );
      }

      this.setState({
        confirmDeleteDataset: null,
        deletingDataset: false,
        deleteDatasetError: {
          errorMessage
        }
      });
    }
  }

  getEnabledDatasetModels = (dataset) => {
    const enabledModels = dataset.models.filter((model) => dataset.config.algorithms[model.algorithm_alias]);
    return enabledModels;
  }

  getOnDatasetConfigSave = (name) => async (conf) => {
    try {
      this.setState({
        isLoading: true
      });

      await this.datasetService.updateDatasetConfig(name, conf);

      if (this.unmounted) return;

      const datasets = await this.getDatasets();

      if (this.unmounted) return;

      this.setState({
        datasets
      });
    } catch (err) {
      alert('Error saving configuration');
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }

  getColumns = () => [
    {
      dataField: 'repository.name',
      text: 'Repository',
      editable: false,
      sort: true,
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'display_name',
      text: 'Dataset',
      classes: (cell, row) => (row.type === 'Web App' ? 'typeahead-cell' : ''),
      editable: (content, row) => row.type === 'Web App',
      sort: true,
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      headerFormatter: tableHeaderFormatterFactory(),
      formatter: (cell, row) => (
        <div className={row.type === 'Web App' ? 'typeahead-row-container' : ''}>
          {row.display_name}
        </div>
      )
    },
    {
      dataField: 'type',
      text: 'Type',
      editable: false,
      sort: true,
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'source',
      text: 'Source',
      editable: false,
      sort: true,
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      headerStyle: () => ({ width: '20%', textAlign: 'left' }),
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'total_records',
      text: 'Total Records',
      editable: false,
      sort: true,
      formatter: (cell, row) => row.total_records,
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'total_errors',
      text: 'Detected Errors',
      editable: false,
      sort: true,
      formatter: (cell, row) => (this.isAuditType(row) ? '—' : row.total_errors),
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'total_labels',
      text: 'Labeled (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_labels / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<span className="no-wrap">Labeled (%)</span>)
    },
    {
      dataField: 'total_bad_labels',
      text: 'Labeled Bad (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_bad_labels / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<div>Labeled<br /><span className="no-wrap">Bad (%)</span></div>)
    },
    {
      dataField: 'total_good_labels',
      text: 'Labeled Good (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_good_labels / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<div>Labeled<br /><span className="no-wrap">Good (%)</span></div>)
    },
    {
      dataField: 'total_actions',
      text: 'User Response (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_actions / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<div>User<br /><span className="no-wrap">Response (%)</span></div>)
    },
    {
      dataField: 'total_fixes',
      text: 'Fix (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_fixes / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<span className="no-wrap">Fix (%)</span>)
    },
    {
      dataField: 'total_ignores',
      text: 'Ignore (%)',
      editable: false,
      sort: true,
      formatter: (cell, row) => (
        this.isAuditType(row) ? '—' : ((row.total_ignores / row.total_records) * 100).toFixed(2)),
      headerFormatter: tableHeaderFormatterFactory(<span className="no-wrap">Ignore (%)</span>)
    },
    {
      dataField: 'id',
      text: 'Features',
      editable: false,
      sort: false,
      formatter: (cell, row) => (
        <>
          <OverlayTrigger overlay={(
            <Tooltip id="tooltip-duplicates-check">
              Duplicate detection&nbsp;
              <strong>{row.duplicate_checks.length > 0 ? 'is active' : 'is not configured'}</strong>.
            </Tooltip>
          )}
          >
            {row.duplicate_checks.length > 0
              ? <FontAwesomeIcon icon={faClone} color="green" />
              : <FontAwesomeIcon icon={faClone} color="gray" />}
          </OverlayTrigger>
          &nbsp;
          <OverlayTrigger overlay={(
            <Tooltip id="tooltip-duadditionalplicates-check">
              Error detection&nbsp;
              <strong>
                {this.getEnabledDatasetModels(row).length > 0
                  ? `is active with  ${this.getEnabledDatasetModels(row).length}/${row.models.length} models`
                  : 'requires additional data'}
              </strong>.
            </Tooltip>
          )}
          >
            {this.getEnabledDatasetModels(row).length > 0
              ? <FontAwesomeIcon icon={faBug} color="green" />
              : <FontAwesomeIcon icon={faBug} color="gray" />}
          </OverlayTrigger>
        </>
      ),
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'dummy',
      isDummyField: true,
      text: 'Actions',
      editable: false,
      sort: false,
      classes: 'actions-column',
      formatter: (cell, row) => (
        <div className="text-nowrap">
          <IconButton
            icon={faClone}
            label="Configure duplicate checks"
            to={`/duplicate-checks/${row.repository.name}/${row.name}`}
            exact
          />
          <span className="s-only">
            <OverlayTrigger
              ref="overlay" // eslint-disable-line react/no-string-refs
              trigger="click"
              placement="left"
              rootClose
              overlay={(
                <Popover id="popover-basic" title="Algorithm selection">
                  <DatasetConfig onSaveConfig={this.getOnDatasetConfigSave(row.name)} config={row.config} />
                </Popover>
              )}
            >
              <IconButton label="Algorithm selection" variant="success" icon={faCog} />
            </OverlayTrigger>
          </span>
          <IconButton
            icon={faChartBar}
            label="View datasets results"
            to={`/analytics/${row.repository.name}/${row.name}`}
            exact
          />
          {row.type !== 'Web App' && (
            <IconButton
              icon={faTasks}
              label="View Audits"
              to={`/audits/${row.repository.name}/${row.name}`}
              exact
            />
          )}
          {row.type !== 'Web App' && (
            <IconButton
              icon={faPlay}
              variant="success"
              label="Run Audit"
              disabled={row.has_pending_audits}
              onClick={() => { this.confirmAuditRun(row.repository.name, row.name); }}
            />
          )}
          <IconButton
            icon={faTrash}
            label="Delete Dataset"
            onClick={() => {
              this.confirmDeleteDataset(row.repository.name, row.name);
            }}
            variant="danger"
          />
        </div>
      ),
      headerFormatter: tableHeaderFormatterFactory()
    }
  ]

  isAuditType = (row) => row.type === 'Typo DI' // NOTE: Add here the other types that works as audits.


  updateDisplayName = async (row) => {
    await this.datasetService.updateDatasetDisplayName(row.repository.name, row.name, row.display_name);
  }

  renderDatasetsTable = () => {
    const { confirmAuditRunModal, datasets } = this.state;

    const paginationOptions = {
      sizePerPage: 25,
      sizePerPageList: [10, 25, 50, 100, 250, 500].map((num) => ({ text: num, value: num })),
      showTotal: true,
      pageListRenderer: CustomPaginationPageListRenderer,
      paginationTotalRenderer: CustomPaginationTotalRenderer,
      sizePerPageRenderer: CustomSizePerPageRenderer,
      alwaysShowAllBtns: true
    };
    const pagination = paginationFactory(paginationOptions);
    const columns = this.getColumns();

    return (datasets.length > 0) ? (
      <div className="bootstrap-table-wrapper two-row-headers with-header-separator">
        <BootstrapTable
          filter={filterFactory()}
          wrapperClasses="table-responsive"
          bootstrap4
          striped
          hover
          keyField="id"
          data={datasets}
          columns={columns}
          bordered
          pagination={pagination}
          cellEdit={cellEditFactory({
            mode: 'click',
            afterSaveCell: async (oldValue, newValue, row, column) => {
              if (column.dataField === 'display_name') {
                await this.updateDisplayName(row);
              }
            }
          })}
          condensed
          noDataIndication="There isn't any data to show."
        />
        {confirmAuditRunModal && (
          <ModalOkCancel
            cancelOnClick={this.closeAuditRunModal}
            title="Confirm Audit Run"
            message="Are you sure you want to run a new audit?"
            onHide={this.closeAuditRunModal}
            okLabel="Confirm"
            okOnClick={() => { this.runAudit(confirmAuditRunModal.repositoryName, confirmAuditRunModal.datasetName); }}
            show
          />
        )}
      </div>
    ) : (
      <Info title="No datasets found">
          There are no datasets available. Datasets will be created automatically during
          data capture or can be created through the REST API.
      </Info>
    );
  }

  renderContent() {
    const { isLoading } = this.state;

    return isLoading ? <Loader /> : this.renderDatasetsTable();
  }

  render() {
    const {
      confirmDeleteDataset, deletingDataset, deleteDatasetError,
      displayAlgorithmConfiguration, error
    } = this.state;

    return (
      <>
        <Row className={`dataset-listing${displayAlgorithmConfiguration ? ' s-visible' : ''}`}>
          <Col>
            <Breadcrumb>
              <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <LinkContainer to="/repositories">
                <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>Datasets</Breadcrumb.Item>
            </Breadcrumb>
            <Title text="Datasets" />
            <InfoHelp dismissible={false}>
              Datasets represent the data captured by Typo. Datasets are created
              automatically at the time the data is captured.
            </InfoHelp>
            {error !== undefined ? <Error /> : this.renderContent()}
          </Col>
        </Row>
        {confirmDeleteDataset && (
          <ModalOkCancel
            cancelOnClick={this.closeConfirmDeleteDatasetModal}
            title="Confirm Audit deletion"
            message="Are you sure you want to delete the audit? This action cannot be undone."
            onHide={this.closeConfirmDeleteDatasetModal}
            okButtonVariant="danger"
            okLabel={(
              <>
                <FontAwesomeIcon icon={faTrash} className="mr-1" />
                Confirm dataset deletion
              </>
            )}
            okOnClick={() => { this.deleteDataset(confirmDeleteDataset); }}
            show
            loaderButton
            loaderButtonLoading={deletingDataset}
          />
        )}
        {deleteDatasetError && (
          <ModalOk
            okLabel="Close"
            onOkClick={() => this.setState({ deleteDatasetError: null })}
            show
            title="Audit deletion error"
            message={deleteDatasetError.errorMessage}
          />
        )}
      </>
    );
  }
}
