import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import SpinnerIcon from './SpinnerIcon';
import {
  AUDIT_STATE_PROCESSING, AUDIT_STATE_SCHEDULED
} from '../constants';


const AuditStateIndicator = ({ state, totalAudited, totalRecords }) => {
  if (state === AUDIT_STATE_SCHEDULED) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={(
          <Tooltip id="tooltip-duplicates-check">
            Queued for processing
          </Tooltip>
        )}
      >
        <FontAwesomeIcon icon={faClock} className="text-secondary" />
      </OverlayTrigger>
    );
  }

  if (state === AUDIT_STATE_PROCESSING) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={(
          <Tooltip id="tooltip-duplicates-check">
            {(totalRecords === null || totalAudited === null) ? (
              <>Processing</>
            ) : (
              <>
                Processing<br />
                Generated {totalAudited} of {totalRecords} records.
              </>
            )}
          </Tooltip>
        )}
      >
        <SpinnerIcon className="text-warning" spinDuration="1.1s" />
      </OverlayTrigger>
    );
  }

  return <></>;
};

AuditStateIndicator.propTypes = {
  state: PropTypes.string.isRequired,
  totalAudited: PropTypes.number,
  totalRecords: PropTypes.number
};

AuditStateIndicator.defaultProps = {
  totalAudited: 0,
  totalRecords: 0
};

export default AuditStateIndicator;
