import React, {
  useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

import { columnTogglePropsPropType } from '../../prop-types';

const DatagridTableWrapper = (props) => {
  const { columnToggleProps, columnVisibility, passRef } = props;
  const { onColumnToggle } = columnToggleProps;

  // TODO: Is this useful? Get this from properties. Check older version.
  const tableRef = useRef(null);

  // Pass up the ref to DatagridTable only once
  useEffect(() => {
    passRef(tableRef.current);
  }, [passRef]);

  useEffect(() => {
    const processedColumnVisibility = {};
    Object.keys(columnVisibility).forEach(
      (key) => { processedColumnVisibility[key] = columnVisibility[key]; }
    );
    onColumnToggle(processedColumnVisibility);
  }, [columnVisibility, onColumnToggle]);

  return (
    <BootstrapTable
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      ref={(n) => { tableRef.current = n; }}
    />
  );
};

DatagridTableWrapper.propTypes = {
  columnToggleProps: columnTogglePropsPropType.isRequired,
  columnVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
  passRef: PropTypes.func.isRequired
};

// const hasNoChanges = (prevProps, nextProps) => {
//   // Don't rerender when the table is sorted
//   if (!isEqual(prevProps.sortingChangeCounter, nextProps.sortingChangeCounter)) {
//     return true;
//   }
//
//   return false;
// };

// TODO: Check this
// export default memo(DatagridTableWrapper, hasNoChanges);

export default DatagridTableWrapper;
