import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { TOGGLE_PLOT_ACTION, TOGGLE_STATS_ACTION, TOGGLE_RESULTS_MATRIX_ACTION } from './actions';
import { useStateValue } from './state';
import Title from '../Title';
import { computeTitle } from './reducer';

const DatasetAnalyticsTitle = () => {
  const [state, dispatch] = useStateValue();
  const {
    dataSelector, showPlots, showStats, showResultsMatrix
  } = state;

  return (
    <Title
      text={computeTitle(dataSelector)}
      toolbar={(
        <>
          <Button variant="light" onClick={() => dispatch({ type: TOGGLE_PLOT_ACTION })}>
            <FontAwesomeIcon icon={showPlots ? faToggleOn : faToggleOff} />&nbsp;Plot
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="light" onClick={() => dispatch({ type: TOGGLE_STATS_ACTION })}>
            <FontAwesomeIcon icon={showStats ? faToggleOn : faToggleOff} />&nbsp;
            Stats
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="light" onClick={() => dispatch({ type: TOGGLE_RESULTS_MATRIX_ACTION })}>
            <FontAwesomeIcon icon={showResultsMatrix ? faToggleOn : faToggleOff} />&nbsp;
            Results Matrix
          </Button>
        </>
      )}
    />
  );
};

export default DatasetAnalyticsTitle;
