import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

import { pagePropType } from '../prop-types';

const CustomPaginationPageListRenderer = ({ pages, onPageChange }) => (
  <Pagination className="col-md-6 col-xs-6 col-sm-6 col-lg-6 d-flex justify-content-end">
    {pages.map((page) => (
      <Pagination.Item
        key={page.page}
        active={page.active}
        disabled={page.disabled}
        onClick={() => { onPageChange(page.page); }}
      >
        {page.page}
      </Pagination.Item>
    ))}
  </Pagination>
);

CustomPaginationPageListRenderer.propTypes = {
  pages: pagePropType.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default CustomPaginationPageListRenderer;
