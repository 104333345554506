import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';

import './IconButton.css';
import { faIconPropType } from '../prop-types';

class IconButton extends Component {
  renderAsLink() {
    const { exact, to } = this.props;

    return (
      <LinkContainer to={to} className="icon-button" exact={exact}>
        {this.renderAsButton(true)}
      </LinkContainer>
    );
  }

  renderAsButton(asChild) {
    const {
      className, disabled, icon, onClick, variant
    } = this.props;

    return (
      <Button
        onClick={onClick}
        className={asChild ? className : 'icon-button'}
        variant={variant}
        disabled={disabled}
        size="sm"
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  }

  render() {
    const { label, placement, to } = this.props;

    return (
      label
        ? (
          <OverlayTrigger
            trigger="hover"
            placement={placement}
            overlay={<Tooltip id={'tooltip}'}>{label}</Tooltip>}
          >
            {to ? this.renderAsLink() : this.renderAsButton(false)}
          </OverlayTrigger>
        )
        : (
          <>
            {to ? this.renderAsLink() : this.renderAsButton(false)}
          </>
        )
    );
  }
}

IconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: faIconPropType.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  to: PropTypes.string,
  variant: PropTypes.string
};

IconButton.defaultProps = {
  className: '',
  disabled: false,
  exact: false,
  label: undefined,
  onClick: undefined,
  placement: 'left',
  to: undefined,
  variant: 'primary'
};

export default IconButton;
