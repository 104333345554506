import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIconPropType } from '../prop-types';

const Info = ({
  className, children, icon, iconElement, title, variant, wrappingParagraph
}) => (
  <Alert variant={variant} className={className}>
    <Row>
      {(icon || iconElement) && (
        <Col className="flex-grow-0 d-none d-sm-block">
          {icon && <FontAwesomeIcon icon={icon} size="3x" />}
          {iconElement && iconElement}
        </Col>
      )}
      <Col>
        {title && (
          <>
            <Alert.Heading>{title}</Alert.Heading>
            <hr />
          </>
        )}
        {wrappingParagraph ? (
          <p>
            {children}
          </p>
        ) : (
          <>{children}</>
        )}
      </Col>
    </Row>
  </Alert>
);

Info.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  icon: faIconPropType,
  iconElement: PropTypes.node,
  wrappingParagraph: PropTypes.bool
};

Info.defaultProps = {
  className: '',
  title: '',
  variant: 'info',
  icon: null,
  iconElement: null,
  wrappingParagraph: true
};

export default Info;
