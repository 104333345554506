import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import './LoaderButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const LoaderButton = ({
  isLoading, text, loadingText,
  className, disabled, ...props
}) => (
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {isLoading && <><FontAwesomeIcon icon={faSpinner} />&nbsp;</>}
    {!isLoading ? text : loadingText}
  </Button>
);

LoaderButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  loadingText: PropTypes.string.isRequired
};

LoaderButton.defaultProps = {
  className: '',
  disabled: false
};

export default LoaderButton;
