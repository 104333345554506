// Action types

export const CONTEXT_MENU_OPENED = 'CONTEXT_MENU_OPENED';

export const DESELECT_CELL = 'DESELECT_CELL';

export const MOVE_CURSOR = 'MOVE_CURSOR';

export const RESET_CELL_STATE = 'RESET_CELL_STATE';

export const SELECT_CELL = 'SELECT_CELL';

export const SELECT_RECTANGLE = 'SELECT_RECTANGLE';
