import React from 'react';
import PropTypes from 'prop-types';

import LocalStorageContext from './localStorageContext';


const LocalStorageStateProvider = ({ children, appClientId, sub }) => {
  const getLocalStorageKey = (itemName) => `typo.${appClientId}.${sub}.${itemName}`;

  // Local storage wrapper, separates all local storage per user
  const getItem = (itemName) => {
    if (!sub) return undefined;

    return localStorage.getItem(getLocalStorageKey(itemName));
  };

  const setItem = (itemName, value) => {
    if (!sub) return false;

    localStorage.setItem(getLocalStorageKey(itemName), value);
    return true;
  };

  return (
    <LocalStorageContext.Provider
      value={{
        getItem,
        setItem,
        sub
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

LocalStorageStateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  appClientId: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired
};

export default LocalStorageStateProvider;
