import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { getTypoLibScriptTag } from '../utils';
import './TypoLibInstructions.scss';

const TypoLibInstructions = ({ apiKey }) => {
  const code = getTypoLibScriptTag(apiKey);
  const [copied, setCopied] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => () => {
    unmounted.current = true;
  }, []);

  const onClipboardCopy = () => {
    setCopied(true);

    setTimeout(() => {
      if (!unmounted.current) {
        setCopied(false);
      }
    }, 2000);
  };

  return (
    <>
      <h4>1. Use on a Website</h4>

      <p className="mb-4">
        To start using this repository, please insert the following code between
        the <code>&lt;head&gt;</code> and <code>&lt;/head&gt;</code> tags of your
        sites.&nbsp;
      </p>

      <Row className="mb-4">
        <Col className="code-container">
          <div className="bg-info text-white">
            {code}
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="flex-grow-0">
          <CopyToClipboard text={code} onCopy={onClipboardCopy} variant="warning">
            <Button style={{ minWidth: '200px' }}>
              <FontAwesomeIcon icon={faCopy} /> {copied ? 'Copied!' : 'Copy to clipboard'}
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>
      <h4>2. Repository Configuration</h4>
      <p>
        You must configure the repository for each website to define on which requests
        and forms will Typo capture data from.
      </p>
      <p>
        <Link to={`/configurations/new?repository=${apiKey}`} className="btn btn-primary btn-secondary">
          <FontAwesomeIcon icon={faCog} />
          Configure Repository
        </Link>
      </p>
    </>
  );
};

TypoLibInstructions.propTypes = {
  apiKey: PropTypes.string.isRequired
};

export default TypoLibInstructions;
