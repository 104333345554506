import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';

import IconButton from './IconButton';
import { apiKeyPropType } from '../prop-types';
import './ApiKeyRow.scss';

class ApiKeyRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSecret: false
    };
  }

  showSecret = () => {
    this.setState({ showSecret: true });
  }

  hideSecret = () => {
    this.setState({ showSecret: false });
  }

  render() {
    const { showSecret } = this.state;
    const { apikey, deleteAction } = this.props;

    const secret = apikey.Attributes.find((attr) => attr.Name === 'custom:secret').Value;

    return (
      <tr>
        <td>{apikey.Username}</td>
        <td>
          <span className="secret-wrapper">
            <span className="secret-toggle">
              {showSecret ? (
                <FontAwesomeIcon icon={faEyeSlash} onClick={this.hideSecret} />
              ) : (
                <FontAwesomeIcon icon={faEye} onClick={this.showSecret} />
              )}
            </span>
            <span className="secret-value">
              <span className="secret-hidden">{secret}</span>
              <span className="secret-visible">
                {showSecret ? (
                  <>
                    {secret}
                  </>
                ) : (
                  <>
                    {'•'.repeat(secret.length)}
                  </>
                )}
              </span>
            </span>
          </span>
        </td>
        <td>{apikey.UserCreateDate}</td>
        <td>
          <IconButton
            variant="danger"
            label="Delete API Key"
            icon={faTrash}
            onClick={() => deleteAction(apikey.Username)}
          />
        </td>
      </tr>
    );
  }
}

ApiKeyRow.propTypes = {
  apikey: apiKeyPropType.isRequired,
  deleteAction: PropTypes.func.isRequired
};

export default ApiKeyRow;
