import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import LoaderButton from './LoaderButton';

const ModalOkCancel = ({
  cancelLabel, cancelOnClick, message, okButtonVariant, okLabel, okOnClick, onHide,
  show, title, size, loaderButton, loaderButtonLoading
}) => (
  <Modal size={size} show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer>
      <Button size="sm" variant="secondary" onClick={cancelOnClick}>{cancelLabel || 'Cancel'}</Button>
      {loaderButton ? (
        <LoaderButton
          size="sm"
          variant={okButtonVariant}
          onClick={okOnClick}
          isLoading={loaderButtonLoading}
          text={okLabel}
          loadingText="Deleting audit"
        />
      ) : (
        <Button size="sm" variant={okButtonVariant} onClick={okOnClick}>
          {okLabel}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

ModalOkCancel.propTypes = {
  cancelLabel: PropTypes.string,
  cancelOnClick: PropTypes.func.isRequired,
  loaderButton: PropTypes.bool,
  loaderButtonLoading: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  okButtonVariant: PropTypes.string,
  okLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  okOnClick: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string
};

ModalOkCancel.defaultProps = {
  cancelLabel: 'Cancel',
  loaderButton: false,
  loaderButtonLoading: false,
  message: '',
  okButtonVariant: 'primary',
  okLabel: 'OK',
  show: false,
  size: 'md',
  title: ''
};

export default ModalOkCancel;
