import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class DatasetConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalConfig: props.config,
      newConfig: this.cloneObject(props.config)
    };
  }

  canSave = () => {
    const { newConfig, originalConfig } = this.state;

    if (Object.keys(originalConfig.algorithms).some(
      (key) => newConfig.algorithms[key] !== originalConfig.algorithms[key]
    )) {
      return true;
    }
    return false;
  }

  handleSave = async () => {
    const { newConfig } = this.state;
    const { onSaveConfig } = this.props;
    await onSaveConfig(newConfig);
  }

  getCheckChangedHandler = (key) => (event) => {
    const value = event.target.checked;
    const { newConfig } = this.state;
    const updatedConfig = this.cloneObject(newConfig);
    updatedConfig.algorithms[key] = value;

    this.setState({
      newConfig: updatedConfig
    });
  };

  cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  render() {
    const { newConfig } = this.state;
    return (
      <Form>
        {Object.keys(newConfig.algorithms).map((key) => (
          <Form.Check
            onChange={this.getCheckChangedHandler(key)}
            checked={newConfig.algorithms[key]}
            type="checkbox"
            id={key}
            key={key}
            label={`${key}`}
          />
        ))}
        <hr />
        <Button size="sm" disabled={!this.canSave()} onClick={this.handleSave}>Save</Button>
      </Form>
    );
  }
}

DatasetConfig.propTypes = {
  config: PropTypes.shape({
    algorithms: PropTypes.object.isRequired
  }).isRequired,
  onSaveConfig: PropTypes.func.isRequired
};

export default DatasetConfig;
