import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import { datetimeFilterPropType } from '../prop-types';
import DatetimeFilterModal, { DATETIME_FILTERING_CONDITION_OPTIONS } from './DatetimeFilterModal';
import './DatetimeFilterTableHeader.scss';


class DatetimeFilterTableHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalVisible: false
    };

    this.closeTimeout = null;
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  openModal = (e) => {
    e.stopPropagation();
    this.setState({ modalOpen: true, modalVisible: true });
  }

  closeModal = () => {
    this.setState({ modalVisible: false });

    this.closeTimeout = setTimeout(() => {
      if (this.unmounted) return;

      this.setState({
        modalOpen: false
      });
    }, 3000);
  }

  render() {
    const { modalOpen, modalVisible } = this.state;
    const { applyFilter, appliedFilter } = this.props;

    return (
      <>
        <span // eslint-disable-line jsx-a11y/click-events-have-key-events
          className="datetime-filter-table-header d-block"
          role="button"
          onClick={this.openModal}
          tabIndex={0}
        >
          <span className="datetime-filter-header-button d-block">
            <FontAwesomeIcon icon={faCalendar} /> Filter
          </span>
          {appliedFilter && (
            <>
              {appliedFilter.map((filterRow, index) => (filterRow.condition && filterRow.datetime)
                && (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key
                    className="datetime-filter-table-header-value mb-0"
                  >
                    {DATETIME_FILTERING_CONDITION_OPTIONS[filterRow.condition]}:&nbsp;
                    {filterRow.datetime.format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                ))}
            </>
          )}
        </span>
        {modalOpen
          && (
            <DatetimeFilterModal
              closeModal={this.closeModal}
              appliedFilter={appliedFilter}
              applyFilter={applyFilter}
              modalVisible={modalVisible}
            />
          )}
      </>
    );
  }
}

DatetimeFilterTableHeader.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  appliedFilter: datetimeFilterPropType
};

DatetimeFilterTableHeader.defaultProps = {
  appliedFilter: null
};

export default DatetimeFilterTableHeader;
