import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';

export const SUPPORT_EMAIL = 'support@typo.ai';

export const DATA_SELECTION_TYPE_NONE = 'DATA_SELECTION_TYPE_NONE';
export const DATA_SELECTION_TYPE_DATASET = 'DATA_SELECTION_TYPE_DATASET';
export const DATA_SELECTION_TYPE_AUDIT = 'DATA_SELECTION_TYPE_AUDIT';

export const DATASET_AUDIT_TYPE = 'Typo DI';
export const DATASET_STREAMING_TYPE = 'Web App';

export const AUDIT_STATE_SCHEDULED = 'SCHEDULED';
export const AUDIT_STATE_PROCESSING = 'PROCESSING';
export const AUDIT_STATE_COMPLETED = 'COMPLETED';

export const PLOT_TYPE_HISTOGRAM = 'histogram';
export const PLOT_TYPE_BOXPLOT = 'boxplot';
export const PLOT_TYPE_SCATTER_2D = 'scatter_2d';
export const PLOT_TYPE_SCATTER_3D = 'scatter_3d';
export const PLOT_INLIER_COLOR = 'green';
export const PLOT_OUTLIER_COLOR = 'red';
export const PLOT_OUTLIER_NOT_XY_COLOR = 'orange';

// Dataset/audit row labels
export const LABEL_GOOD = 'Good';
export const LABEL_BAD = 'Bad';
export const LABEL_NOTSET = 'Not Set';

// Dataset/audit column types
export const COLUMN_TYPE_DATETIME = 'datetime';
export const COLUMN_TYPE_DATETIME_2 = 'date-time';
export const COLUMN_TYPE_TIMESTAMP = 'timestamp';
export const COLUMN_TYPE_INTEGER = 'integer';
export const COLUMN_TYPE_NUMBER = 'number';
export const COLUMN_TYPE_FLOAT = 'float';

export const STATUS_POLL_MILLISECONDS = 10000;
export const HEADER_POLL_MILLISECONDS = 2000;

// Data export formats
export const dataExportFormats = {
  csv: {
    icon: faFileCsv,
    displayName: 'CSV'
  },
  xlsx: {
    icon: faFileExcel,
    displayName: 'Excel (xlsx)'
  },
  'xlsx-formatted': {
    icon: faFileExcel,
    displayName: 'Formatted Excel (xlsx)'
  }
};
