import React, { useEffect, useState, useRef } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';

import { useStateValue } from './state';
import {
  fetchDatasetResultsPlot, fetchAuditResultsPlot, fetchDatasetResults, fetchAuditResults
} from './services';
import NoData from '../NoData';
import PlotInput from './PlotInput';
import PlotContext from '../../context/plot/plotContext';
import PlotPointHover from './PlotPointHover';
import PlotStateProvider from '../../context/plot/PlotStateProvider';
import PlotWrapper, { getPlotData } from './PlotWrapper';
import { PLOT_TYPE_SCATTER_2D, PLOT_TYPE_HISTOGRAM, PLOT_TYPE_BOXPLOT } from '../../constants';
import { isAuditSelection, isDatasetSelection, isNoneSelection } from './DataSelector';
import Loader from '../Loader';
import QualityLabelBadge, { formatQualityLabel } from './QualityLabelBadge';
import LabelEditor from '../LabelEditor';
import formatDate from './formatDate';
import ResultLabel from './ResultLabel';
import { generateGetCellClasses, generateGetCellInlineStyles } from './defaults';
import { SET_LOADING_SELECTED_RECORDS_ACTION, SET_SELECTED_RECORDS_ACTION } from './actions';
import { findSelectedRecordsIndexes } from './utils';
import './PlotContainer.scss';

function PlotContainer() {
  // NOTE: Plot container must render if, recordFields, xField, yField or plotType change.
  const [state, dispatch] = useStateValue();
  const unmounted = useRef(false);

  // Cleanup on unmount
  useEffect(() => () => {
    unmounted.current = true;
  }, []);

  const [plotDataState, setPlotDataState] = useState({
    plotData: null,
    isLoading: false
  });

  const [plotInput, setPlotInput] = useState({
    plotType: null,
    xField: null,
    yField: null
  });

  const [selectedRecordsId, setSelectedRecordsId] = useState([]);
  const [singlePointsSelected, setSinglePointsSelected] = useState([]);

  const { plotData, isLoading } = plotDataState;

  const {
    showPlots, records, dataSelector, filters, datetimeFilters, loadingSelectedRecords,
    schema, selectedRecords
  } = state;

  const { plotType, xField, yField } = plotInput;

  const getRecordFields = () => {
    let fields = [];
    if (records && records.length > 0) {
      const record = records[0];
      fields = Object.keys(record.data).map((f) => ({ id: f, name: f }));
    }
    return fields;
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const onPlotInputChange = (plotTypeObj, newXField, newYField) => {
    const newPlotType = plotTypeObj ? plotTypeObj.type : null;

    setPlotInput({
      plotType: newPlotType,
      xField: newXField,
      yField: newYField
    });
  };

  const updateSelectedPoints = (newSelectedPoints) => {
    const { plotData: originalPlotData, plotData: { data } } = plotDataState;
    let pointsFound = 0;

    const newData = data.map((trace) => {
      // Prevent unnecessarily cycling through all records if we have
      // already found all newSelectedPoints
      if (newSelectedPoints.length !== 0 && pointsFound === newSelectedPoints.length) {
        return trace;
      }

      if (trace.customdata.length > 0 && trace.customdata[0] !== null) {
        const traceIndexes = findSelectedRecordsIndexes(trace.customdata, newSelectedPoints);
        pointsFound += traceIndexes.length;

        if (traceIndexes.length > 0) {
          return {
            ...trace,
            selectedpoints: traceIndexes
          };
        }

        const ret = { ...trace };

        // If there are no longer selected points in this trace, remove the property
        if (Object.prototype.hasOwnProperty.call(ret, 'selectedpoints')) {
          delete ret.selectedpoints;
        }

        return ret;
      }

      return trace;
    });

    setPlotDataState({
      ...plotDataState,
      plotData: {
        ...originalPlotData,
        data: newData
      }
    });
  };

  const clearPointsSelection = () => {
    // Get all the records for the selected points.
    setSelectedRecordsId([]);
    setSinglePointsSelected([]);

    const action = {
      type: SET_SELECTED_RECORDS_ACTION,
      param: []
    };

    dispatch(action);
    updateSelectedPoints([]);
  };

  const clearSelectionButton = {
    name: 'Clear Selection',
    click: () => {
      clearPointsSelection();
    },
    icon: {
      width: 857.1,
      height: 1000,
      path: 'm857 350q0-87-34-166t-91-137-137-92-166-34q-96 0-183 41t-147 114q-4 6-4 13t5 11l76 77q6 5 14 5 9-1 13-7 '
        + '41-53 100-82t126-29q58 0 110 23t92 61 61 91 22 111-22 111-61 91-92 61-110 23q-55 '
        + '0-105-20t-90-57l77-77q17-16 8-38-10-23-33-23h-250q-15 0-25 11t-11 25v250q0 24 22 33 22 10 39-8l72-72q60 '
        + '57 137 88t159 31q87 0 166-34t137-92 91-137 34-166z',
      transform: 'matrix(1 0 0 -1 0 850)'
    }
  };

  const fetchPlotDataAndUpdateState = async () => {
    const isValidPlotInput = (plotInput) => {
      const { plotType, xField, yField } = plotInput;
      if ((plotType === PLOT_TYPE_HISTOGRAM && xField) || (plotType === PLOT_TYPE_BOXPLOT && xField)
        || (plotType === PLOT_TYPE_SCATTER_2D && xField && yField)) {
        return true;
      }
      return false;
    };

    if (!isValidPlotInput(plotInput)) {
      setPlotDataState({
        plotData: null,
        isLoading: false
      });
      return;
    }

    const fields = { x: xField, y: yField };
    const params = {
      filters,
      datetimeFilters,
      schema
    };

    const {
      selectionType, selectedRepository, selectedDataset, selectedAudit
    } = dataSelector;
    let result;

    if (isDatasetSelection(selectionType)) {
      setPlotDataState({
        ...plotDataState,
        isLoading: true
      });
      result = await fetchDatasetResultsPlot(selectedRepository.id, selectedDataset.id, params, fields);
    } else if (isAuditSelection(selectionType)) {
      setPlotDataState({
        ...plotDataState,
        isLoading: true
      });
      result = await fetchAuditResultsPlot(selectedRepository.id, selectedDataset.id, selectedAudit.id, params, fields);
    } else {
      setPlotDataState({
        ...plotDataState,
        plotData: null,
        isLoading: false
      });
      return;
    }

    if (unmounted.current) return;

    const plotRecords = result.data.records.map((record) => ({
      __typo_id: record['__typo_id'],
      errorsFields: record['errors_fields'],
      hasErrors: record['has_errors'],
      qualityLabel: record['quality_label'],
      [xField]: record[xField],
      [yField]: record[yField]
    }));

    // TODO: selectedRecord, null & newSelectedRecords should be removed as are unnecessary
    const newSelectedRecords = [];
    const selectedRecord = null;
    const newPlotData = getPlotData(plotRecords, plotType, fields, selectedRecord, null, newSelectedRecords);

    setPlotDataState({
      plotData: newPlotData,
      isLoading: false
    });
  };

  const handlePointsSelected = async (obj, singlePoint) => {
    if (obj === undefined || plotType !== PLOT_TYPE_SCATTER_2D) return;

    const setLoadingAction = {
      type: SET_LOADING_SELECTED_RECORDS_ACTION,
      param: true
    };

    dispatch(setLoadingAction);

    let newRecordIdList = [];

    if (singlePoint) {
      const id = obj.points[0].customdata['__typo_id'];

      let newSinglePoints = [];

      if (selectedRecordsId.includes(id)) {
        // Remove point
        newSinglePoints = singlePointsSelected.filter((recordId) => recordId !== id);
      } else {
        // Add point
        newSinglePoints = [...singlePointsSelected, id];
      }

      updateSelectedPoints(newSinglePoints);
      setSinglePointsSelected(newSinglePoints);
      newRecordIdList = newSinglePoints;
    } else {
      newRecordIdList = obj.points.map((p) => p.customdata['__typo_id']);
      setSinglePointsSelected([]);
    }

    const allSelectedRecordsId = newRecordIdList.slice(0, 100);

    // TODO: Convert timestamp fields?
    // TODO: Set the unique errors fields
    const transformRecords = (newRecords) => newRecords.map((rec) => (
      {
        id: rec['id'],
        createdAt: rec['created_at'],
        data: rec['record'] || rec['plain_record'],
        qualityLabel: formatQualityLabel(rec['quality_label']),
        qualityFeedback: rec['quality_feedback'] || {},
        userAction: rec['user_action'],
        userFingerprintDisplay: rec['username'] || rec['user_fingerprint'],
        userFingerprint: rec['user_fingerprint'],
        tag: rec['tag'],
        errorsFields: rec['errors_fields'] === null ? [] : rec['errors_fields'],
        sourceOfErrors: rec['source_of_errors'] || [],
        resultLabel: rec['has_errors'] ? 'Error' : 'OK',
        hasErrors: rec['has_errors'],
        processedModels: rec['processed_models'],
        totalModels: rec['total_models'],
        screenshotsCount: rec['screenshots_count']
      }
    ));

    const fetchRecords = async (repository, dataset, audit, params, selectionType) => {
      if (!selectionType || isNoneSelection(selectionType)) {
        return null;
      }

      let result;

      if (isDatasetSelection(selectionType)) {
        result = await fetchDatasetResults(repository, dataset, params);
      } else if (isAuditSelection(selectionType)) {
        result = await fetchAuditResults(repository, dataset, audit, params);
      }

      const totalSize = result.data['total_records'];
      const finalRecords = transformRecords(result.data.records);

      return { records: finalRecords, totalSize };
    };

    const fetchData = async (params) => {
      const {
        selectedRepository, selectedDataset, selectedAudit, selectionType
      } = dataSelector;

      if (isNoneSelection(selectionType)) {
        return null;
      }

      const repository = selectedRepository ? selectedRepository.id : null;
      const dataset = selectedDataset ? selectedDataset.id : null;
      const audit = selectedAudit ? selectedAudit.id : null;
      return fetchRecords(repository, dataset, audit, params, selectionType);
    };

    setSelectedRecordsId(allSelectedRecordsId);
    const recordsIdFilter = [{
      op: 'in',
      field: '__typo_id',
      value: Array.from(allSelectedRecordsId)
    }];

    const params = {
      page: 1,
      sizePerPage: 100,
      filters: recordsIdFilter,
      datetimeFilters: {},
      schema: {}
    };

    // Get all the records for the selected points.
    const { records: newRecords } = await fetchData(params);
    if (unmounted.current) return;

    const action = {
      type: SET_SELECTED_RECORDS_ACTION,
      param: newRecords
    };

    dispatch(action);
  };

  const handleSinglePointSelected = (obj) => {
    handlePointsSelected(obj, true);
  };

  const renderUserAction = (userAction) => (userAction && userAction !== '' ? (
    <span>
      <FontAwesomeIcon icon={faCircle} size="xs" color={userAction.toLowerCase() === 'fix' ? '#007bff' : 'red'} />
      &nbsp;{capitalize(userAction)}
    </span>
  ) : (
    <span>-</span>
  ));

  const getBaseColumns = (
    isAudit, showViewScreenshots, onScreenshotButtonClick, customLabelOptions, onRecordUpdate
  ) => [
    {
      dataField: 'qualityLabel',
      text: 'Quality Label',
      formatter: (cell, row) => <QualityLabelBadge qualityLabel={row.qualityLabel} />,
      headerFormatter: (column, colIndex, { sortElement }) => (
        <>
          <div className="datagrid-header">
            {column.text}
            {sortElement}
          </div>
        </>
      ),
      editable: false
    },
    {
      dataField: 'tag',
      text: 'Tag',
      classes: 'typeahead-cell',
      headerClasses: 'tag-header',
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <>
          {filterElement}<br />
          <div className="datagrid-header">
            {column.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {sortElement}
          </div>
        </>
      ),
      hidden: false,
      formatter: (cell, row) => (
        <div className="typeahead-row-container">
          <Badge variant="primary">{row.tag}</Badge>
        </div>
      ),
      editorRenderer: (editorProps, value, row) => (
        <LabelEditor
          {...editorProps} // eslint-disable-line react/jsx-props-no-spreading
          onUpdate={(selected) => {
            if (customLabelOptions.indexOf(selected) === -1) {
              customLabelOptions = [...customLabelOptions, selected];
            }
            editorProps.onUpdate(selected);
            row.tag = selected;
            onRecordUpdate(row, customLabelOptions);
          }}
          onEsc={() => {
            editorProps.onBlur();
          }}
          onBlur={() => {
            editorProps.onBlur();
          }}
          value={value}
          options={customLabelOptions}
        />
      )
    },
    {
      dataField: 'createdAt',
      text: 'Typo Timestamp',
      formatter: (cell, row) => formatDate(new Date(row.createdAt)),
      editable: false,
      headerFormatter: (column) => (
        <>
          <div className="datagrid-header">
            {column.text}
          </div>
        </>
      )
    },
    {
      dataField: 'hasErrors',
      text: 'Result',
      formatter: (cell, row) => (
        <ResultLabel
          record={row}
          showViewScreenshots={showViewScreenshots}
          onScreenshotButtonClick={onScreenshotButtonClick}
        />
      ),
      editable: false,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
        <>
          {filterElement}<br />
          <div className="datagrid-header">
            {column.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {sortElement}
          </div>
        </>
      )
    },
    {
      dataField: 'userAction',
      text: 'User Action',
      formatter: (cell, row) => renderUserAction(row.userAction),
      headerFormatter: (column, colIndex, { sortElement }) => (
        <>
          <div className="datagrid-header">
            {column.text}
            {sortElement}
          </div>
        </>
      ),
      editable: false,
      hidden: isAudit
    },
    {
      dataField: 'userFingerprintDisplay',
      text: 'User Fingerprint',
      headerFormatter: (column, colIndex, { sortElement }) => (
        <>
          <div className="datagrid-header">
            {column.text}
            {sortElement}
          </div>
        </>
      ),
      editable: false,
      hidden: isAudit
    }
  ];

  const createFieldColumn = (columnName) => {
    const column = {
      dataField: `data.${columnName}`,
      text: columnName,
      classes: generateGetCellClasses(columnName),
      style: generateGetCellInlineStyles(columnName),
      events: {
        onContextMenu: (e, column, colIndex, row, rowIndex) => {
          //this.handleContextMenu(e, column, colIndex, row, rowIndex);
        }
      },
      editable: false,
      sort: true,
      sorting: true,
      formatter: (cell, row, rowIndex) => (
             <div className="datagrid-cell">
             {cell && <span className="cell-content">{cell}</span>}
             {!cell && <span className="cell-spacer">&nbsp;</span>}
           </div>
      )
    };

    column.headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
      <div className="datagrid-header-wrapper">
        {filterElement}<br />
        <div className="datagrid-header sortable">
          {column.text}
          {sortElement}
        </div>
      </div>
    );  

    return column;
  }

  const getUniqueUserLabels = () => {
    return [];
  }

  const getColumns = (records, schema, hasFilters, isAudit) => {
    const customLabelOptions = getUniqueUserLabels(records);
    const defaultColumns = getBaseColumns(isAudit, null, null, customLabelOptions, (row, updatedCustomLabelOptions) => {
      this.updateRecord(row, true)
      this.customLabelOptions = updatedCustomLabelOptions;
    });

    const record = records.length > 0 && records[0].data;
    if (record) {
      const recordColumns = Object.keys(record).map((columnName) => createFieldColumn(columnName));
      const allColumns = defaultColumns.concat(recordColumns);
      return allColumns;
    }
    if (records.length === 0) {
      // NOTE: Keep columns when table is empty and filters were applied.
      if (hasFilters > 0 && this.latestColumns) {
        return this.latestColumns;
      }
      // NOTE: This is important to enable sorting. There is a bug in the BootstrapTable
      // component that does not creates the SortConetxt if we do not set an initial column.
      const dummyColumns = [{ dataField: '', text: '', sort: true }];
      return dummyColumns; // NOTE: Default column. Check how we can set no columns at all.
    }
  };

  useEffect(() => {
    fetchPlotDataAndUpdateState();
  }, [plotInput, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSelectedRecords = () => {
    if (loadingSelectedRecords) {
      return (
        <Loader />
      );
    }

    if (!(selectedRecords && selectedRecords.length > 0)) {
      return <></>;
    }

    const isAudit = false;

    const selectedRecordsColumns = getColumns(selectedRecords, schema, false, isAudit);

    return (
      <>
        <Row><Col><h5>Selected Records ({selectedRecords.length})</h5></Col></Row>
        <Row>
          <Col className="datagrid-wrapper selected-records-table">
            <BootstrapTable
              bootstrap4
              wrapperClasses="table-responsive"
              hover
              condensed
              striped
              keyField="id"
              columns={selectedRecordsColumns}
              data={selectedRecords}
            />
          </Col>
        </Row>
        <p>&nbsp;</p>
      </>
    );
  };

  if (showPlots) {
    const fields = getRecordFields();
    const noData = fields.length === 0;

    const { selectedRepository, selectedDataset, selectedAudit } = dataSelector;
    const repository = selectedRepository ? selectedRepository.id : null;
    const dataset = selectedDataset ? selectedDataset.id : null;
    const auditId = selectedAudit ? selectedAudit.id : null;

    // NOTE: Initially plot data will be null
    return (
      <>
        <Row><Col><h5>Plots</h5></Col></Row>
        {noData && <NoData message="No data available. Please select a Dataset or Audit." />}
        {!noData && <PlotInput fields={fields} onChange={onPlotInputChange} />}
        {isLoading && <Loader />}
        {!noData && plotData && (
          <Row className="plot-container">
            <Col>
              <PlotStateProvider>
                <PlotContext.Consumer>
                  {({
                    onHover, onPointDetailsHover, onPointDetailsUnhover, onUnhover, plotPointHover
                  }) => (
                    <>
                      <PlotWrapper
                        onClick={handleSinglePointSelected}
                        onSelected={handlePointsSelected}
                        data={plotData.data}
                        layout={plotData.layout}
                        config={{
                          responsive: true,
                          displaylogo: false,
                          displayModeBar: true,
                          modeBarButtonsToRemove: ['hoverClosestCartesian',
                            'hoverCompareCartesian', 'toggleSpikelines'],
                          modeBarButtonsToAdd: [clearSelectionButton]
                        }}
                        style={{ width: '100%', height: '100%' }}
                        onHover={onHover}
                        onUnhover={onUnhover}
                      />
                      {(plotType === PLOT_TYPE_SCATTER_2D && xField && yField) && (
                        <PlotPointHover
                          errorColumns={[]}
                          point={plotPointHover}
                          repository={repository}
                          dataset={dataset}
                          auditId={auditId}
                          xColumnName={xField}
                          yColumnName={yField}
                          showViewScreenshots={false}
                          onPointDetailsHover={onPointDetailsHover}
                          onPointDetailsUnhover={onPointDetailsUnhover}
                          onScreenshotButtonClick={() => { }}
                        />
                      )}
                    </>
                  )}
                </PlotContext.Consumer>
              </PlotStateProvider>
            </Col>
          </Row>
        )}
        {renderSelectedRecords()}
      </>
    );
  }
  return null;
}

export default PlotContainer;
