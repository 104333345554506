import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalOk = ({
  message, okLabel, onOkClick, show, title
}) => (
  <Modal show={show} onHide={onOkClick}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer>
      <Button size="sm" variant="secondary" onClick={onOkClick}>
        {okLabel || 'OK'}
      </Button>
    </Modal.Footer>
  </Modal>
);
ModalOk.defaultProps = {
  okLabel: 'OK'
};

ModalOk.propTypes = {
  show: PropTypes.bool.isRequired,
  onOkClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  okLabel: PropTypes.string
};


export default ModalOk;
