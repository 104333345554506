import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Auth, API } from 'aws-amplify';
import config from 'react-global-configuration';
import { hot } from 'react-hot-loader/root';

import './App.scss';
import AsyncDownloader from './components/AsyncDownloader';
import AsyncDownloaderStateProvider from './context/asyncDownloader/AsyncDownloaderStateProvider';
import awsConfig from './awsConfig';
import Header from './components/Header';
import LocalStorageStateProvider from './context/localStorage/LocalStorageStateProvider';
import Routes from './Routes';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autheticatedUser: null, // eslint-disable-line
      isAuthenticated: false,
      isAuthenticating: true,
      email: '',
      userSettings: {},
      sub: '', // Subject: unique, permanent user identifier from Cognito
      signupCompleted: false
    };
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (user) {
        await this.userHasAuthenticated({
          authenticated: true,
          email: user.attributes.email,
          sub: user.attributes.sub,
          signupCompleted: (
            'custom:signup_completed' in user.attributes ? user.attributes['custom:signup_completed'] === '1' : false)
        });
        this.setAuthenticatedUser(user);
      }
    } catch (e) {
      // Too much time has passed and the user has been logged out
      // If the user is visiting an authenticated page, the router will
      // automatically redirect to login, otherwise, it will render normally.
    }

    this.setState({ isAuthenticating: false });
  }

  setAuthenticatedUser = (user) => {
    this.setState({
      authenticatedUser: user // eslint-disable-line
    });
  }

  userHasAuthenticated = async ({
    authenticated, email, sub, signupCompleted
  }) => {
    this.setState({
      isAuthenticated: authenticated,
      email,
      signupCompleted,
      sub
    });
    if (authenticated && signupCompleted) {
      await this.loadUserSettings();
    }
  }

  settingsWereUpdated = async () => {
    await this.loadUserSettings();
  }

  loadUserSettings = async () => {
    const settings = await API.get('typo-dashboard', `/settings?env=${awsConfig.application.ENVIRONMENT_NAME}`);
    this.setState({
      userSettings: settings
    });
    config.set({
      userSettings: settings,
      clusterManagementEndpoint: `${settings.clusterEndpoint}/management/api/v1`
    }, { freeze: false });
  }

  handleLogout = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const { attributes } = user;

    await Auth.signOut();

    if (!attributes.identities || attributes.identities.length === 0) {
      // User has signed in with email & password (has no associated identities).
      await this.userHasAuthenticated({
        authenticated: false,
        email: '',
        sub: '',
        signupCompleted: false
      });
    }
  }

  render() {
    const {
      isAuthenticated, isAuthenticating, email, signupCompleted, sub, userSettings
    } = this.state;

    const childProps = {
      isAuthenticated,
      signupCompleted,
      userSettings,
      userHasAuthenticated: this.userHasAuthenticated,
      settingsWereUpdated: this.settingsWereUpdated
    };

    if (isAuthenticating) {
      return <></>;
    }

    const content = (
      <>
        <Header
          clusterEndpoint={userSettings.clusterEndpoint}
          email={email}
          handleLogout={this.handleLogout}
          isAuthenticated={isAuthenticated}
          signupCompleted={signupCompleted}
        />
        <Container fluid>
          <Routes childProps={childProps} />
        </Container>
      </>
    );

    if (isAuthenticated) {
      return (
        <LocalStorageStateProvider appClientId={awsConfig.cognito.APP_CLIENT_ID} sub={sub}>
          <AsyncDownloaderStateProvider>
            <AsyncDownloader />
            {content}
          </AsyncDownloaderStateProvider>
        </LocalStorageStateProvider>
      );
    }

    return content;
  }
}

export default process.env.NODE_ENV === 'development' ? hot(withRouter(App)) : withRouter(App);
