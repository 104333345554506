import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import { LABEL_GOOD, LABEL_BAD, LABEL_NOTSET } from '../../constants';

const classNames = {};
classNames[LABEL_GOOD] = 'badge-good';
classNames[LABEL_BAD] = 'badge-bad';
classNames[LABEL_NOTSET] = 'badge-not-set';

const QualityLabelBadge = ({ qualityLabel }) => {
  const qLabel = qualityLabel || LABEL_NOTSET;

  return (
    <>
      <Badge className={classNames[qLabel]}>{qLabel}</Badge>
    </>
  );
};

QualityLabelBadge.propTypes = {
  qualityLabel: PropTypes.string
};

QualityLabelBadge.defaultProps = {
  qualityLabel: null
};

export default QualityLabelBadge;

export const formatQualityLabel = (qualityLabel) => {
  if (!qualityLabel) {
    return LABEL_NOTSET;
  }
  if (qualityLabel === 'GOOD') {
    return LABEL_GOOD;
  }
  if (qualityLabel === 'BAD') {
    return LABEL_BAD;
  }
  if (qualityLabel === 'NOT_SET') {
    return LABEL_NOTSET;
  }
  return qualityLabel;
};
