import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Stats from './Stats';
import { useStateValue } from './state';

const StatsContainer = () => {
  const [{ showStats, dataSelector }] = useStateValue();

  if (showStats) {
    return (
      <>
        <Row>
          <Col><h5>Stats</h5></Col>
        </Row>
        <Row>
          <Col><Stats dataSelector={dataSelector} /></Col>
        </Row>
      </>
    );
  }
  return null;
};

export default StatsContainer;
