import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

const OAuthLogout = ({ history }) => {
  history.push('/login');
  return (<></>);
};

OAuthLogout.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default OAuthLogout;
