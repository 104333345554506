import React, { Component } from 'react';
import { API } from 'aws-amplify';
import {
  Button, Table, Modal, Col, Row, Breadcrumb
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import NoConfigurations from '../components/NoConfigurations';

import IconButton from '../components/IconButton';
import Loader from '../components/Loader';
import Title from '../components/Title';


class ListConfigurations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      configurations: [],
      show: false
    };

    this.unmounted = false;
  }

  async componentDidMount() {
    this.loadConfigurations();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getConfigurations() {
    return API.get('typo-dashboard', '/configurations');
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  deleteConfiguration = async (configurationId) => {
    const { configurations } = this.state;

    const configIndex = configurations.findIndex((config) => config.configurationId === configurationId);

    const selectedConfig = configurations[configIndex];

    this.setState({
      selectedConfigurationId: configurationId,
      selectedApiKey: selectedConfig.apiKeyId,
      selectedUrl: selectedConfig.domainFilter
    });

    this.handleShow();
  }

  confirmDelete = async () => {
    const { configurations, selectedConfigurationId } = this.state;

    try {
      const configurationId = selectedConfigurationId;

      await API.del('typo-dashboard', `/configurations/${configurationId}`);

      if (this.unmounted) return;

      const configIndex = configurations.findIndex((config) => config.configurationId === configurationId);
      const updatedConfigurations = configurations.slice();
      updatedConfigurations.splice(configIndex, 1);

      this.setState({
        configurations: updatedConfigurations,
        show: false
      });
    } catch (e) {
      alert(e);
    }
  }

  async loadConfigurations() {
    const compareStrings = (a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    };

    const compareApiKeyIds = (a, b) => compareStrings(a.apiKeyId, b.apiKeyId);


    try {
      const configurations = await this.getConfigurations();

      if (this.unmounted) return;

      configurations.sort(compareApiKeyIds);
      this.setState({
        configurations
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  enable(index) {
    this.updateEnable(index, true);
  }

  async updateEnable(index, enabled) {
    const { apiKeys } = this.state;

    const apiKey = apiKeys[index];
    apiKey.enabled = enabled;

    try {
      await this.updateApiKey(apiKey);

      if (this.unmounted) return;

      this.forceUpdate();
    } catch (e) {
      alert(e);
    }
  }

  updateApiKey(apiKey) {
    return API.put('typo-dashboard', `/apikeys/${apiKey.apiKeyId}`, {
      body: apiKey
    });
  }

  renderApiKeysList() {
    const { configurations } = this.state;

    const rows = configurations.map(({
      configurationId, apiKeyId, domainFilter, createdAt
    }, index) => (
      <tr
        key={index} // eslint-disable-line react/no-array-index-key
      >
        <td>{apiKeyId}</td>
        <td>{domainFilter}</td>
        <td>{new Date(createdAt).toISOString()}</td>
        <td className="text-right">
          <IconButton
            to={`/configurations/new?repository=${apiKeyId}&domainFilter=${encodeURIComponent(domainFilter)}`}
            icon={faPen}
            label="Edit configuration"
          />
          <IconButton
            variant="danger"
            onClick={() => this.deleteConfiguration(configurationId)}
            label="Delete configuration"
            icon={faTrash}
          />
        </td>
      </tr>
    ));

    return (
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>Repository</th>
            <th>Activation URL</th>
            <th>Last Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }

  render() {
    const {
      configurations, isLoading, selectedApiKey, selectedUrl, show
    } = this.state;

    const renderContent = () => {
      if (isLoading) {
        return <Loader />;
      }

      if (configurations.length === 0) {
        return (
          <NoConfigurations
            {...this.props} // eslint-disable-line react/jsx-props-no-spreading
          />
        );
      }

      return this.renderApiKeysList();
    };

    return (
      <>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure that you want to delete the configuration for repository
            <strong>{selectedApiKey}</strong> and URL <strong>{selectedUrl}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button size="sm" variant="outline-danger" onClick={this.confirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <Breadcrumb>
              <LinkContainer to="/"><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <LinkContainer to="/repositories">
                <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>Configurations</Breadcrumb.Item>
            </Breadcrumb>
            <Title
              text="Configurations"
              toolbar={(
                <Link to="/configurations/new">
                  <Button size="sm">Add Configuration</Button>
                </Link>
              )}
            />
            {renderContent()}
          </Col>
        </Row>
      </>
    );
  }
}

export default ListConfigurations;
