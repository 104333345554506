import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './AuthButton.scss';
import googleLogo from './google-g-logo.svg';
import microsoftLogo from './microsoft-logo.svg';

const AuthButton = ({ onClick, text, type }) => (
  <Button className="btn-block auth-button" variant="outline-secondary" type="button" onClick={onClick}>
    {type === 'google' && (
      <img src={googleLogo} style={{ width: '16px' }} alt="" />)}
    {type === 'microsoft' && (
      <img src={microsoftLogo} style={{ width: '16px' }} alt="" />)}
    {text}
  </Button>
);

AuthButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default AuthButton;
