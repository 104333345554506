import {
  DOWNLOAD_GENERATION_COMPLETED, DOWNLOAD_GENERATION_STARTED, START_DOWNLOAD,
  DOWNLOAD_RESUMED, START_DOWNLOADING, BROWSER_DOWNLOAD_STARTED,
  CLOSE_NOTIFICATION, DOWNLOAD_ERROR, CLEAR_STATE,
  DOWNLOAD_PENDING,
  STATE_NEW, STATE_PENDING, STATE_COMPLETED, STATE_ERROR,
  STATE_RESUMED
} from './asyncDownloaderTypes';


export default (state, action) => {
  const { downloadId, payload } = action;

  // Update state for one specific download
  const remapDownloads = (dId, newValues) => state.downloads.map((download) => {
    if (download.downloadId === dId) {
      return { ...download, ...newValues };
    }

    return download;
  });

  switch (action.type) {
    case START_DOWNLOAD: {
      // Initialize with NEW state
      const downloadData = {
        data: payload,
        state: STATE_NEW,
        notificationVisible: true,
        downloadId,
        resumed: false,
        download: false,
        browserDownloadStarted: false
      };

      return { ...state, downloads: [...state.downloads, downloadData] };
    }

    case DOWNLOAD_GENERATION_STARTED: {
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { state: STATE_PENDING, exportId: payload.exportId }
        )
      };
    }

    case DOWNLOAD_GENERATION_COMPLETED: {
      // Ready to download
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { state: STATE_COMPLETED, downloadUrl: payload.downloadUrl, notificationVisible: true }
        )
      };
    }

    case DOWNLOAD_RESUMED: {
      // Add to downloads array a resumed download
      // loaded from local storage
      const downloadData = {
        data: payload.downloadData,
        state: STATE_RESUMED,
        notificationVisible: false,
        downloadId,
        exportId: action.exportId,
        resumed: true,
        download: false
      };

      return { ...state, downloads: [...state.downloads, downloadData] };
    }

    case START_DOWNLOADING: {
      // Start downloading a completely generated download
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { download: true, notificationVisible: true }
        )
      };
    }

    case BROWSER_DOWNLOAD_STARTED: {
      // Confirm that download has started (or download browser dialog has opened)
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { browserDownloadStarted: true }
        )
      };
    }

    case DOWNLOAD_PENDING: {
      // Mark the download as PENDING
      const currentState = state.downloads.find((download) => download.downloadId === downloadId).state;

      return currentState !== STATE_PENDING ? ({
        ...state,
        downloads: remapDownloads(
          downloadId,
          { state: STATE_PENDING, notificationVisible: true }
        )
      }) : state;
    }

    case CLOSE_NOTIFICATION: {
      // Close a notification toast
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { notificationVisible: false }
        )
      };
    }

    case DOWNLOAD_ERROR: {
      // An error occurred when generating download
      return {
        ...state,
        downloads: remapDownloads(
          downloadId,
          { state: STATE_ERROR, notificationVisible: true }
        )
      };
    }

    case CLEAR_STATE: {
      // Clear state to all blank
      return { ...state, downloads: [] };
    }

    default:
      return state;
  }
};
