import React from 'react';
import PropTypes from 'prop-types';
import { ToolkitContext } from 'react-bootstrap-table2-toolkit';

// NOTE: Monkey patch ToolkitContext to allow changing visibility of
// multiple columns at once.
ToolkitContext.Provider.prototype.onColumnToggle = function onColumnToggle(dataField) {
  const { columnToggle } = this.state;

  if (typeof (dataField) === 'object') {
    this.setState({
      columnToggle: {
        ...columnToggle,
        ...dataField
      }
    });
  } else {
    this.setState({
      columnToggle: {
        ...columnToggle,
        [dataField]: !columnToggle[dataField]
      }
    });
  }
};

const ToolkitProvider = (props) => (
  <ToolkitContext.Provider
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <ToolkitContext.Consumer>
      {(tookKitProps) => props.children(tookKitProps)}
    </ToolkitContext.Consumer>
  </ToolkitContext.Provider>
);

ToolkitProvider.propTypes = {
  children: PropTypes.func.isRequired
};

export default ToolkitProvider;
