import React, { Component } from 'react';
import {
  Alert, Badge, Breadcrumb, Col, Row, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ReactRouterPropTypes from 'react-router-prop-types';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import AuditStateIndicator from '../components/AuditStateIndicator';
import AuditStatePoller from '../services/AuditStatePoller';
import CustomPaginationPageListRenderer from '../components/CustomPaginationPageListRenderer';
import CustomPaginationTotalRenderer from '../components/CustomPaginationTotalRenderer';
import CustomSizePerPageRenderer from '../components/CustomSizePerPageRenderer';
import tableHeaderFormatterFactory from '../components/tableHeaderFormatterFactory';
import Loader from '../components/Loader';
import Error from '../components/Error';
import DatasetService from '../services/dataset';
import InfoHelp from '../components/InfoHelp';
import Info from '../components/Info';
import ModalOk from '../components/ModalOk';
import ModalOkCancel from '../components/ModalOkCancel';
import Title from '../components/Title';
import IconButton from '../components/IconButton';
import LabelEditor from '../components/LabelEditor';
import { getTimestampFromDate } from '../utils';

import {
  AUDIT_STATE_COMPLETED, AUDIT_STATE_PROCESSING, AUDIT_STATE_SCHEDULED
} from '../constants';


class DatasetsAudits extends Component {
  constructor(props) {
    super(props);

    this.datasetService = new DatasetService();

    const { match: { params: { repository, dataset } } } = props;

    this.state = {
      audits: [],
      isLoading: true,
      error: undefined,
      repository,
      dataset,
      tags: [],
      confirmDeleteAudit: null,
      deletingAudit: false,
      deleteAuditError: null
    };

    this.auditStatePollers = {};
  }

  getDatasetsAudits = async () => {
    const { dataset, repository } = this.state;

    const audits = await this.datasetService.getDatasetsAudits(repository, dataset, true);
    return audits.data;
  }

  componentDidMount = async () => {
    this.fetchAllData();
  }

  componentWillUnmount = () => {
    Object.keys(this.auditStatePollers).forEach((key) => {
      this.auditStatePollers[key].stopPolling();
    });
  }

  componentDidUpdate = (prevProps) => {
    const { match } = this.props;

    if (prevProps.match.path !== match.path && match.path === '/audits') {
      this.fetchAllData();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.path === '/audits') {
      return {
        ...state,
        dataset: '',
        repository: ''
      };
    }
    return state;
  }

  fetchAllData = async () => {
    try {
      const { isLoading } = this.state;

      if (!isLoading) {
        this.setState({
          isLoading: true
        });
      }

      const audits = await this.getDatasetsAudits();

      this.setState({
        audits,
        isLoading: false
      });

      audits.forEach((audit) => {
        if ([AUDIT_STATE_SCHEDULED, AUDIT_STATE_PROCESSING].includes(audit.state)) {
          this.auditStatePollers[`audit-${audit.id}`] = new AuditStatePoller(
            this.datasetService, audit.repository.name, audit.dataset.name, audit.id,
            this.updateAudit
          );
        }
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        error: err
      });
    }
  }

  confirmDeleteAudit = (repositoryName, datasetName, auditId) => {
    this.setState({
      confirmDeleteAudit: {
        repositoryName,
        datasetName,
        auditId
      }
    });
  };

  closeConfirmDeleteAuditModal = () => {
    this.setState({
      confirmDeleteAudit: null
    });
  }

  deleteAudit = async () => {
    const { confirmDeleteAudit: { repositoryName, datasetName, auditId } } = this.state;

    this.setState({
      deletingAudit: true
    });

    try {
      await this.datasetService.deleteAudit(repositoryName, datasetName, auditId);

      this.setState({
        confirmDeleteAudit: null,
        deletingAudit: false
      });
      this.fetchAllData();
    } catch (e) {
      let errorMessage = '';

      if (e.response) {
        if (e.response.status === 404) {
          errorMessage = (
            <Alert variant="danger">
              We were unable to find the selected audit. It may have already been deleted.
              Please reload the page and try again.
            </Alert>
          );
        } else if (e.response.status === 409) {
          errorMessage = (
            <Alert variant="danger">
              <p>The selected audit cannot be deleted at this time.</p>
              <p className="mb-0">{e.response.data.message}</p>
            </Alert>
          );
        }
      }

      if (!errorMessage) {
        errorMessage = (
          <Alert variant="danger">
            An error occurred while deleting the audit. Please try again.
          </Alert>
        );
      }

      this.setState({
        confirmDeleteAudit: null,
        deletingAudit: false,
        deleteAuditError: {
          errorMessage
        }
      });
    }
  }

  updateAudit = (newAuditValues) => {
    const { audits } = this.state;

    this.setState({
      audits: audits.map((audit) => {
        if (audit.id === newAuditValues.fullRecord.id) {
          return {
            total_labels: 0,
            total_bad_labels: 0,
            total_good_labels: 0,
            ...newAuditValues.fullRecord
          };
        }

        return audit;
      })
    });
  };

  updateAuditRecord = (row) => {
    const data = {
      name: row.name,
      tag: row.tag
    };
    const { repository } = row;
    const { dataset } = row;
    const auditId = row.id;
    this.datasetService.updateAuditHeader(repository.name, dataset.name, auditId, data).then(() => {
      this.forceUpdate();
    });
  }

  updateAuditState = (auditId, newState) => {
    const { audits } = this.state;

    this.setState({
      audits: audits.map((audit) => {
        if (audit.id === auditId) {
          return {
            ...audit,
            state: newState
          };
        }

        return audit;
      })
    });
  }

  checkRow = (row, value, extraCheck) => {
    if (row.total_records === null || (extraCheck && row[extraCheck] === null)) {
      return '–';
    }

    return value();
  }

  getColumns = () => [
    {
      dataField: 'id',
      text: 'Audit ID',
      editable: false,
      sort: true,
      headerFormatter: tableHeaderFormatterFactory(),
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' })
    },
    {
      dataField: 'repository.name',
      text: 'Repository',
      editable: false,
      sort: true,
      headerFormatter: tableHeaderFormatterFactory(),
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' })
    },
    {
      dataField: 'dataset.name',
      text: 'Dataset',
      editable: false,
      sort: true,
      headerFormatter: tableHeaderFormatterFactory(),
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' })
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: true,
      editorClasses: 'form-control-sm',
      formatter: (cell, row) => (
        <div className="typeahead-row-container">
          {row.name}
        </div>
      ),
      headerFormatter: tableHeaderFormatterFactory(),
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      sort: true,
      classes: 'typeahead-cell'
    },
    {
      dataField: 'tag',
      text: 'Tag',
      formatter: (cell, row) => (
        <div className="typeahead-row-container">
          {row.tag && <Badge variant="info">{row.tag}</Badge>}
        </div>
      ),
      editable: true,
      headerFormatter: tableHeaderFormatterFactory(),
      filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
      sort: true,
      classes: 'typeahead-cell',
      editorRenderer: (editorProps, value, row) => {
        const { tags } = this.state;

        return (
          <LabelEditor
            {...editorProps} // eslint-disable-line react/jsx-props-no-spreading
            onUpdate={(selected) => {
              if (tags.indexOf(selected) === -1) {
                this.setState({
                  tags: [...tags, selected]
                });
              }
              editorProps.onUpdate(selected);
              this.updateAuditRecord(row);
            }}
            value={value}
            options={tags}
          />
        );
      }
    },
    {
      dataField: 'created_at',
      text: 'Audit Timestamp',
      formatter: (cell, row) => getTimestampFromDate(row.created_at),
      headerFormatter: tableHeaderFormatterFactory(),
      editable: false,
      sort: true
    },
    {
      dataField: 'total_records',
      text: 'Total Records',
      editable: false,
      sort: true,
      formatter: (cell) => (cell === null ? '–' : cell),
      headerFormatter: tableHeaderFormatterFactory(
        <div>Total<br />Records</div>
      )
    },
    {
      dataField: 'total_errors',
      text: 'Detected Errors',
      formatter: (cell, row) => this.checkRow(
        row,
        () => `${row.total_errors} (${((row.total_errors / row.total_records) * 100).toFixed(2)} %)`,
        'total_errors'
      ),
      headerFormatter: tableHeaderFormatterFactory(
        <div>Detected<br />Errors</div>
      ),
      editable: false,
      sort: true
    },
    {
      dataField: 'total_labels',
      text: 'Labeled (%)',
      editable: false,
      formatter: (cell, row) => this.checkRow(
        row,
        () => ((row.total_labels / row.total_records) * 100).toFixed(2),
        'total_labels'
      ),
      headerFormatter: tableHeaderFormatterFactory(),
      sort: true
    },
    {
      dataField: 'total_bad_labels',
      text: 'Labeled Bad (%)',
      editable: false,
      formatter: (cell, row) => this.checkRow(
        row,
        () => ((row.total_bad_labels / row.total_records) * 100).toFixed(2),
        'total_bad_labels'
      ),
      headerFormatter: tableHeaderFormatterFactory(
        <div>Labeled<br /><span className="no-wrap">Bad (%)</span></div>
      ),
      sort: true
    },
    {
      dataField: 'total_good_labels',
      text: 'Labeled Good (%)',
      editable: false,
      formatter: (cell, row) => this.checkRow(
        row,
        () => ((row.total_good_labels / row.total_records) * 100).toFixed(2),
        'total_good_labels'
      ),
      headerFormatter: tableHeaderFormatterFactory(
        <div>Labeled<br /><span className="no-wrap">Good (%)</span></div>
      ),
      sort: true
    },
    {
      dataField: 'state',
      text: 'State',
      editable: false,
      sort: true,
      formatter: (cell, row) => {
        switch (cell) {
          case AUDIT_STATE_SCHEDULED:
          case AUDIT_STATE_PROCESSING:
            return (
              <AuditStateIndicator
                state={row.state}
                totalAudited={row.total_audited}
                totalRecords={row.total_records}
              />
            );
          case AUDIT_STATE_COMPLETED:
            return (
              <OverlayTrigger
                placement="left"
                overlay={(
                  <Tooltip id="tooltip-duplicates-check">
                    Completed
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faCheck} className="text-success" />
              </OverlayTrigger>
            );
          default:
            return cell;
        }
      },
      headerFormatter: tableHeaderFormatterFactory()
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <>
          <IconButton
            icon={faChartBar}
            label="View audit results"
            to={`/analytics/${row.repository.name}/${row.dataset.name}/${row.id}`}
          />
          <IconButton
            icon={faTrash}
            label="Delete Audit"
            onClick={() => {
              this.confirmDeleteAudit(row.repository_name, row.dataset_name, row.id);
            }}
            variant="danger"
          />
        </>
      ),
      headerFormatter: tableHeaderFormatterFactory(),
      editable: false,
      classes: 'actions-column'
    }
  ]

  renderDatasetAuditsTable = () => {
    const { audits } = this.state;

    const paginationOptions = {
      sizePerPage: 25,
      sizePerPageList: [10, 25, 50, 100, 250, 500].map((num) => ({ text: num, value: num })),
      showTotal: true,
      pageListRenderer: CustomPaginationPageListRenderer,
      paginationTotalRenderer: CustomPaginationTotalRenderer,
      sizePerPageRenderer: CustomSizePerPageRenderer,
      alwaysShowAllBtns: true
    };
    const pagination = paginationFactory(paginationOptions);
    const columns = this.getColumns();

    return (audits.length > 0) ? (
      <div className="bootstrap-table-wrapper two-row-headers with-header-separator">
        <BootstrapTable
          bootstrap4
          wrapperClasses="table-responsive"
          striped
          keyField="id"
          hover
          data={audits}
          columns={columns}
          bordered
          pagination={pagination}
          cellEdit={cellEditFactory({
            mode: 'click',
            afterSaveCell: (oldValue, newValue, row) => { this.updateAuditRecord(row); }
          })}
          filter={filterFactory()}
          condensed
          noDataIndication="There isn't any data to show."
        />
      </div>
    ) : (
      <Info title="No audits found">No audits available.</Info>
    );
  }

  renderContent() {
    const { isLoading } = this.state;
    return isLoading ? <Loader /> : this.renderDatasetAuditsTable();
  }

  render() {
    const {
      confirmDeleteAudit, deletingAudit, deleteAuditError, error
    } = this.state;

    return (
      <>
        <Row>
          <Col>
            <Breadcrumb>
              <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <LinkContainer to="/repositories">
                <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to="/datasets">
                <Breadcrumb.Item href="/datasets">Datasets</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>Audits</Breadcrumb.Item>
            </Breadcrumb>
            <Title text="Datasets Audits" />

            <InfoHelp dismissible={false}>Audits run as batch processes over datasets to detect errors.</InfoHelp>
            {error !== undefined ? <Error /> : this.renderContent()}
          </Col>
        </Row>
        {confirmDeleteAudit && (
          <ModalOkCancel
            cancelOnClick={this.closeConfirmDeleteAuditModal}
            title="Confirm Audit deletion"
            message="Are you sure you want to delete the audit? This action cannot be undone."
            onHide={this.closeConfirmDeleteAuditModal}
            okButtonVariant="danger"
            okLabel={(
              <>
                <FontAwesomeIcon icon={faTrash} className="mr-1" />
                Confirm audit deletion
              </>
            )}
            okOnClick={() => { this.deleteAudit(confirmDeleteAudit); }}
            show
            loaderButton
            loaderButtonLoading={deletingAudit}
          />
        )}
        {deleteAuditError && (
          <ModalOk
            okLabel="Close"
            onOkClick={() => this.setState({ deleteAuditError: null })}
            show
            title="Audit deletion error"
            message={deleteAuditError.errorMessage}
          />
        )}
      </>
    );
  }
}

DatasetsAudits.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
};

export default DatasetsAudits;
