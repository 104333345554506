import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import {
  Row, Col, Tab, Nav
} from 'react-bootstrap';
import { Chart, Doughnut, HorizontalBar } from 'react-chartjs-2';
import { fetchDatasetStats, fetchDatasetAuditStats } from './services';
import Loader from '../Loader';
import NoData from '../NoData';
import './DatasetStatistics.scss';
import { isAuditSelection, isDatasetSelection } from './DataSelector';

const systemUser = 'System';

const pluginConfig = {
  beforeDraw(chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      const { ctx } = chart.chart;

      // Get options from the center object in options
      const centerConfig = chart.config.options.elements.center;
      const fontStyle = centerConfig.fontStyle || 'Arial';
      const txt = centerConfig.text;
      const color = centerConfig.color || '#000';
      const sidePadding = centerConfig.sidePadding || 20;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = `30px ${fontStyle}`;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      const fontSizeToUse = Math.min(newFontSize, elementHeight);

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctx.fillStyle = color;

      // Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  }
};

const getRecordsPerUserStats = (data) => {
  let totalRecords = 0;
  data.forEach((val) => { totalRecords += parseInt(val.totalRecords, 10); });

  return {
    data: {
      datasets: [{
        data: data.map((row) => row.totalRecords),
        backgroundColor: [
          '#36a2eb', // This is for the System user
          '#ff6384',
          '#4bc0c0',
          '#ffce56',
          '#e7e9ed'
        ],
        label: 'Records' // for legend
      }
      ],
      labels: data.map((row) => row.user)
    },
    options: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      elements: {
        center: {
          text: totalRecords,
          color: '#4bc0c0', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20 // Defualt is 20 (as a percentage)
        }
      }
    }
  };
};

const getErrorsPerUserStats = (data) => {
  const filteredData = data.filter((row) => row.user !== systemUser);
  let totalErrors = 0;
  filteredData.forEach((val) => { totalErrors += parseInt(val.totalErrors, 10); });

  return {
    data: {
      datasets: [{
        data: filteredData.map((row) => row.totalErrors),
        backgroundColor: [
          '#ff6384',
          '#4bc0c0',
          '#ffce56',
          '#e7e9ed',
          '#36a2eb'
        ],
        label: 'Errors' // for legend
      }
      ],
      labels: filteredData.map((row) => row.user)
    },
    options: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      elements: {
        center: {
          text: ` ${totalErrors} `,
          color: '#ccc', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20 // Defualt is 20 (as a percentage)
        }
      }
    }

  };
};

const getErrorRatePerUserStats = (data) => {
  const filteredData = data.filter((row) => row.user !== systemUser);
  return {
    datasets: [{
      data: filteredData.map((row) => row.errorRate * 100),
      backgroundColor: [
        '#ff6384',
        '#4bc0c0',
        '#ffce56',
        '#e7e9ed',
        '#36a2eb'
      ],
      label: 'Error rate' // for legend
    }
    ],
    labels: filteredData.map((row) => row.user)
  };
};

const getFixRatePerUserStats = (data) => {
  const filteredData = data.filter((row) => row.user !== systemUser);
  return {
    datasets: [{
      data: filteredData.map((row) => row.fixRate * 100),
      backgroundColor: [
        '#ff6384',
        '#4bc0c0',
        '#ffce56',
        '#e7e9ed',
        '#36a2eb'
      ],
      label: 'Fix rate' // for legend
    }
    ],
    labels: filteredData.map((row) => row.user)
  };
};

const getIgnoreRatePerUserStats = (data) => {
  const filteredData = data.filter((row) => row.user !== systemUser);
  return {
    datasets: [{
      data: filteredData.map((row) => row.ignoreRate * 100),
      backgroundColor: [
        '#ff6384',
        '#4bc0c0',
        '#ffce56',
        '#e7e9ed',
        '#36a2eb'
      ],
      label: 'Ignore rate' // for legend
    }
    ],
    labels: filteredData.map((row) => row.user)
  };
};

const getLabeledDataStats = (data) => {
  let labeled = 0;
  let unlabeled = 0;
  let good = 0;
  let bad = 0;
  let notSet = 0;

  data.forEach((r) => {
    if (r.label === 'Bad') {
      bad = parseInt(r.count, 10);
    } else if (r.label === 'Good') {
      good = parseInt(r.count, 10);
    } else if (r.label === 'Not Set') {
      notSet = parseInt(r.count, 10);
    }
  });

  labeled = good + bad;
  unlabeled = notSet;

  let labeledPercentage = 0;

  if (labeled + unlabeled > 0) {
    labeledPercentage = ((labeled / (labeled + unlabeled)) * 100);
  }

  labeledPercentage = labeledPercentage.toFixed(2);

  return {
    data: {
      datasets: [
        {
          data: [notSet, good, bad],
          backgroundColor: [
            '#4bc0c0', // Not Set
            'rgb(60,179,113)', // Good
            '#ff6384', // Bad
            '#36a2eb',
            '#e7e9ed'
          ],
          labels: ['Not Set', 'Good', 'Bad'],
          label: 'Dataset 1' // for legend
        },
        {
          data: [unlabeled, labeled],
          backgroundColor: [
            '#4bc0c0', // Unlabeled gray
            '#36a2eb', // Labeled blue
            '#ffce56',
            '#e7e9ed'

          ],
          labels: ['Unlabeled', 'Labeled'],
          label: 'Dataset 2' // for legend
        }
      ]

    },
    options: {
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, tooltipData) {
            const dataset = tooltipData.datasets[tooltipItem.datasetIndex];
            const { index } = tooltipItem;
            return `${dataset.labels[index]}: ${dataset.data[index]}`;
          }
        }
      },
      elements: {
        center: {
          text: `${labeledPercentage}%`,
          color: '#4bc0c0', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20 // Defualt is 20 (as a percentage)
        }
      }
    }
  };
};

const getRecordsPredictionsStats = (data) => {
  let errors = 0;
  let ok = 0;

  data.forEach((row) => {
    if (row.label === 'OK') {
      ok = parseInt(row.count, 10);
    } else if (row.label === 'Error') {
      errors = parseInt(row.count, 10);
    }
  });

  let errorsPercentage = 100;
  if (ok > 0) {
    errorsPercentage = ((errors / (ok + errors)) * 100).toFixed(2);
  }

  const newData = [ok, errors];

  return {
    data: {
      datasets: [
        {
          data: newData,
          backgroundColor: [
            'rgb(60,179,113)', // Good
            '#ff6384', // Bad
            '#ffce56',
            '#e7e9ed'
          ]
        }
      ],
      labels: ['OK', 'Error']
    },
    options: {
      legend: {
        display: false
      },
      elements: {
        center: {
          text: `${errorsPercentage}%`,
          color: '#ff6384', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20 // Defualt is 20 (as a percentage)
        }
      }
    }
  };
};

const computeStats = (data) => {
  const results = {
    errorsPerUserStats: getErrorsPerUserStats(data.userStats),
    recordsPerUserStats: getRecordsPerUserStats(data.userStats),
    errorRatePerUserStats: getErrorRatePerUserStats(data.userStats),
    fixRatePerUserStats: getFixRatePerUserStats(data.userStats),
    ignoreRatePerUserStats: getIgnoreRatePerUserStats(data.userStats),
    labeledDataStats: getLabeledDataStats(data.labels),
    recordsPredictionsStats: getRecordsPredictionsStats(data.predictions),
    userStats: data.userStats
  };
  return results;
};

const renderStats = (stats) => {
  const {
    errorsPerUserStats, errorRatePerUserStats, fixRatePerUserStats, ignoreRatePerUserStats,
    labeledDataStats, recordsPerUserStats, recordsPredictionsStats, userStats
  } = stats;

  return (
    <Row>
      {recordsPredictionsStats && (
        <Col sm={{ span: 4 }} xl={{ span: 2 }} className="mb-4">
          <h6 className="text-center">Predicted Results</h6>
          <Doughnut data={recordsPredictionsStats.data} options={recordsPredictionsStats.options} />
        </Col>
      )}
      {labeledDataStats && (
        <Col sm={{ span: 4 }} xl={{ span: 2 }} className="mb-4">
          <h6 className="text-center">Quality Labels</h6>
          <Doughnut data={labeledDataStats.data} options={labeledDataStats.options} />
        </Col>
      )}
      {userStats && userStats.length > 0 && (
        <>
          <Col sm={{ span: 4 }} xl={{ span: 2 }} className="mb-4">
            <h6 className="text-center">Records per User</h6>
            <Doughnut data={recordsPerUserStats.data} options={recordsPerUserStats.options} />
          </Col>
          <Col sm={{ span: 4 }} xl={{ span: 2 }} className="mb-4">
            <h6 className="text-center">Errors per User</h6>
            <Doughnut data={errorsPerUserStats.data} options={errorsPerUserStats.options} />
          </Col>
          <Col sm={{ span: 8 }} xl={{ span: 4 }} className="mb-4 user-error-percentage">
            <Tab.Container id="statistics-tabs" defaultActiveKey="first">
              <Row>
                <Col sm={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {userStats.length > 0 && (
                        <>
                          <h6 className="text-center">User Error Percentage of Total</h6>
                          <HorizontalBar
                            data={errorRatePerUserStats}
                            options={{
                              legend: { display: false },
                              tooltips: {
                                callbacks: {
                                  label: (tooltipItem, data) => {
                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                    const value = dataset.data[tooltipItem.index];
                                    return `${Math.round(value)}%`;
                                  }
                                }
                              }
                            }}
                          />
                        </>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {userStats.length > 0 && (
                        <>
                          <h6 className="text-center">User Fix Percentage of Errors</h6>
                          <HorizontalBar
                            data={fixRatePerUserStats}
                            options={{
                              scales: {
                                xAxes: [{
                                  display: true,
                                  ticks: {
                                    suggestedMin: 0,
                                    suggestedMax: 100 // minimum will be 0, unless there is a lower value.
                                  }
                                }]
                              },
                              legend: { display: false },
                              tooltips: {
                                callbacks: {
                                  label: (tooltipItem, data) => {
                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                    const value = dataset.data[tooltipItem.index];
                                    return `${Math.round(value)}%`;
                                  }
                                }
                              }
                            }}
                          />
                        </>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      {userStats.length > 0 && (
                        <>
                          <h6 className="text-center">User Ignore Percentage of Errors</h6>
                          <HorizontalBar
                            data={ignoreRatePerUserStats}
                            options={
                              {
                                scales: {
                                  xAxes: [{
                                    display: true,
                                    ticks: {
                                      suggestedMin: 0,
                                      suggestedMax: 100 // minimum will be 0, unless there is a lower value.
                                    }
                                  }]
                                },
                                legend: { display: false },
                                tooltips: {
                                  callbacks: {
                                    label: (tooltipItem, data) => {
                                      const dataset = data.datasets[tooltipItem.datasetIndex];
                                      const value = dataset.data[tooltipItem.index];
                                      return `${Math.round(value)}%`;
                                    }
                                  }
                                }
                              }
                            }
                          />
                        </>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
                <Col sm={4}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Errors</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Fixes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Ignores</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </>
      )}
    </Row>
  );
};

function Stats({ dataSelector }) {
  const unmounted = useRef(false);

  const [{ stats, isLoading }, setState] = useState({
    stats: null,
    isLoading: false
  });

  const loadStats = async (providedDataSelector) => {
    if (isDatasetSelection(providedDataSelector.selectionType)) {
      const { selectedRepository, selectedDataset } = providedDataSelector;
      const result = await fetchDatasetStats(selectedRepository.id, selectedDataset.id);
      return computeStats(result.data);
    }

    if (isAuditSelection(providedDataSelector.selectionType)) {
      const { selectedRepository, selectedDataset, selectedAudit } = providedDataSelector;
      const result = await fetchDatasetAuditStats(selectedRepository.id, selectedDataset.id, selectedAudit.id);
      return computeStats(result.data);
    }
    return null;
  };

  const fetchStatsAndUpdateState = async () => {
    try {
      setState({ stats, isLoading: true });
      const newStats = await loadStats(dataSelector);
      if (unmounted.current) return;
      setState({ stats: newStats, isLoading: false });
    } catch {
      // TODO: Set error
      if (unmounted.current) return;
      setState({ stats: null, isLoading: false });
    }
  };

  // componentDidMount
  useEffect(() => {
    Chart.pluginService.register(pluginConfig);
    fetchStatsAndUpdateState();

    return () => {
      unmounted.current = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchStatsAndUpdateState();
  }, [dataSelector]); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    if (!stats) {
      return <NoData message="No data available. Please select a Dataset or Audit." />;
    }
    return renderStats(stats);
  }, [stats, isLoading]);
}

// TODO: Cache data if the dataSelector did not change.

export default Stats;
