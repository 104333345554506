import axios from 'axios';
import { Auth, API } from 'aws-amplify';
import config from 'react-global-configuration';
import { COLUMN_TYPE_DATETIME, COLUMN_TYPE_DATETIME_2 } from '../../constants';

const DEFAULT_AXIOS_TIMEOUT = 0;

const getAuthToken = async () => {
  const session = await Auth.currentSession();
  return session.idToken.jwtToken;
};

// TODO: Get from management-api instead.s
export const fetchAllRepositories = () => API.get('typo-dashboard', '/repositories');

export const fetchRepositoryDatasets = async (repositoryId) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryId}/datasets`;
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj.data;
};

export const fetchDatasetAudits = async (repositoryId, datasetId) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryId}/datasets/${datasetId}/audits/`
    + '?detailed=false';
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj.data;
};

// TODO: Remove the copy from the dataset.js
export const fetchDatasetStats = async (repository, dataset) => {
  if (!repository || !dataset) {
    return { data: {} };
  }
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/dataset-stats/${repository}/${dataset}`;
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj.data;
};

// TODO: Remove the copy from the dataset.js
export const fetchDatasetAuditStats = async (repository, dataset, auditId) => {
  if (!repository || !dataset || !auditId) {
    return { data: [] };
  }
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/audit-stats/${repository}/${dataset}/${auditId}`;
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj.data;
};

export const fetchResultsMatrix = async (repository, dataset, auditId) => {
  if (!repository || !dataset) {
    return { data: [] };
  }
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  let url = `${clusterEndpoint}/management/api/v1/dataset-matrix/${repository}/${dataset}`;
  if (auditId) {
    url = `${clusterEndpoint}/management/api/v1/dataset-matrix/${repository}/${dataset}/${auditId}`;
  }
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj.data;
};


const encodeDatetimeFilterParams = (datetimeFilters, schema) => {
  // Append datetime filters to URL
  let params = '';
  Object.keys(datetimeFilters).forEach((columnName) => datetimeFilters[columnName].forEach(
    (filterRow) => {
      if (filterRow.condition && filterRow.datetime) {
        // Format as YYYY-MM-DD HH:mm:ss or just leave as a timestamp based on column schema type.
        let urlDate = filterRow.datetime.valueOf();
        const isDatetime = (schema[columnName]
          && (schema[columnName].type === COLUMN_TYPE_DATETIME || schema[columnName].type === COLUMN_TYPE_DATETIME_2));

        if (isDatetime) {
          const formatMapping = {
            '%d/%m/%Y %I:%M:%S.%f %p': 'DD/MM/YYYY hh:mm:ss.sss A',
            '%Y-%m-%d %H:%M:%S': 'YYYY-MM-DD HH:mm:ss'
          };
          const format = formatMapping[schema[columnName].format];
          urlDate = encodeURIComponent(filterRow.datetime.format(format).replace(/:/g, '$$$'));
        }
        params += `&${columnName}=${filterRow.condition}:${urlDate}`;
      }
    }
  ));
  return params;
};

const encodeResultsParams = (parameters) => {
  const params = parameters || {};
  let {
    sizePerPage, page, sort, filters, datetimeFilters
  } = params;
  const { schema } = params;
  sizePerPage = sizePerPage === undefined ? 10 : sizePerPage; // NOTE: Passing 0 will get all records.

  if (sizePerPage === undefined) {
    sizePerPage = 10;
  }

  page = page || 1;
  filters = filters || [];
  filters = filters.map((filter) => `${filter.field}=${filter.op}:${encodeURIComponent(filter.value)}`);
  filters = filters.join('&');
  sort = sort || {};
  sort = Object.keys(sort).map((field) => `sort=${field}:${sort[field]}`);
  sort = sort.join('&');

  datetimeFilters = encodeDatetimeFilterParams(params.datetimeFilters, schema);

  return `&records_per_page=${sizePerPage}&page=${page}&${filters}&${sort}&${datetimeFilters}`;
};

export const fetchDatasetResults = async (repository, dataset, params) => {
  try {
    if (!repository || !dataset) {
      return { data: { records: [] } };
    }
    const token = await getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/results?${
      encodeResultsParams(params)}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AXIOS_TIMEOUT
    });
    return obj.data;
  } catch (e) {
    // TODO: Manage this!
    console.log(e);
  }

  return undefined;
};

export const fetchAuditResults = async (repository, dataset, auditId, params) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  params = params || {};
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/audits/${auditId}/results?${encodeResultsParams(params)}`;
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    timeout: DEFAULT_AXIOS_TIMEOUT
  });
  return obj.data;
};


const generateFieldsQueryString = (fields) => {
  let queryString = '';
  if (fields.x) {
    queryString += `x=${encodeURIComponent(fields.x)}`;
  }

  if (fields.y) {
    queryString += `&y=${encodeURIComponent(fields.y)}`;
  }
  return queryString;
};

export const fetchDatasetResultsPlot = async (repository, dataset, resultsParams, fields) => {
  try {
    if (!repository || !dataset) {
      return { data: [] };
    }
    const token = await getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    resultsParams.sizePerPage = 0; // NOTE: Get all records;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/results/plot?${generateFieldsQueryString(fields)}${encodeResultsParams(resultsParams)}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AXIOS_TIMEOUT
    });
    return obj.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchAuditResultsPlot = async (repository, dataset, auditId, options, fields) => {
  try {
    if (!repository || !dataset) {
      return { data: [] };
    }
    const token = await getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    options.sizePerPage = 0; // NOTE: Get all records;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/audits/${auditId}/results/plot?${generateFieldsQueryString(fields)}${encodeResultsParams(options)}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AXIOS_TIMEOUT
    });
    return obj.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchDatasetResultRecordDetails = async (repository, dataset, recordId) => {
  try {
    if (!repository || !dataset) {
      return { data: { records: [] } };
    }
    const token = await getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/results/${recordId}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AXIOS_TIMEOUT
    });
    return obj.data;
  } catch (e) {
    // TODO: Manage this!
    console.log(e);
  }
};

export const fetchAuditResultRecordDetails = async (repository, dataset, auditId, recordId) => {
  try {
    if (!repository || !dataset) {
      return { data: { records: [] } };
    }
    const token = await getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/audits/${auditId}/results/${recordId}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AXIOS_TIMEOUT
    });
    return obj.data;
  } catch (e) {
    // TODO: Manage this!
    console.log(e);
  }
};

export const updateDatasetResultRecord = async (repository, dataset, id, data) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/dataset/${repository}/${dataset}/${id}`;
  const obj = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj;
};

export const updateAuditResultRecord = async (repositoryName, datasetName, auditId, id, data) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
    + `datasets/${datasetName}/audits/${auditId}/results/${id}/label`;
  const obj = await axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj;
};

const getBulkLabelPayload = (columnName, label) => {
  const fieldsLabels = {};
  fieldsLabels[columnName] = label;
  const payload = [{
    'data_selector': '',
    'data_selector_where': '',
    'fields_labels': fieldsLabels
  }];
  return payload;
};

export const getDatasetHeader = async (repositoryName, datasetName) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getAuditHeader = async (repositoryName, datasetName, auditId) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}/audits/${auditId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const postDatasetBulkLabel = async (repositoryName, datasetName, columnName, label) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}/bulk-labels`;
  const payload = getBulkLabelPayload(columnName, label);
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const postAuditBulkLabel = async (repositoryName, datasetName, auditId, columnName, label) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}/audits/${auditId}/bulk-labels`;

  const payload = getBulkLabelPayload(columnName, label);
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


export const updateAuditResultRecords = async (repositoryName, datasetName, auditId, data) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
    + `datasets/${datasetName}/audits/${auditId}/labels`;
  const obj = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj;
};

export const updateDatasetRecords = async (repository, dataset, data) => {
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/dataset/${repository}/${dataset}/labels`;
  const obj = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return obj;
};

export const fetchDatasetMetadata = async (repositoryName, datasetName) => {
  if (!repositoryName || !datasetName) {
    return { data: [] };
  }
  const token = await getAuthToken();
  const userSettings = config.get('userSettings');
  const { clusterEndpoint } = userSettings;
  const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/datasets/${datasetName}/metadata`;
  const obj = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return obj.data;
};

export const fetchAudit = async (repositoryName, datasetName, auditId) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}/audits/${auditId}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return result.data;
};

export const fetchDataset = async (repositoryName, datasetName, auditId) => {
  const token = await getAuthToken();
  const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
    + `datasets/${datasetName}`;

  const result = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return result.data;
};
