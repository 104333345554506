import React, { Component } from 'react';
import {
  Button, Row, Col, Form, Card, Alert, Breadcrumb, Table
} from 'react-bootstrap';
import { Auth, API } from 'aws-amplify';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

import awsConfig from '../awsConfig';
import ApiKeyRow from '../components/ApiKeyRow';
import Loader from '../components/Loader';
import Title from '../components/Title';
import ModalOkCancel from '../components/ModalOkCancel';


class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      areApikeysLoading: false,
      session: null,
      newClusterEndpoint: null,
      saved: false,
      apikeys: [],
      showDeleteApikeyConfirmationModal: false,
      userData: null
    };
  }

  async componentDidMount() {
    // bypassCache is necessary because after signup completion the cache is not updated
    const [session, settings, apikeys, userData] = await Promise.all([
      Auth.currentAuthenticatedUser({ bypassCache: true }),
      API.get('typo-dashboard', `/settings?env=${awsConfig.application.ENVIRONMENT_NAME}`),
      API.get('typo-dashboard', '/apikeys'),
      API.get('typo-dashboard', '/user-data')
    ]);

    this.setState({
      session,
      isLoading: false,
      newClusterEndpoint: settings.clusterEndpoint,
      apikeys,
      userData
    });
  }

  canSave = () => true

  saveSettings = async () => {
    const { newClusterEndpoint } = this.state;
    const { settingsWereUpdated } = this.props;

    await API.put('typo-dashboard', `/settings?env=${awsConfig.application.ENVIRONMENT_NAME}`, {
      body: {
        clusterEndpoint: newClusterEndpoint
      }
    });

    this.setState({
      saved: true
    });

    this.timeoutId = setTimeout(() => {
      this.setState({ saved: false });
    }, 5 * 1000);

    await settingsWereUpdated();
  }

  createApikey = async () => {
    this.setState({ areApikeysLoading: true });
    await API.post('typo-dashboard', '/apikeys');
    const apikeys = await API.get('typo-dashboard', '/apikeys');
    this.setState({
      apikeys,
      areApikeysLoading: false
    });
  }

  save = async () => {
    // Create the tenant on the cluster.
    try {
      const { newClusterEndpoint, session: stateSession } = this.state;

      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;
      const tenantName = stateSession.attributes['custom:tenant'];

      const apikeys = await API.get('typo-dashboard', '/repositories');

      await axios.post(`${newClusterEndpoint}/management/api/v1/sync`, {
        tenant: { name: tenantName },
        apikeys
      }, { headers: { Authorization: `Bearer ${token}` } });

      await this.saveSettings();
    } catch (err) {
      if (err.toString().includes('Request failed with status code 409')) {
        try {
          await this.saveSettings();
        } catch (e) {
          alert(e);
        }
      } else {
        alert(err);
      }
    }
  }

  clusterEndpointChanged = (event) => {
    this.setState({ newClusterEndpoint: event.target.value });
  }

  saveMessageClose = () => {
    clearTimeout(this.timeoutId);
    this.setState({
      saved: false
    });
  }

  cancelApikeyDelete = () => {
    this.setState({
      showDeleteApikeyConfirmationModal: false,
      selectedApikeyId: null
    });
  }

  deleteApikeyButtonClicked = async (apikeyId) => {
    this.setState({
      showDeleteApikeyConfirmationModal: true,
      selectedApikeyId: apikeyId
    });
  }

  deleteApikey = async (apikeyId) => {
    this.setState({ areApikeysLoading: true, showDeleteApikeyConfirmationModal: false });
    await API.del('typo-dashboard', `/apikeys/${apikeyId}`);
    const apikeys = await API.get('typo-dashboard', '/apikeys');
    this.setState({
      apikeys,
      areApikeysLoading: false,
      selectedApikeyId: null
    });
  }

  deleteSelectedApikey = async () => {
    const { selectedApikeyId } = this.state;
    this.deleteApikey(selectedApikeyId);
  }

  renderContent = () => {
    const {
      apikeys, areApikeysLoading, newClusterEndpoint, saved, session, userData
    } = this.state;

    return (
      <>
        <Alert show={saved} onClose={this.saveMessageClose} dismissible variant="success">
          Cluster details updated!
        </Alert>
        <Card>
          <Card.Header as="h5">
            Accout details
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row} controlId="firstName">
                <Form.Label column sm={2}>
                  First Name
                </Form.Label>
                <Col sm={10}>
                  {session
                    && <Form.Control readOnly plaintext value={userData.firstName} />}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="lastName">
                <Form.Label column sm={2}>
                  Last Name
                </Form.Label>
                <Col sm={10}>
                  {session
                    && <Form.Control readOnly plaintext value={userData.lastName} />}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="email">
                <Form.Label column sm={2}>
                  Username
                </Form.Label>
                <Col sm={10}>
                  {session
                    && <Form.Control readOnly plaintext value={session.attributes.email} />}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="company">
                <Form.Label column sm={2}>
                  Company
                </Form.Label>
                <Col sm={10}>
                  {session
                    && <Form.Control readOnly plaintext value={userData.company} />}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="company">
                <Form.Label column sm={2}>
                  Job Title
                </Form.Label>
                <Col sm={10}>
                  {session
                    && <Form.Control readOnly plaintext value={userData.jobTitle} />}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="accountType">
                <Form.Label column sm={2}>
                  Account Type
                </Form.Label>
                <Col sm={10}>
                  <Form.Control readOnly plaintext value="Enterprise"></Form.Control>
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
          <div className="panel-footer" />
        </Card>
        <p>&nbsp;</p>
        <Card>
          <Card.Header as="h5">
            API Keys
          </Card.Header>
          <Card.Body>
            {
              areApikeysLoading
                ? <Loader />
                : (
                  <>
                    {apikeys.length > 0 && (
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>API Key ID</th>
                            <th>Secret</th>
                            <th>Created Timestamp</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apikeys.map((apikey, index) => (
                            <ApiKeyRow
                              key={index} // eslint-disable-line react/no-array-index-key
                              apikey={apikey}
                              deleteAction={this.deleteApikeyButtonClicked}
                            />
                          ))}
                        </tbody>
                      </Table>
                    )}
                    <Button onClick={this.createApikey}>Create an API Key</Button>
                  </>
                )
            }
          </Card.Body>
          <div className="panel-footer" />
        </Card>
        <p>&nbsp;</p>
        <Card>
          <Card.Header as="h5">
            Cluster details
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row} controlId="clusterEndpoint">
                <Form.Label column sm={2}>
                  Endpoint
                </Form.Label>
                <Col sm={10}>
                  <Form.Control value={newClusterEndpoint} onChange={this.clusterEndpointChanged} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="company">
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button disabled={!this.canSave()} onClick={this.save}>Save</Button>
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
          <div className="panel-footer" />
        </Card>
      </>
    );
  }

  render() {
    const { isLoading, showDeleteApikeyConfirmationModal } = this.state;
    return (
      <>
        <ModalOkCancel
          title="Delete API Key"
          message="Are you sure that you want to delete the API Key?"
          onHide={this.cancelApikeyDelete}
          cancelOnClick={this.cancelApikeyDelete}
          show={showDeleteApikeyConfirmationModal}
          okOnClick={this.deleteSelectedApikey}
        />
        <Row>
          <Col>
            <Breadcrumb>
              <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item active>My Account</Breadcrumb.Item>
            </Breadcrumb>
            <Title text="My Account" />
            <div className="limited-width">
              {isLoading ? <Loader /> : this.renderContent()}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

Account.propTypes = {
  settingsWereUpdated: PropTypes.func.isRequired
};

export default Account;
