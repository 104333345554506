import React, { Component, Fragment } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import Info from './Info';

export default class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            apiKeys: [],
            selectedIndex: -1,
            selectedApiKeyDescription: ''
        };
    }

    async componentDidMount() {
        try {
            var apiKeys = await this.getApiKeys();
            this.setState({
                apiKeys: apiKeys
            });
        } catch (e) {
            alert(e);
        }
        this.setState({ isLoading: false });
    }

    getApiKeys() {
        return API.get('typo-dashboard', '/repositories');
    }

    getSelectedApiKey() {
        if (this.state.selectedIndex !== -1) {
            return this.state.apiKeys[this.state.selectedIndex];
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        const selectedApiKey = this.getSelectedApiKey();
        if (!selectedApiKey) {
            return;
        }

        const url = `/configurations/new?apiKeyId=${selectedApiKey.apiKeyId}`;
        this.props.history.push(url);
    }

    handleChange = (event) => {
        const index = event.target.value;
        let selectedApiKeyId = '';
        let selectedApiKeyDescription = '';

        if (index !== -1) {
            const selectedApiKey = this.state.apiKeys[index];
            selectedApiKeyId = selectedApiKey.apiKeyId;
            selectedApiKeyDescription = selectedApiKey.description;
        }

        this.setState({
            selectedApiKeyId: selectedApiKeyId,
            selectedApiKeyDescription: selectedApiKeyDescription,
            selectedIndex: index
        });
    }

    validateForm() {
        return this.state.selectedIndex !== -1;
    }

    render() {
        let apiKeyOptions = this.state.apiKeys.map((apiKey, index) => {
            const apiKeyId = apiKey.apiKeyId;
            return (<option key={index} value={index}>{apiKeyId}</option>);
        });

        apiKeyOptions.push(<option key={-1} value={-1}>-- Please select --</option>);

        return (
            <Fragment>
                <Info title="No configurations found">
                    Please select a repository for which you want to create a configuration.
                </Info>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Group as={Row} controlId="repository">
                        <Form.Label column sm={2}>
                            <strong>Repository</strong>
                        </Form.Label>
                        <Form.Label column sm={10}>
                            <Form.Control as="select" value={this.state.selectedIndex} onChange={this.handleChange}>
                                {apiKeyOptions}
                            </Form.Control>
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formHorizontalPassword">
                        <Form.Label column sm={2}>
                            Description
                        </Form.Label>
                        <Form.Label column sm={10}>
                            <Form.Control readOnly={true} plaintext={true} value={this.state.selectedApiKeyDescription} />
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col>
                            <Button disabled={!this.validateForm()} variant="primary" type="submit">Configure</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Fragment>
        );
    }
}
