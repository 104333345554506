import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route, Redirect } from 'react-router-dom';

import { childPropsPropType } from '../prop-types';

const AuthenticatedRoute = ({ component: C, props: childProps, ...rest }) => (
  <Route
    {...rest} // eslint-disable-line react/jsx-props-no-spreading
    render={(props) => {
      if (childProps.isAuthenticated) {
        if (childProps.signupCompleted || props.location.pathname === '/complete-signup') {
          return (
            <C {...props} {...childProps} /> // eslint-disable-line
          );
        }
        return (
          <Redirect to="/complete-signup" />
        );
      }
      return (
        <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} /> // eslint-disable-line
      );
    }}
  />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: childPropsPropType.isRequired,
  location: ReactRouterPropTypes.location
};

AuthenticatedRoute.defaultProps = {
  location: undefined
};

export default AuthenticatedRoute;
