export const emailValidationError = 'Please enter a valid email';

export const requiredFieldError = 'This field is required';

export const nonMatchingPasswordsError = 'The passwords don\'t match. Please try again.';

export const existingRepositoryName = 'There is an existing Typo repository with this name. Repository '
  + 'names must be globally unique, please enter a different one.';

export const invalidRepositoryName = 'Name must only contain lowercase letters, numbers and hyphens (a-z, 0-9, -).';
