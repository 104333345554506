import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Form, Col, Row, Container
} from 'react-bootstrap';
import { API, Auth } from 'aws-amplify';
import ReactRouterPropTypes from 'react-router-prop-types';

import { INVALID_TOKEN } from '../errorCodes';
import { handleFieldBlur, handleFieldChange, validateForm } from '../formMethods';
import LoaderButton from '../components/LoaderButton';
import { refreshToken } from '../cognitoUtils';
import { validateRequired } from '../validators';
import { wait } from '../utils';

const SIGNUP_ALREADY_COMPLETED = 'SIGNUP_ALREADY_COMPLETED';

class CompleteSignup extends Component {
  validators = {
    firstName: validateRequired,
    lastName: validateRequired,
    company: validateRequired,
    jobTitle: validateRequired,
    phone: validateRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      fieldValues: {
        firstName: '',
        lastName: '',
        company: '',
        jobTitle: '',
        phone: ''
      },
      fieldErrors: {
        firstName: null,
        lastName: null,
        company: null,
        jobTitle: null,
        phone: null
      },
      fieldsValidated: {
        firstName: false,
        lastName: false,
        company: false,
        jobTitle: false,
        phone: false
      }
    };

    this.handleFieldBlur = handleFieldBlur.bind(this);
    this.handleFieldChange = handleFieldChange.bind(this);
    this.validateForm = validateForm.bind(this);

    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  criticalError = () => {
    // Most probably a network error
    this.setState({
      errorMessage: 'An error occurred while submitting your information. Please check your '
      + 'internet connection, reload the page and try again.',
      isLoading: false
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    const { history, userHasAuthenticated } = this.props;
    const {
      fieldValues: {
        firstName, lastName, phone, company, jobTitle
      }
    } = this.state;

    this.setState({ isLoading: true, errorMessage: null });

    const callAPI = async () => {
      try {
        // Get JWT token to forward to lambda function so it can use it to call the cluster
        const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

        // Values are stored following the OpenID Connect specification
        // https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
        await API.put('typo-dashboard', '/complete-signup', {
          body: {
            firstName, lastName, phone, company, jobTitle, jwt
          }
        });

        if (this.unmounted) {
          return null;
        }

        return true;
      } catch (error) {
        if (error.response !== undefined && error.response.data !== undefined
            && error.response.data.errorCode !== undefined) {
          if (error.response.status === 401 && error.response.data.errorCode === INVALID_TOKEN) {
            // Token is invalid, refresh and request again.
            return false;
          }
          if (error.response.status === 400 && error.response.data.errorCode === SIGNUP_ALREADY_COMPLETED) {
            // Already completed signup, can use system freely.
            history.push('/');
            return null;
          }
        }
        this.criticalError();
        return null;
      }
    };

    // Skipped if callAPI is true or null
    // If false, an unexpected error occurred (network error, etc.)
    let lastApiCallResult;

    lastApiCallResult = await callAPI();
    if (this.unmounted) {
      return;
    }

    if (lastApiCallResult === false) {
      // JWT invalid
      // Refresh token and try again.
      try {
        await refreshToken();

        if (this.unmounted) {
          return;
        }
      } catch (error) {
        // Proabably network error or refresh token expired
        this.criticalError();
        return;
      }

      // Token is not refreshed instantly
      await wait(1000);

      if (this.unmounted) {
        return;
      }

      lastApiCallResult = await callAPI();
      if (this.unmounted) {
        return;
      }

      if (lastApiCallResult === false) {
        // Refreshed JWT successfully but it still wasn't valid
        this.criticalError();
        return;
      }
    }


    if (lastApiCallResult === true) {
      // Get new token with updated signup status & tenant
      await refreshToken();

      if (this.unmounted) {
        return;
      }

      const userData = (await Auth.currentSession()).getIdToken().payload;

      if (this.unmounted) {
        return;
      }

      // Update App state
      userHasAuthenticated({
        authenticated: true,
        email: userData.email,
        sub: userData.sub,
        signupCompleted: true
      });
      history.push('/');
    }
  }

  renderForm() {
    const {
      errorMessage, fieldErrors, fieldValues, fieldsValidated, isLoading
    } = this.state;

    return (
      <>
        <Form onSubmit={this.handleSubmit} noValidate className="signup-form" autoComplete="off">
          <Row>
            <Col>
              <Form.Group controlId="firstName" variant="large">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  value={fieldValues.firstName}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.firstName !== null}
                  isValid={fieldsValidated.firstName && fieldErrors.firstName === null}
                  maxLength="100"
                />
                {fieldErrors.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.firstName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastName" variant="large">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  value={fieldValues.lastName}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.lastName !== null}
                  isValid={fieldsValidated.lastName && fieldErrors.lastName === null}
                  maxLength="100"
                />
                {fieldErrors.lastName && (
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.lastName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="company" variant="large">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              value={fieldValues.company}
              onChange={this.handleFieldChange}
              onBlur={this.handleFieldBlur}
              isInvalid={fieldErrors.company !== null}
              isValid={fieldsValidated.company && fieldErrors.company === null}
              maxLength="100"
            />
            {fieldErrors.company && (
              <Form.Control.Feedback type="invalid">
                {fieldErrors.company}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="jobTitle" variant="large">
            <Form.Label>Job title</Form.Label>
            <Form.Control
              type="text"
              value={fieldValues.jobTitle}
              onChange={this.handleFieldChange}
              onBlur={this.handleFieldBlur}
              isInvalid={fieldErrors.jobTitle !== null}
              isValid={fieldsValidated.jobTitle && fieldErrors.jobTitle === null}
              maxLength="100"
            />
            {fieldErrors.jobTitle && (
              <Form.Control.Feedback type="invalid">
                {fieldErrors.jobTitle}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="phone" variant="large">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              value={fieldValues.phone}
              onChange={this.handleFieldChange}
              onBlur={this.handleFieldBlur}
              isInvalid={fieldErrors.phone !== null}
              isValid={fieldsValidated.phone && fieldErrors.phone === null}
              maxLength="100"
            />
            {fieldErrors.phone && (
              <Form.Control.Feedback type="invalid">
                {fieldErrors.phone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {errorMessage
            && <Alert variant="danger">{errorMessage}</Alert>}
          <p className="text-secondary" style={{ fontSize: '0.9em' }}>
            By clicking below, you agree to the Terms and conditions and Privacy policy.
          </p>
          <LoaderButton
            block
            type="submit"
            isLoading={isLoading}
            text="Signup"
            loadingText="Signing up…"
          />
        </Form>
      </>
    );
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col className="small-centered-container">
            <h3 className="mb-3">Complete your Typo registration</h3>
            {this.renderForm()}
          </Col>
        </Row>
      </Container>
    );
  }
}

CompleteSignup.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired
};

export default CompleteSignup;
