import React, { Component } from 'react';
import {
  Card, Table, Button, Form, Row, Col, Breadcrumb
} from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { API } from 'aws-amplify';
import { LinkContainer } from 'react-router-bootstrap';
import queryString from 'query-string';

import DatasetService from '../services/dataset';
import Loader from '../components/Loader';
import InfoHelp from '../components/InfoHelp';
import Title from '../components/Title';

const DATASET_AUDIT_TYPE = 'Typo DI';

const MOCKED_RESULTS_MATRIX = [{
  predicted_error_admin_error: '343',
  predicted_error_user_error: '250',
  predicted_error_admin_good: '301',
  predicted_error_user_good: '50',
  predicted_error_no_feedback: '4002',
  predicted_error_total: '4535',
  predicted_good_admin_error: '35',
  predicted_good_user_error: 'n/a',
  predicted_good_admin_good: '280',
  predicted_good_user_good: 'n/a',
  predicted_good_no_feedback: '1530',
  predicted_good_total: '17950',
  predicted_error_admin_user_error_agree: 85,
  predicted_error_admin_user_good_agree: 95,
  predicted_good_admin_user_error_agree: 'n/a',
  predicted_good_admin_user_good_agree: 'n/a'
}];

export default class Usage extends Component {
  constructor(props) {
    super(props);

    this.datasetService = new DatasetService();

    this.state = {
      isLoading: true,
      usage: null,
      filteredApikeys: [],
      chartOptions: {
        bezierCurve: false,
        pointDotRadius: 6
      },
      auditSelection: [],
      resultsMatrix: [],
      datasetName: null
    };

    this.unmounted = false;
  }

  async componentDidMount() {
    try {
      const usage = await this.getUsage();

      if (this.unmounted) return;

      const perApikeySummary = this.summarizeByApiKey(usage);
      const allApikeys = Object.keys(usage.monthly);
      let filteredApikeys = allApikeys;
      const params = queryString.parse(window.location.search);
      const apikeysIdsParam = params.apiKeyIds;

      if (apikeysIdsParam) {
        filteredApikeys = apikeysIdsParam.split(',');
      }
      const usageView = this.computeUsageView(usage, filteredApikeys);
      const chartData = this.getChartData(usageView);

      await this.getRepositories();

      if (this.unmounted) return;

      this.setState({
        usage,
        perApikeySummary,
        usageView,
        filteredApikeys,
        chartData,
        allApikeys
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getChartData(usageView) {
    return {
      labels: usageView.details.last7Days.map((d) => d.date.substring(0, 10)),
      datasets: [
        {
          label: 'Daily Usage',
          fillColor: 'rgba(220,220,220,0.2)',
          fill: false,
          strokeColor: 'rgba(1,84,255,1)',
          pointColor: 'rgba(1,84,255,1)',
          pointStrokeColor: '#fff',
          pointHighlightFill: 'rgba(1,84,255,1)',
          pointHighlightStroke: 'rgba(1,84,255,1)',
          data: usageView.details.last7Days.map((d) => d.total)
        }
      ]
    };
  }

  onAuditChanged = async (auditSelection) => {
    const { datasetName, repositoryName } = this.state;

    let resultsMatrix = [];
    let auditId = null;
    if (auditSelection.length > 0) {
      const audit = auditSelection[0];
      auditId = audit.id;
      resultsMatrix = await this.getResultsMatrix(repositoryName, datasetName, auditId);
    }

    if (this.unmounted) return;

    this.setState({
      auditSelection,
      resultsMatrix
    });
  }

  onDatasetChanged = async (datasetSelection) => {
    const { repository, repositoryName } = this.state;
    const auditSelection = [];
    let resultsMatrix = [];
    let datasetName = null;

    if (datasetSelection.length > 0) {
      const dataset = datasetSelection[0];
      datasetName = dataset.name;

      if (dataset.type === DATASET_AUDIT_TYPE) {
        await this.getAudits(repository, datasetName);
      } else {
        resultsMatrix = await this.getResultsMatrix(repositoryName, datasetName);
      }
    } else {
      resultsMatrix = MOCKED_RESULTS_MATRIX;
    }

    if (this.unmounted) return;

    this.setState({
      auditSelection,
      resultsMatrix,
      datasetName
    });
  }

  async getUsage() {
    const result = await API.get('typo-dashboard', '/usage');

    return result.usage;
  }

  getToday() {
    const date = new Date();
    const today = `${date.toISOString().substring(0, 10)}T00:00:00.000Z`;
    return today;
  }

  getCurrentMonth() {
    const date = new Date();
    const currentMonth = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1)).toISOString();
    return currentMonth;
  }

  isFiltered = () => {
    const { allApikeys, filteredApikeys } = this.state;

    return allApikeys.length !== filteredApikeys.length;
  }

  clearFilters = () => {
    const { allApikeys, usage } = this.state;

    const usageView = this.computeUsageView(usage, allApikeys);
    const chartData = this.getChartData(usageView);

    this.setState({
      filteredApikeys: allApikeys,
      usageView,
      chartData
    });
  }

  onRepositoryChanged = async (repositorySelection) => {
    const auditSelection = [];
    let resultsMatrix = [];
    let repositoryName = null;
    const datasetName = null;

    if (repositorySelection.length > 0) {
      const repository = repositorySelection[0];
      repositoryName = repository.name;
      await this.getDatasets(repositoryName);

      if (this.unmounted) return;

      resultsMatrix = MOCKED_RESULTS_MATRIX;
    }

    this.setState({
      auditSelection,
      resultsMatrix,
      repositoryName,
      datasetName
    });
  }

  getRepositories = async () => {
    const repositories = await this.datasetService.getRepositories();

    return repositories.map((repo) => ({ name: repo.apiKeyId }));
  }

  getDatasets = async (repository) => {
    const datasets = await this.datasetService.getDatasets(repository);
    return datasets;
  }

  getResultsMatrix = async (repository, dataset, auditId) => {
    const result = await this.datasetService.getDatasetMatrix(repository, dataset, auditId);
    return result.data;
  }

  getAudits = async (repository, dataset) => {
    const audits = await this.datasetService.getDatasetsAudits(repository, dataset);

    return audits.data.map((audit) => ({
      ...audit,
      displayText: audit.name ? `${audit.created_on} - ${audit.name}` : audit.created_on
    }));
  }

  isApikeyInFilters = (apikey) => {
    const { filteredApikeys } = this.state;
    return filteredApikeys.includes(apikey);
  }

  updateApikeyFilter = (apikey) => {
    const { filteredApikeys: stateFilteredApikeys, usage } = this.state;

    let filteredApikeys;

    if (stateFilteredApikeys.includes(apikey)) {
      filteredApikeys = stateFilteredApikeys.filter((currentApikey) => currentApikey !== apikey);
    } else {
      filteredApikeys = stateFilteredApikeys.concat([apikey]);
    }

    const usageView = this.computeUsageView(usage, filteredApikeys);
    const chartData = this.getChartData(usageView);

    this.setState({
      filteredApikeys,
      usageView,
      chartData
    });
  }

  summarizeLast7Days(usage, filteredApikeys) {
    const time = 'T00:00:00.000Z';
    const dailyTotals = {};

    for (let i = 7; i >= 0; i -= 1) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      date = date.toISOString().substring(0, 10) + time;
      dailyTotals[date] = 0;
    }

    Object.keys(usage.daily).forEach((apiKeyId) => {
      if (filteredApikeys.includes(apiKeyId)) {
        Object.keys(dailyTotals).forEach((date) => {
          dailyTotals[date] += (usage.daily[apiKeyId][date] || 0);
        });
      }
    });

    return Object.keys(dailyTotals).map((date) => ({ date, total: dailyTotals[date] })).sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }

      if (a.date > b.date) {
        return 1;
      }

      return 0;
    });
  }

  computeUsageView(usage, filteredApikeys) {
    const currentMonthTotal = this.calculateCurrentMonthOverallUsage(usage, filteredApikeys);
    const todayTotal = this.calculateTodayOverallUsage(usage, filteredApikeys);

    const last30DaysTotal = 0;

    return {
      summary: {
        today: todayTotal,
        last30Days: last30DaysTotal,
        currentMonth: currentMonthTotal
      },
      details: {
        last7Days: this.summarizeLast7Days(usage, filteredApikeys)
      }
    };
  }

  calculateCurrentMonthOverallUsage(usage, filteredApikeys) {
    const currentMonth = this.getCurrentMonth();
    let currentMonthTotal = 0;
    Object.keys(usage.monthly).forEach((apiKeyId) => {
      if (filteredApikeys.includes(apiKeyId)) {
        currentMonthTotal += usage.monthly[apiKeyId][currentMonth] || 0;
      }
    });
    return currentMonthTotal;
  }

  calculateTodayOverallUsage(usage, filteredApikeys) {
    const today = this.getToday();
    let todayTotal = 0;
    Object.keys(usage.daily).forEach((apiKeyId) => {
      if (filteredApikeys.includes(apiKeyId)) {
        todayTotal += usage.daily[apiKeyId][today] || 0;
      }
    });
    return todayTotal;
  }

  summarizeByApiKey(usage) {
    const today = this.getToday();
    const currentMonth = this.getCurrentMonth();

    const apiKeyIds = Object.keys(usage.monthly);
    return apiKeyIds.map((apiKeyId) => ({
      apiKeyId,
      usage: {
        today: usage.daily[apiKeyId][today] || 0,
        currentMonth: usage.monthly[apiKeyId][currentMonth] || 0,
        last30Days: 0
      }
    }));
  }

  renderPerApiKeyRows() {
    const { perApikeySummary } = this.state;

    return (perApikeySummary && perApikeySummary.map((summary, index) => (
      <tr
        key={index} // eslint-disable-line react/no-array-index-key
      >
        <td>
          <Form.Check
            type="checkbox"
            checked={this.isApikeyInFilters(summary.apiKeyId)}
            onChange={() => this.updateApikeyFilter(summary.apiKeyId)}
          />
        </td>
        <td>{summary.apiKeyId}</td>
        <td>
          <span className="label label-primary">{summary.usage.today}</span>
        </td>
        <td>
          <span className="label label-info">{summary.usage.currentMonth}</span>
        </td>
      </tr>
    )));
  }

  renderContent() {
    const {
      auditSelection, chartOptions, chartData, resultsMatrix, usage, usageView
    } = this.state;

    return (
      <>

        {this.isFiltered()
        && (
          <InfoHelp dismissible={false}>
            <strong>Filters are active.</strong>
            &nbsp;Clear the filters to view total usage.
            <Button size="sm" variant="info" onClick={this.clearFilters} className="ml-3">Clear filters</Button>
          </InfoHelp>
        )}
        {usage && (
          <div className="row">
            <div className="col-md-3">
              <h4>Overall Usage</h4>
              <Card bg="success" text="white">
                <Card.Header>
                  <Card.Title>Today&apos;s requests</Card.Title>
                </Card.Header>
                <Card.Body><h1>{usageView.summary.today}</h1></Card.Body>
              </Card><br />
              <Card bg="secondary" text="white">
                <Card.Header>
                  <Card.Title>Current month requests</Card.Title>
                </Card.Header>
                <Card.Body><h1>{usageView.summary.currentMonth}</h1></Card.Body>
              </Card>
            </div>
            <div className="col-md-9">
              <h4>Last 7 Days Usage</h4>
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        )}
        <p>&nbsp;</p>
        <div className="row">
          <div className="col-sm-12">
            <h4>Usage per Repository</h4>
            <Table striped responsive>
              <thead>
                <tr>
                  <th />
                  <th>Repository</th>
                  <th>Today Usage</th>
                  <th>Current Month</th>
                </tr>
              </thead>
              <tbody>
                {this.renderPerApiKeyRows()}
              </tbody>
            </Table>
          </div>
        </div>
        {/*
      <p>&nbsp;</p>
      <Row>
        <Col><h4>Results Matrix</h4></Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridRepository">
                <Form.Label>Repository</Form.Label>
                <Typeahead
                  id='repository'
                  clearButton
                  ref={(ref) => this.typeaheadRepositories = ref}
                  selected={this.state.repositorySelection}
                  onChange={this.onRepositoryChanged}
                  highlightOnlyResult={true}
                  selectHintOnEnter={true}
                  multiple={false}
                  labelKey="name"
                  options={this.state.repositories}
                  placeholder="Choose a repository..." />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDataset">
                <Form.Label>Dataset</Form.Label>
                <Typeahead
                  id='dataset'
                  disabled={this.state.repositorySelection.length === 0}
                  clearButton
                  selected={this.state.datasetSelection}
                  onChange={this.onDatasetChanged}
                  ref={(ref) => this.typeaheadDatasets = ref}
                  highlightOnlyResult={true}
                  selectHintOnEnter={true}
                  multiple={false}
                  labelKey="display_name"
                  options={this.state.datasets}
                  placeholder="Choose a dataset..." />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridRecord">
                <Form.Label>Audit</Form.Label>
                <Typeahead
                  id='audit'
                  disabled={
                    this.state.datasetSelection.length === 0
                    || this.state.datasetSelection[0].type !== DATASET_AUDIT_TYPE
                    }
                  clearButton
                  ref={(ref) => this.typeaheadRecords = ref}
                  selected={this.state.auditSelection}
                  onChange={this.onAuditChanged}
                  highlightOnlyResult={true}
                  selectHintOnEnter={true}
                  labelKey="displayText"   // TODO: Change this on the server side to createdOn
                  multiple={false}
                  options={this.state.audits}
                  placeholder="Choose an audit..." />
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      */}
        {false && resultsMatrix.length > 0 && auditSelection.length === 0 && (
          <>
            <Row><Col><h5>Results Matrix</h5></Col></Row>
            <Row>
              <Col>
                <Table bordered size="sm">
                  <tbody>
                    <tr>
                      <th colSpan="2" rowSpan="3" />
                      <th className="bg-light text-center" colSpan="6">Actual</th>
                      <th colSpan="2" />
                    </tr>
                    <tr>
                      <th className="bg-light text-center" colSpan="3">Error</th>
                      <th className="bg-light text-center" colSpan="3">Valid</th>
                      <th className="bg-light text-center align-middle" rowSpan="2">No Feedback</th>
                      <th className="bg-light text-center align-middle" rowSpan="2">Total Records</th>
                    </tr>
                    <tr>
                      <th className="bg-light text-center">Admin</th>
                      <th className="bg-light text-center">User</th>
                      <th className="bg-light text-center">Disagree %</th>
                      <th className="bg-light text-center">Admin</th>
                      <th className="bg-light text-center">User</th>
                      <th className="bg-light text-center">Agree %</th>
                    </tr>
                    <tr>
                      <th className="bg-light text-center align-middle" rowSpan="2">Predicted</th>
                      <th className="bg-light text-center">Error</th>
                      <td>{resultsMatrix[0].predicted_error_admin_error}</td>
                      <td>{resultsMatrix[0].predicted_error_user_error}</td>
                      <td>{resultsMatrix[0].predicted_error_admin_user_error_disagree}</td>
                      <td>{resultsMatrix[0].predicted_error_admin_good}</td>
                      <td>{resultsMatrix[0].predicted_error_user_good}</td>
                      <td>{resultsMatrix[0].predicted_error_admin_user_good_disagree}</td>
                      <td>{resultsMatrix[0].predicted_error_no_feedback}</td>
                      <td>{resultsMatrix[0].predicted_error_total}</td>
                    </tr>
                    <tr>
                      <th className="bg-light text-center">Valid</th>
                      <td>{resultsMatrix[0].predicted_good_admin_error}</td>
                      <td>{resultsMatrix[0].predicted_good_user_error}</td>
                      <td>{resultsMatrix[0].predicted_good_admin_user_error_disagree}</td>
                      <td>{resultsMatrix[0].predicted_good_admin_good}</td>
                      <td>{resultsMatrix[0].predicted_good_user_good}</td>
                      <td>{resultsMatrix[0].predicted_good_admin_user_good_disagree}</td>
                      <td>{resultsMatrix[0].predicted_good_no_feedback}</td>
                      <td>{resultsMatrix[0].predicted_good_total}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}

        {false && resultsMatrix.length > 0 && auditSelection.length > 0 && (
          <>
            <Row><Col><h5>Results Matrix</h5></Col></Row>
            <Row>
              <Col>
                <Table bordered size="sm">
                  <tbody>
                    <tr>
                      <th colSpan="2" rowSpan="2" />
                      <th className="bg-light text-center" colSpan="2">Actual</th>
                      <th colSpan="2" />
                    </tr>
                    <tr>
                      <th className="bg-light text-center">Error</th>
                      <th className="bg-light text-center">Valid</th>
                      <th className="bg-light text-center align-middle">No Feedback</th>
                      <th className="bg-light text-center align-middle">Total Records</th>
                    </tr>
                    <tr>
                      <th className="bg-light text-center align-middle" rowSpan="2">Predicted</th>
                      <th className="bg-light text-center">Error</th>
                      <td>{resultsMatrix[0].predicted_error_admin_error}</td>
                      <td>{resultsMatrix[0].predicted_error_admin_good}</td>
                      <td>{resultsMatrix[0].predicted_error_no_feedback}</td>
                      <td>{resultsMatrix[0].predicted_error_total}</td>
                    </tr>
                    <tr>
                      <th className="bg-light text-center">Valid</th>
                      <td>{resultsMatrix[0].predicted_good_admin_error}</td>
                      <td>{resultsMatrix[0].predicted_good_admin_good}</td>
                      <td>{resultsMatrix[0].predicted_good_no_feedback}</td>
                      <td>{resultsMatrix[0].predicted_good_total}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to="/"><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
            <Breadcrumb.Item active>Usage</Breadcrumb.Item>
          </Breadcrumb>
          <Title text="Usage" />
          {isLoading ? <Loader /> : this.renderContent()}
        </Col>
      </Row>
    );
  }
}
