import React, {
  useEffect, useState, useMemo, useRef
} from 'react';
import {
  Tooltip, OverlayTrigger, Form, Col, Row
} from 'react-bootstrap';
import ExportDataDropdown from './ExportDataDropdown';
import { DATAGRID_INIT_ACTION, DATAGRID_REFRESH_ACTION, TOGGLE_HIDE_NO_ERROR_COLUMNS } from './actions';
import {
  fetchAudit, fetchDataset, fetchDatasetResults, fetchAuditResults
} from './services';
import {
  LABEL_NOTSET, LABEL_BAD, LABEL_GOOD, DATA_SELECTION_TYPE_DATASET, DATA_SELECTION_TYPE_AUDIT,
  COLUMN_TYPE_DATETIME, COLUMN_TYPE_DATETIME_2, COLUMN_TYPE_TIMESTAMP
} from '../../constants';
import { isNoneSelection, isDatasetSelection, isAuditSelection } from './DataSelector';
import { useStateValue } from './state';
import Datagrid from './Datagrid';
import NoData from '../NoData';
import Loader from '../Loader';
import SpinnerIcon from '../SpinnerIcon';

const DatagridContainer = () => {
  const [context, dispatch] = useStateValue();
  const [state, setState] = useState({
    isLoading: false,
    isGridPageLoading: false
  });
  const unmounted = useRef(false);

  const { isLoading, isGridPageLoading } = state;

  const {
    filters, sort, datetimeFilters, datetimeFilterableFields, schema, dataSelector,
    records, page, sizePerPage, totalSize, hideNoErrorColumns
  } = context;
  const allFilters = filters.concat(datetimeFilters);
  const filtersAndSorting = { filters: allFilters, sort };
  const showExportDataButton = true; // TODO: Compute this.
  const {
    selectedRepository, selectedDataset, selectedAudit, selectionType
  } = dataSelector;

  const transformRecords = (srcRecords) => {
    const formatQualityLabel = (qualityLabel) => {
      if (!qualityLabel) {
        return LABEL_NOTSET;
      }
      if (qualityLabel === 'GOOD') {
        return LABEL_GOOD;
      }
      if (qualityLabel === 'BAD') {
        return LABEL_BAD;
      }
      if (qualityLabel === 'NOT_SET') {
        return LABEL_NOTSET;
      }
      return qualityLabel;
    };

    // TODO: Convert timestamp fields?
    // TODO: Set the unique errors fields
    return srcRecords.map((rec) => ({
      id: rec['id'],
      createdAt: rec['created_at'],
      data: rec['record'] || rec['plain_record'],
      qualityLabel: formatQualityLabel(rec['quality_label']),
      qualityFeedback: rec['quality_feedback'] || {},
      userAction: rec['user_action'],
      userFingerprintDisplay: rec['username'] || rec['user_fingerprint'],
      userFingerprint: rec['user_fingerprint'],
      tag: rec['tag'],
      errorsFields: rec['errors_fields'] === null ? [] : rec['errors_fields'],
      sourceOfErrors: rec['source_of_errors'] || [],
      resultLabel: rec['has_errors'] ? 'Error' : 'OK',
      hasErrors: rec['has_errors'],
      processedModels: rec['processed_models'],
      totalModels: rec['total_models'],
      screenshotsCount: rec['screenshots_count']
    }));
  };

  const fetchRecords = async (repository, dataset, audit, params, providedSelectionType) => {
    if (!providedSelectionType || isNoneSelection(providedSelectionType)) {
      return null;
    }
    let result;
    if (isDatasetSelection(providedSelectionType)) {
      result = await fetchDatasetResults(repository, dataset, params);
    } else if (isAuditSelection(providedSelectionType)) {
      result = await fetchAuditResults(repository, dataset, audit, params);
    }

    const newTotalSize = result.data['total_records'];
    const newRecords = transformRecords(result.data.records);

    return { records: newRecords, totalSize: newTotalSize };
  };

  const onDatetimeFiltersChanged = (columnName, newValues) => {
    // TODO: Refresh grid data and dispatch action.
    if (newValues === null) {
      delete datetimeFilters[columnName];
    } else {
      datetimeFilters[columnName] = newValues;
    }

    const options = {
      page,
      sizePerPage,
      filters,
      sort,
      datetimeFilters,
      schema
    };

    fetchDataAfterOptionsChanged(options);
  }

  const fetchDataAfterDataSelectorChanged = async () => {
    try {
      setState({ ...state, isLoading: true });
      const params = {
        page,
        sizePerPage,
        filters,
        sort,
        datetimeFilters,
        schema: {}  // NOTE: We always start with an empty schema.
      };
      const results = await fetchData(params);
      if (unmounted.current) return;

      let records;
      let totalSize;
      let schema;
      let datetimeFilterableFields;

      if (results) {
        records = results.records;
        totalSize = results.totalSize;
        const res = await fetchAndProcessSchema(repository, dataset, audit, selectionType);
        if (unmounted.current) return;

        if (res) {
          schema = res.schema;
          datetimeFilterableFields = res.datetimeFilterableFields;
        }
      }
      setState({ ...state, isLoading: false });
      dispatch({
        type: DATAGRID_INIT_ACTION,
        param: {
          records, totalSize, datetimeFilterableFields, schema
        }
      });
    } catch (e) {
      // TODO: not doing anything with exception
      console.log(e);
    }
  };

  const fetchDataAfterOptionsChanged = async (options) => {
    setState({ ...state, isGridPageLoading: true });

    const {
      page, sizePerPage, filters, sort, datetimeFilters
    } = options;

    const result = await fetchData(options);
    if (unmounted.current) return;

    const { records, totalSize } = result;

    const action = {
      type: DATAGRID_REFRESH_ACTION,
      param: {
        page,
        sizePerPage,
        filters,
        sort,
        datetimeFilters,
        records,
        totalSize
      }
    };

    dispatch(action);

    setState({ ...state, isGridPageLoading: false });
  };

  const fetchAndProcessSchema = async (repository, dataset, audit, selectionType) => {
    // Get dataset metadata and filterable fields
    const datetimeFilterableFields = new Set();
    let schema = {};

    if (isAuditSelection(selectionType)) {
      const result = await fetchAudit(repository, dataset, audit);
      schema = result.data.schema;
    } else if (isDatasetSelection(selectionType)) {
      const result = await fetchDataset(repository, dataset);
      schema = result.data.schema;
    }

    // Get the dataset header or the audit header which has the schema.
    // Get/update filterable fields
    Object.keys(schema).forEach((col) => {
      if ([COLUMN_TYPE_DATETIME_2, COLUMN_TYPE_DATETIME, COLUMN_TYPE_TIMESTAMP].includes(schema[col].type)) {
        datetimeFilterableFields.add(col);
      }
    });
    return { schema, datetimeFilterableFields };
  };

  const fetchData = async (params) => {
    const {
      selectedRepository, selectedDataset, selectedAudit, selectionType
    } = dataSelector;

    if (isNoneSelection(selectionType)) {
      return null;
    }

    const repository = selectedRepository ? selectedRepository.id : null;
    const dataset = selectedDataset ? selectedDataset.id : null;
    const audit = selectedAudit ? selectedAudit.id : null;

    return fetchRecords(repository, dataset, audit, params, selectionType);
  };

  // TODO: Check datagridOptions change.
  useEffect(() => {
    fetchDataAfterDataSelectorChanged();
  }, [dataSelector]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowChange = () => {
    // TODO: Re-render the plot?
  };

  // TODO: Check this.
  const selectedAuditHeaderRecord = { state: 'COMPLETED' };
  const auditState = selectedAuditHeaderRecord.state;

  const repository = selectedRepository ? selectedRepository.id : null;
  const dataset = selectedDataset ? selectedDataset.id : null;
  const audit = selectedAudit ? selectedAudit.id : null;

  const onTableChange = (type, options) => {
    // NOTE: datetimeFilters are not passed in, so we use them from the context.
    const newOptions = {
      ...options,
      datetimeFilters,
      schema
    };

    fetchDataAfterOptionsChanged(newOptions);
  };

  const onClearFiltersEnd = () => {
    const options = {
      page,
      sizePerPage,
      filters: [],
      sort,
      datetimeFilters: {},
      schema
    };
    fetchDataAfterOptionsChanged(options);
  };

  const onRefreshDatagrid = () => {
    const options = {
      page,
      sizePerPage,
      filters,
      sort,
      datetimeFilters,
      schema
    };
    fetchDataAfterOptionsChanged(options);
  };

  const toggleHideNoErrorColumns = () => dispatch({ type: TOGGLE_HIDE_NO_ERROR_COLUMNS });

  const showNoDatasetOrAudit = (
    selectionType === DATA_SELECTION_TYPE_DATASET || selectionType === DATA_SELECTION_TYPE_AUDIT);

  const showDatagrid = records !== null;

  // CHECK: Create maybe a different context for the datagrid options? DatagridContext, DataSelectorContext
  const dependencyList = [
    records, filters, sort, page, isLoading, sizePerPage, totalSize,
    selectionType, isGridPageLoading, hideNoErrorColumns
  ];

  // Add special classes to the body to prevent text selection when shift-click or ctrl-click selection
  // takes place
  const handleKeyDown = (e) => {
    if (e.keyCode === 16) {
      // Shift key pressed
      document.body.classList.add('shift-key-pressed');
    }

    if (e.keyCode === 17) {
      // Alt key pressed
      document.body.classList.add('ctrl-key-pressed');
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 16) {
      // Shift key depressed
      document.body.classList.remove('shift-key-pressed');
    }
    if (e.keyCode === 17) {
      // Alt key depressed
      document.body.classList.remove('ctrl-key-pressed');
    }
  };

  const handleDocumentBlur = () => {
    document.body.classList.remove('shift-key-pressed', 'ctrl-key-pressed');
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    document.addEventListener('blur', handleDocumentBlur);

    return () => {
      // Cleanup
      unmounted.current = true;
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('blur', handleDocumentBlur);
    };
  }, []);

  return useMemo(() => {
    if (!showNoDatasetOrAudit) {
      return (
        <>
          <Row>
            <Col className="mb-3">
              <h5>
                Result Records
              </h5>
              <NoData message="No data available. Please select a Dataset or Audit." />
            </Col>
          </Row>
        </>
      );
    }

    if (isLoading) {
      return <Loader />;
    }

    let overlayHeight = 0;

    if (isGridPageLoading) {
      const tbody = document.querySelector('.react-bootstrap-table > table > tbody');

      if (tbody) {
        overlayHeight = tbody.offsetHeight;
      }
    }

    return (
      <>
        <Row>
          <Col className="mb-3">
            <h5>
              Result Records
            </h5>
          </Col>

          <Col className="d-flex justify-content-end">
            <OverlayTrigger
              overlay={(
                <Tooltip>
                  Hide all columns with no detected errors
                </Tooltip>
              )}
            >
              <div>
                <Form.Check
                  type="switch"
                  label="Hide Good Columns"
                  className="mr-4"
                  id="test1"
                  checked={hideNoErrorColumns}
                  onChange={toggleHideNoErrorColumns}
                />
              </div>
            </OverlayTrigger>
            {showExportDataButton
              && (
                <ExportDataDropdown
                  disabled={!records || records.length === 0}
                  repositoryName={repository}
                  datasetName={dataset}
                  auditId={audit}
                  auditName={`${audit}`}
                  auditState={auditState}
                  filtersAndSorting={filtersAndSorting}
                />
              )}
          </Col>
        </Row>
        {showDatagrid && (
          <Row>
            <Col className="datagrid-wrapper">
              {isGridPageLoading && (
                <div
                  className="datagrid-overlay"
                  style={{ height: `${overlayHeight}px` }}
                >
                  <SpinnerIcon className="text-primary" />
                </div>
              )}
              <Datagrid
                dataSelector={dataSelector}
                keyField="id"
                data={records}
                filters={filters}
                sort={sort}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                remote
                onTableChange={onTableChange}
                onRowChange={onRowChange}
                loading={isGridPageLoading}
                datetimeFilters={datetimeFilters}
                schema={schema}
                applyDatetimeFilter={onDatetimeFiltersChanged}
                onClearFiltersEnd={onClearFiltersEnd}
                onRefreshDatagrid={onRefreshDatagrid}
                datetimeFilterableFields={datetimeFilterableFields}
                hideNoErrorColumns={hideNoErrorColumns}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }, dependencyList); // eslint-disable-line react-hooks/exhaustive-deps
};

export default DatagridContainer;
