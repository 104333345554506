import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { componentChildrenPropType } from '../../prop-types';

const StateContext = React.createContext();

export const useStateValue = () => useContext(StateContext);

const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

StateProvider.propTypes = {
  children: componentChildrenPropType.isRequired,
  reducer: PropTypes.func.isRequired
};

export { StateProvider };
