import axios from 'axios';
import { Auth, API } from 'aws-amplify';
import config from 'react-global-configuration';

const DEFAULT_AJAX_TIMEOUT = 0;

class DatasetService {
  getAuthToken = async () => {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  }

  generateDatetimeFilterQueryString = (datetimeFilters, utc) => {
    // Append datetime filters to URL
    let params = '';

    Object.keys(datetimeFilters).forEach((columnName) => datetimeFilters[columnName].forEach(
      (filterRow) => {
        if (filterRow.condition && filterRow.datetime) {
          const urlDate = utc
            ? encodeURIComponent(filterRow.datetime.format('YYYY-MM-DD HH:mm:ss')) : filterRow.datetime.valueOf();

          params += `&${columnName}=${filterRow.condition}:${urlDate}`;
        }
      }
    ));

    return params;
  }

  getRepositories() {
    return API.get('typo-dashboard', '/repositories');
  }

  getDatasets = async (repository) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    let url = `${clusterEndpoint}/management/api/v1/datasets`;
    if (repository) {
      url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets`;
    }
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  getDatasetsDetailed = async () => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/datasets?detailed=true`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  getDatasetsAudits = async (repository, dataset, detailed) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const detailedValue = detailed ? 'true' : 'false';

    let url = `${clusterEndpoint}/management/api/v1/audits?detailed=${detailedValue}`;

    if (repository && dataset) {
      url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/`
        + `audits/?detailed=${detailedValue}`;
    }

    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return obj.data;
  }

  getDatasetAuditRecords = async (repository, dataset, auditId, datetimeFilters, options) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/`
      + `datasets/${dataset}/audits/${auditId}/results?${this.generateDatetimeFilterQueryString(datetimeFilters)}`
      + `${this.generateOptionsQueryString(options || {})}`;

    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: DEFAULT_AJAX_TIMEOUT
    });
    return obj.data;
  }


  getScreenshotList = async (userFingerprint) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/screenshots?userFingerprint=${userFingerprint}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  getScreenshot = async (id) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/screenshots/${id}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  generateOptionsQueryString(opts) {
    const options = opts || {};
    // TODO: How to merge to object with object spread.
    let {
      sizePerPage, page, sort, filters
    } = options;

    if (sizePerPage === undefined) {
      sizePerPage = 10;
    }

    page = page || 1;

    filters = filters.map((filter) => `${filter.field}=${filter.op}:${encodeURIComponent(filter.value)}`);
    filters = filters.join('&');

    sort = Object.keys(sort).map((field) => `sort=${field}:${sort[field]}`);
    sort = sort.join('&');

    return `&records_per_page=${sizePerPage}&page=${page}&${filters}&${sort}`;
  }

  generateFieldsQueryString = (fields) => {
    let queryString = '';

    if (fields.x) {
      queryString += `&x=${fields['x']}`;
    }

    if (fields.y) {
      queryString += `&y=${fields['y']}`;
    }
    return queryString;
  }

  getDatasetResultsPlot = async (repository, dataset, datetimeFilters, options, fields) => {
    try {
      if (!repository || !dataset) {
        return { data: [] };
      }
      const token = await this.getAuthToken();
      const userSettings = config.get('userSettings');
      const { clusterEndpoint } = userSettings;

      const opts = {
        ...options,
        sizePerPage: 0 // NOTE: Get all records;
      };

      const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/`
        + `results/plot?${this.generateFieldsQueryString(fields)}${this.generateOptionsQueryString(opts)}`
        + `${this.generateDatetimeFilterQueryString(datetimeFilters, true)}`;
      const obj = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: DEFAULT_AJAX_TIMEOUT
      });
      return obj.data;
    } catch (e) {
      return undefined;
    }
  }

  getDatasetResults = async (repository, dataset, datetimeFilters, options) => {
    try {
      if (!repository || !dataset) {
        return { data: [] };
      }
      const token = await this.getAuthToken();
      const userSettings = config.get('userSettings');
      const { clusterEndpoint } = userSettings;

      const url = `${clusterEndpoint}/management/api/v1/repositories/${repository}/datasets/${dataset}/results?`
        + `${this.generateDatetimeFilterQueryString(datetimeFilters, true)}${this.generateOptionsQueryString(options)}`;
      const obj = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: DEFAULT_AJAX_TIMEOUT
      });
      return obj.data;
    } catch (e) {
      return undefined;
    }
  }

  getDatasetStats = async (repository, dataset) => {
    if (!repository || !dataset) {
      return { data: {} };
    }
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/dataset-stats/${repository}/${dataset}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  getDatasetMetadata = async (repositoryName, datasetName) => {
    if (!repositoryName || !datasetName) {
      return { data: [] };
    }
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/datasets/${datasetName}/metadata`;

    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return obj.data;
  }

  getDatasetAuditStats = async (repository, dataset, auditId) => {
    if (!repository || !dataset) {
      return { data: [] };
    }
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/audit-stats/${repository}/${dataset}/${auditId}`;
    const obj = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  updateDatasetConfig = async (name, datasetConfig) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/dataset/${name}`;
    const obj = await axios.post(url, datasetConfig, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  updateDatasetDisplayName = async (repository, name, displayName) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/dataset-display-name/${repository}/${name}`;
    const obj = await axios.post(url, { display_name: displayName }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj.data;
  }

  updateDatasetRecord = async (repository, dataset, id, data) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/dataset/${repository}/${dataset}/${id}`;
    const obj = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj;
  }

  updateAuditResultRecord = async (repositoryName, datasetName, auditId, id, data) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
      + `datasets/${datasetName}/audits/${auditId}/results/${id}/label`;
    const obj = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj;
  }

  updateAuditHeader = async (repositoryName, datasetName, auditId, data) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;
    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
      + `datasets/${datasetName}/audits/${auditId}`;
    const obj = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return obj;
  }

  getAuditRecord = async (repositoryName, datasetName, auditId) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
      + `datasets/${datasetName}/audits/${auditId}`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  putAuditRecord = async (repositoryName, datasetName) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const url = `${config.get('clusterManagementEndpoint')}/repositories/${repositoryName}/`
      + `datasets/${datasetName}/audits`;

    return axios.post(url, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  getDatasetByName = async (name) => {
    const url = `${config.get('clusterManagementEndpoint')}/dataset/${name}`;
    const token = await this.getAuthToken();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  }

  getIndexes = async (datasetName) => {
    const url = `${config.get('clusterManagementEndpoint')}/dataset/${datasetName}`;
    const token = await this.getAuthToken();
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return result.data.duplicate_checks;
  }

  putDuplicateCheck = async (repository, dataset, duplicateCheckName, duplicateCheckFields) => {
    const url = `${config.get('clusterManagementEndpoint')}/repositories/`
      + `${repository}/datasets/${dataset}/duplicate-checks/${duplicateCheckName}`;
    const token = await this.getAuthToken();
    const response = await fetch(url, {
      method: 'put',
      body: JSON.stringify(duplicateCheckFields),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  deleteIndex = async (repository, dataset, duplicateCheckName) => {
    const url = `${config.get('clusterManagementEndpoint')}/repositories/`
      + `${repository}/datasets/${dataset}/duplicate-checks/${duplicateCheckName}`;
    const token = await this.getAuthToken();
    await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  createAuditExport = async (repositoryName, datasetName, auditId, fileType, filtersAndSorting) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/`
      + `${repositoryName}/datasets/${datasetName}/audits/${auditId}/exports`;

    const obj = await axios.post(url, { type: fileType, filters_and_sorting: filtersAndSorting }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return obj.data;
  }

  createDatasetExport = async (repositoryName, datasetName, fileType, filtersAndSorting) => {
    const token = await this.getAuthToken();
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/datasets/${datasetName}/exports`;

    const obj = await axios.post(url, { type: fileType, filters_and_sorting: filtersAndSorting }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return obj.data;
  }

  getExportStates = async (url, downloadUrl) => {
    const token = await this.getAuthToken();

    // Using fetch because there's no way to avoid Axios redirecting the request to the
    // generated exported file on the XHR.
    const request = new Request(url, {
      method: 'GET',
      redirect: 'manual',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    });

    const response = await fetch(request);

    if (response.type === 'opaqueredirect') {
      return {
        completed: true,
        downloadUrl
      };
    }

    const { data } = await response.json();

    return data;
  };

  getAuditExportStates = async (repositoryName, datasetName, auditId, exportId) => {
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
      + `datasets/${datasetName}/audits/${auditId}/exports/${exportId}`;

    const downloadUrl = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
          + `datasets/${datasetName}/audits/${auditId}/exports/${exportId}/download`;

    return this.getExportStates(url, downloadUrl);
  }

  getDatasetExportStates = async (repositoryName, datasetName, exportId) => {
    const userSettings = config.get('userSettings');
    const { clusterEndpoint } = userSettings;

    const url = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
      + `datasets/${datasetName}/exports/${exportId}`;

    const downloadUrl = `${clusterEndpoint}/management/api/v1/repositories/${repositoryName}/`
          + `datasets/${datasetName}/exports/${exportId}/download`;

    return this.getExportStates(url, downloadUrl);
  }

  deleteDataset = async (repository, dataset) => {
    const url = `${config.get('clusterManagementEndpoint')}/repositories/`
      + `${repository}/datasets/${dataset}`;

    const token = await this.getAuthToken();

    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  deleteAudit = async (repository, dataset, auditId) => {
    const url = `${config.get('clusterManagementEndpoint')}/repositories/`
      + `${repository}/datasets/${dataset}/audits/${auditId}`;

    const token = await this.getAuthToken();

    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

export default DatasetService;
