import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { SUPPORT_EMAIL } from '../constants';

const Error = ({ message }) => (
  <Alert variant="danger">
    <Alert.Heading>We have detected an error</Alert.Heading>
    <hr />
    {message && (
      <p>
        <strong>{message}</strong>
      </p>
    )}
    <p>
      Please check your internet connection, reload the page and try again.
      If the problem persists please&nbsp;contact <a href={`mailto:${SUPPORT_EMAIL}`} className="text-danger"><b>{SUPPORT_EMAIL}</b></a>.
    </p>
  </Alert>
);

Error.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Error.defaultProps = {
  message: ''
};

export default Error;
