import React from 'react';
import PropTypes from 'prop-types';

import {
  getAbsoluteRowIndex, getCellKey
} from '../../context/datagridCell/datagridCellUtils';
import {
  gridCoordinatePropType, refPropType
} from '../../prop-types';


const OptimizedDatagridCell = ({
  // Data
  content, columnIndex, relativeRowIndex,
  cursorPosition,
  tableRef,
  // Actions
  contextMenuOpened, deselectCell,
  moveCursorTo, selectCell, selectRectangle,
  toggleCellLabel,
  selectedCells
}) => {
  const rowIndex = getAbsoluteRowIndex(relativeRowIndex, tableRef);
  const cellKey = getCellKey(rowIndex, columnIndex);

  // Click
  const onCellClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.ctrlKey) {
      // Select single cell
      if (selectedCells.has(cellKey)) {
        deselectCell(rowIndex, columnIndex);
      } else {
        selectCell(rowIndex, columnIndex);
      }
    } else if (e.shiftKey) {
      // Rectangular selection
      selectRectangle({
        row: rowIndex,
        column: columnIndex
      });
    } else if (e.altKey) {
      // Toggle cell state
      toggleCellLabel(rowIndex, columnIndex);
      moveCursorTo(rowIndex, columnIndex);
    } else {
      // Move cursor and deselect other cells
      moveCursorTo(rowIndex, columnIndex);
    }
  };

  // Right click
  const onContextMenu = () => {
    contextMenuOpened(rowIndex, columnIndex);
  };

  const isSelected = selectedCells.has(cellKey);
  const hasCursor = cursorPosition.row === rowIndex && cursorPosition.column === columnIndex;

  // TODO: Set the selection style as the in-rectangular-selection;
  // + ${isInRectangularSelection ? ' in-rectangular-selection' : ''}`

  return (
    <div
      onKeyPress={(e) => { if (e.keyCode === 13) { onCellClick(); } }}
      tabIndex={0}
      role="button"
      onClick={onCellClick}
      onContextMenu={onContextMenu}
      className={
        `datagrid-cell${isSelected ? ' selected' : ''}${hasCursor ? ' cursor' : ''}`
      }
    >
      {content && <span className="cell-content">{content}</span>}
      {!content && <span className="cell-spacer">&nbsp;</span>}
    </div>
  );
};

OptimizedDatagridCell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contextMenuOpened: PropTypes.func.isRequired,
  cursorPosition: gridCoordinatePropType.isRequired,
  deselectCell: PropTypes.func.isRequired,
  moveCursorTo: PropTypes.func.isRequired,
  relativeRowIndex: PropTypes.number.isRequired,
  selectCell: PropTypes.func.isRequired,
  selectRectangle: PropTypes.func.isRequired,
  tableRef: refPropType,
  toggleCellLabel: PropTypes.func.isRequired
};

OptimizedDatagridCell.defaultProps = {
  content: null,
  tableRef: undefined
};

const propsAreEqual = (prevProps, nextProps) => {
  const {
    relativeRowIndex, columnIndex
  } = prevProps;

  const rowIndex = relativeRowIndex;

  const cellKey = getCellKey(rowIndex, columnIndex);
  if (prevProps.selectedCells.has(cellKey)) {
    return false;
  }

  if (nextProps.selectedCells.has(cellKey)) {
    return false;
  }

  const hadCursor = prevProps.cursorPosition.row === rowIndex && prevProps.cursorPosition.column === columnIndex;
  if (hadCursor) {
    return false;
  }

  const willHaveCursor = nextProps.cursorPosition.row === rowIndex && nextProps.cursorPosition.column === columnIndex;
  if (willHaveCursor) {
    return false;
  }

  // TODO: Should return true
  // return true;
};

export default React.memo(OptimizedDatagridCell, propsAreEqual);
