import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Container, Col, FormControl, FormGroup, FormLabel, Row
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';

import Error from '../components/Error';
import Info from '../components/Info';
import LoaderButton from '../components/LoaderButton';
import { passwordValidator } from '../validators';
import { nonMatchingPasswordsError, requiredFieldError } from '../validationErrors';
import { handleFieldBlur, handleFieldChange, validateForm } from '../formMethods';

class PasswordReset extends Component {
  validators = {
    password: passwordValidator,
    confirmPassword: (confirmPassword) => {
      const { fieldValues: { password } } = this.state;

      if (!confirmPassword) {
        return requiredFieldError;
      }

      if (confirmPassword !== password) {
        return nonMatchingPasswordsError;
      }

      return null;
    }
  }

  constructor(props) {
    super(props);

    this.email = atob(props.match.params.email);
    this.code = props.match.params.code;

    this.state = {
      errorCode: false,
      loading: false,
      fieldValues: {
        password: '',
        confirmPassword: ''
      },
      fieldErrors: {
        password: null,
        confirmPassword: null
      },
      fieldsValidated: {
        password: false,
        confirmPassword: false
      }
    };

    this.handleFieldBlur = handleFieldBlur.bind(this);
    this.handleFieldChange = handleFieldChange.bind(this);
    this.validateForm = validateForm.bind(this);

    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({
      loading: true
    });

    const { fieldValues: { password } } = this.state;
    const { history } = this.props;

    try {
      await Auth.forgotPasswordSubmit(this.email, this.code, password);

      if (this.unmounted) {
        return;
      }

      history.push('/login', {
        passwordReset: true
      });
    } catch (e) {
      if (e.code) {
        this.setState({
          errorCode: e.code,
          loading: false
        });
      } else {
        this.setState({
          errorCode: 'GenericError',
          loading: false
        });
      }
    }
  };

  getErrorMessage = () => {
    const { errorCode } = this.state;

    switch (errorCode) {
      case 'ExpiredCodeException':
        return (
          <Info variant="danger" title="Link expired" icon={faClock}>
            Your password reset link has expired, please&nbsp;
            <Link
              role="button"
              tabIndex="0"
              to="/forgot-password"
              className="text-danger text-link"
            >
              <b>click here</b>
            </Link> and fill in your email address to get a new one sent to
            your inbox.
          </Info>
        );
      case 'NotAuthorizedException':
        return (
          <Info variant="success" title="Email verified successfuly" icon={faCheck}>
            Your email has already been verified.<br />
            <Link to="/login" className="text-success"><b>Click here</b></Link> to log in.
          </Info>
        );
      default:
        return (
          <Error />
        );
    }
  }

  render() {
    const {
      errorCode, fieldErrors, fieldValues, fieldsValidated, loading
    } = this.state;

    return (
      <Container>
        <Row className="justify-content-center">
          <Col className="small-centered-container">
            {errorCode ? this.getErrorMessage() : (
              <Row>
                <Col>
                  <h2>Change Your Password</h2>
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="password" variant="large">
                      <FormLabel>Choose a new password</FormLabel>
                      <FormControl
                        value={fieldValues.password}
                        type="password"
                        onChange={this.handleFieldChange}
                        onBlur={this.handleFieldBlur}
                        isInvalid={fieldErrors.password !== null}
                        isValid={fieldsValidated.password && fieldErrors.password === null}
                      />
                      {fieldErrors.password && fieldErrors.password}
                    </FormGroup>
                    <FormGroup controlId="confirmPassword" variant="large">
                      <FormLabel>Confirm</FormLabel>
                      <FormControl
                        value={fieldValues.confirmPassword}
                        type="password"
                        onChange={this.handleFieldChange}
                        onBlur={this.handleFieldBlur}
                        isInvalid={fieldErrors.confirmPassword !== null}
                        isValid={fieldsValidated.confirmPassword && fieldErrors.confirmPassword === null}
                      />
                      {fieldErrors.confirmPassword && (
                        <FormControl.Feedback type="invalid">
                          {fieldErrors.confirmPassword}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <LoaderButton
                      block
                      type="submit"
                      isLoading={loading}
                      text="Change password"
                      loadingText="Please wait..."
                    />
                  </form>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

PasswordReset.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

export default PasswordReset;
