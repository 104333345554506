import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Row, Col, Form, Button, Table, Badge, Alert, Breadcrumb
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

import Index from './DuplicateChecksIndex';
import Title from '../components/Title';
import LoaderButton from '../components/LoaderButton';
import Loader from '../components/Loader';
import ModalOkCancel from '../components/ModalOkCancel';
import ModalOk from '../components/ModalOk';
import Error from '../components/Error';
import DatasetService from '../services/dataset';

class DuplicateChecks extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { repository, dataset } } } = props;

    const tablename = dataset;

    this.state = {
      field: '',
      indexes: [],
      newIndex: new Index(),
      isLoading: true,
      error: undefined,
      repository,
      dataset,
      tablename
    };

    this.datasetService = new DatasetService();
  }

  async componentDidMount() {
    try {
      const indexes = await this.getIndexes();
      this.setState({
        indexes,
        // availableFields,
        isLoading: false,
        showDeleteConfigurationModal: false
      });
    } catch (err) {
      this.setState({
        error: err
      });
    }
  }

  async getAvailableFields() {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(['field_1', 'field_2', 'field_3', 'field_4']);
      }, 1000);
    });
    return promise;
  }

  refreshIndexes = async () => {
    const indexes = await this.getIndexes();
    this.setState({
      indexes
    });
  }

  addField = () => {
    const { field } = this.state;
    let { newIndex: index } = this.state;

    index = index.addField(field);

    this.setState({
      newIndex: index,
      field: ''
    });
  }

  removeField = (field) => {
    let { newIndex: index } = this.state;

    index = index.removeField(field);

    this.setState({
      newIndex: index
    });
  }

  fieldChanged = (event) => {
    const { value } = event.target;
    this.setState({
      field: value
    });
  }

  canSaveNewIndex = () => {
    const { newIndex } = this.state;
    return newIndex.isValid();
  }

  saveNewIndexHandler = async () => {
    try {
      const { newIndex: index } = this.state;

      await this.saveNewIndex(index);
      const indexes = await this.getIndexes();

      this.setState({
        indexes,
        newIndex: new Index()
      });
    } catch (err) {
      const errorMessage = (err === 'INDEX_ALREADY_EXIST') ? 'Configuration already exists. Can not save.' : err;
      this.setState({
        showErrorModal: true,
        errorMessage
      });
    }
  }

  saveNewIndex = async (index) => {
    const { dataset, repository } = this.state;

    const body = { fields: index.fields.join(',') };

    return this.datasetService.putDuplicateCheck(repository, dataset, index.name, body);
  }

  getIndexes = async () => {
    const { tablename } = this.state;

    return this.datasetService.getIndexes(tablename);
  }

  deleteIndex = async (indexName) => {
    const { repository, tablename } = this.state;

    return this.datasetService.deleteIndex(repository, tablename, indexName);
  }

  confirmDeleteIndex = async () => {
    const { selectedConfiguration } = this.state;

    try {
      await this.deleteIndex(selectedConfiguration);
      const indexes = await this.getIndexes();
      this.setState({
        indexes,
        showDeleteConfigurationModal: false,
        selectedConfiguration: undefined
      });
    } catch (err) {
      alert(err);
    }
  }

  indexDeleteHandler = async (indexName) => {
    this.setState({
      selectedConfiguration: indexName,
      showDeleteConfigurationModal: true
    });
  }

  canDeleteIndex = (index) => index.status === 'SUCCEEDED' || index.status === 'FAILED'

  handleClose = () => {
    this.setState({
      showDeleteConfigurationModal: false,
      selectedConfiguration: undefined
    });
  }

  closeErrorModal = () => {
    this.setState({
      showErrorModal: false
    });
  }

  canAddField() {
    const { field } = this.state;

    return field.trim() !== '';
  }

  // TODO:
  // 3. Filter the list of remaining fields.
  // 4. Generate the name for the index
  // 5. Allow save only in the case of having at least one field.
  // 6. Pack as a component
  // 7. Check how we can align horizontally the drop down with the button.
  // 8. Generate a component for the select drop down.
  // 9. Remove the item from the list and add to another list.

  renderIndexStatus(status) {
    const mapping = {
      PENDING: 'secondary',
      FAILED: 'danger',
      SUCCEEDED: 'success',
      PROCESSING: 'warning'
    };

    return <Badge variant={mapping[status]}>{status}</Badge>;
  }

  renderIndexesTable() {
    const { indexes } = this.state;

    return (indexes.length > 0)
      ? (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Fields</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              indexes.map((index, counter) => (
                <tr
                  key={counter} // eslint-disable-line react/no-array-index-key
                >
                  <td>{index.name}</td>
                  <td>{index.fields}</td>
                  <td>Exact match</td>
                  <td>{this.renderIndexStatus(index.status)}</td>
                  <td>
                    <Button
                      disabled={!this.canDeleteIndex(index)}
                      variant="danger"
                      size="sm"
                      onClick={() => this.indexDeleteHandler(index.name)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      ) : <p>No configurations available.</p>;
  }

  renderNewIndexSelectedFields = () => {
    const { newIndex } = this.state;

    if (newIndex.fields.length > 0) {
      return newIndex.fields.map((field, index) => (
        <Fragment
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <h5>
            <Badge
              pill
              variant="secondary"
              onClick={() => this.removeField(field)}
            >
              {field}&nbsp;
              <FontAwesomeIcon icon={faWindowClose} />
            </Badge>
            &nbsp;
          </h5>
        </Fragment>
      ));
    }

    return (
      <Alert variant="info">No fields were selected for this configuration. Please select a field below.</Alert>
    );
  }

  renderContent() {
    const { field, isLoading, newIndex } = this.state;

    if (isLoading) {
      return (
        <Col><Loader /></Col>
      );
    }
    return (
      <>
        <Col>
          <h4>Configurations</h4>
          {this.renderIndexesTable()}
          <FontAwesomeIcon icon={faSyncAlt} onClick={this.refreshIndexes} style={{ cursor: 'hand' }} />
        </Col>
        <Col className="ml-2">
          <h4>New Configuration</h4>
          <Form>
            <Form.Group as={Row}>
              <Col sm="2">
                <Form.Label>Name</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control readOnly plaintext value={newIndex.name}></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="2">
                <Form.Label>Type</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control readOnly plaintext value="Exact match"></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="2">
                <Form.Label>Selected Fields</Form.Label>
              </Col>
              <Col sm="9">
                {this.renderNewIndexSelectedFields()}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm="2">
                <Form.Label>Field</Form.Label>
              </Col>
              <Col sm="9">
                <Form.Control value={field} onChange={this.fieldChanged}></Form.Control>
              </Col>
              <Col sm="1">
                <Button disabled={!this.canAddField()} onClick={this.addField}>Add</Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <LoaderButton
                  variant="primary"
                  disabled={!this.canSaveNewIndex()}
                  isLoading={isLoading}
                  text="Save"
                  loadingText="Saving…"
                  onClick={this.saveNewIndexHandler}
                />
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </>
    );
  }

  render() {
    const {
      error, errorMessage, selectedConfiguration, showDeleteConfigurationModal,
      showErrorModal, tablename
    } = this.state;

    return (
      <>
        <ModalOk
          show={showErrorModal}
          title="Error"
          onOkClick={this.closeErrorModal}
          message={errorMessage}
        />
        <ModalOkCancel
          title="Delete Configuration"
          message={(
            <p>
              &quot;Are you sure that you want to delete the unique value configuration&quot;
              <strong>{selectedConfiguration}?</strong>
            </p>
          )}
          show={showDeleteConfigurationModal}
          okLabel="Delete"
          onHide={this.handleClose}
          cancelOnClick={this.handleClose}
          okOnClick={this.confirmDeleteIndex}
        />
        <Row>
          <Col>
            <Breadcrumb>
              <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <LinkContainer to="/repositories">
                <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to="/datasets"><Breadcrumb.Item href="/datasets">Datasets</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item href={`/datasets?dataset=${tablename}`}>
                {tablename}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Duplicate Check</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title text="Duplicate Check" />
          </Col>
        </Row>
        <div className="limited-width-md">
          <Row>
            {error ? <Col><Error /></Col> : this.renderContent()}
          </Row>
        </div>
      </>
    );
  }
}

DuplicateChecks.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
};

export default DuplicateChecks;
