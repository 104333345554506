import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AsyncValidationEvent from './AsyncValidationEvent';
import SpinnerIcon from '../SpinnerIcon';
import './AsyncValidatorFormControl.scss';

class AsyncValidatorFormControl extends React.Component {
  constructor(props) {
    super(props);

    this.asyncCallStack = [];

    this.debounceTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { isRequired, value } = this.props;

    if (value !== prevProps.value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      const { validatorFunction, validationResult, preValidateCallback } = this.props;

      if (isRequired && !value.trim()) {
        validationResult('This field is required.');
        return;
      }

      this.cancelAsyncCalls();
      preValidateCallback();

      this.debounceTimeout = setTimeout(() => {
        this.asyncCallStack.push(new AsyncValidationEvent(value, validatorFunction, validationResult));
      }, 500);
    }
  }

  cancelAsyncCalls() {
    this.asyncCallStack.forEach((asyncCall) => asyncCall.cancel());
    this.asyncCallStack = [];
  }

  render() {
    // Explictly extract onChange
    const {
      autoFocus, disabled, errorMessage, isInvalid, isValid,
      onChange, placeholder, value, type, validating
    } = this.props;

    return (
      <div className="async-validator-wrapper">
        <Form.Control
          autoFocus={autoFocus}
          type={type}
          value={value}
          onChange={onChange}
          isValid={isValid}
          isInvalid={isInvalid}
          disabled={disabled}
          placeholder={placeholder}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
        {validating && <SpinnerIcon />}
      </div>
    );
  }
}

AsyncValidatorFormControl.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  preValidateCallback: PropTypes.func,
  type: PropTypes.string.isRequired,
  validatorFunction: PropTypes.func.isRequired,
  validationResult: PropTypes.func.isRequired,
  value: PropTypes.string,
  validating: PropTypes.bool.isRequired
};

AsyncValidatorFormControl.defaultProps = {
  autoFocus: undefined,
  disabled: undefined,
  isInvalid: undefined,
  isRequired: false,
  isValid: undefined,
  placeholder: undefined,
  preValidateCallback: () => {},
  value: ''
};

export default AsyncValidatorFormControl;
