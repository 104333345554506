import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import {
  Button, Col, Form, Row, Modal
} from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faEraser } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';

import IconButton from './IconButton';
import { datetimeFilterPropType } from '../prop-types';
import './DatetimeFilterModal.scss';

const CONDITION_EQUAL = 'eq';
const CONDITION_GREATER_THAN = 'gt';
const CONDITION_GREATER_EQUAL_THAN = 'ge';
const CONDITION_LOWER_THAN = 'lt';
const CONDITION_LOWER_EQUAL_THAN = 'le';

const DATETIME_FILTERING_CONDITION_OPTIONS = {
  [CONDITION_EQUAL]: 'Equal',
  [CONDITION_GREATER_THAN]: 'Is after',
  [CONDITION_GREATER_EQUAL_THAN]: 'Is after or equal to',
  [CONDITION_LOWER_THAN]: 'Is before',
  [CONDITION_LOWER_EQUAL_THAN]: 'Is before or equal to'
};

const blankValues = [
  { condition: '', datetime: null },
  { condition: '', datetime: null }
];

class DatetimeFilterModal extends Component {
  constructor(props) {
    super(props);
    const { appliedFilter } = props;

    const values = appliedFilter !== null ? cloneDeep(appliedFilter) : cloneDeep(blankValues);

    const { errors, isValid } = this.validateValues(values);

    this.state = {
      values,
      errors,
      isValid,
      enabledConditionOptions: [
        Object.keys(DATETIME_FILTERING_CONDITION_OPTIONS),
        this.getValidConditionOptions(values[0].condition)
      ]
    };
  }

  validateValues = (values) => {
    let isValid = true;
    const errors = [{ condition: '', datetime: '' }, { condition: '', datetime: '' }];

    values.forEach((row, index) => {
      const otherRowIndex = 1 - index;

      if (row.datetime && typeof (row.datetime) === 'string') {
        errors[index].datetime = 'Please enter a valid date.';
        isValid = false;
      } else if ((row.condition || row.datetime) && !(row.condition && row.datetime)) {
        // Ensure no row is half-completed
        isValid = false;
      } else if (
        // Evaluate selected date range when both rows are completed, with valid dates.
        // Only check the row that has BEFORE conditions (display the erros at the
        // end of the range).
        row.condition && row.datetime
        && values[otherRowIndex].condition && values[otherRowIndex].datetime
        && typeof (values[otherRowIndex].datetime) === 'object'
        && [CONDITION_LOWER_THAN, CONDITION_LOWER_EQUAL_THAN].includes(row.condition)
      ) {
        // Check if date range is valid
        if (
          (values[otherRowIndex].condition === CONDITION_GREATER_EQUAL_THAN
            && row.condition === CONDITION_LOWER_EQUAL_THAN
            && values[otherRowIndex].datetime > row.datetime
          )
          || (
            (values[otherRowIndex].condition === CONDITION_GREATER_THAN
            || row.condition === CONDITION_LOWER_THAN)
            && values[otherRowIndex].datetime >= row.datetime
          )
        ) {
          errors[index].datetime = `Please select a later date than Condition ${otherRowIndex + 1}.`;
          isValid = false;
        }
      }
    });
    return { isValid, errors };
  }

  getValidConditionOptions = (primaryCondition) => {
    // One condition is considered primary, and the other one's
    // options will be limited
    if (primaryCondition === CONDITION_EQUAL) {
      return [];
    }

    if ([CONDITION_LOWER_THAN, CONDITION_LOWER_EQUAL_THAN].includes(primaryCondition)) {
      return [CONDITION_GREATER_THAN, CONDITION_GREATER_EQUAL_THAN];
    }

    if ([CONDITION_GREATER_THAN, CONDITION_GREATER_EQUAL_THAN].includes(primaryCondition)) {
      return [CONDITION_LOWER_THAN, CONDITION_LOWER_EQUAL_THAN];
    }

    return Object.keys(DATETIME_FILTERING_CONDITION_OPTIONS);
  }

  onChange = (rowNumber, changedField) => (event) => {
    const { values } = this.state;
    const newState = {};

    // onChange: select inputs have object type (event) and date inputs return Moment.js objects
    values[rowNumber][changedField] = event.target !== undefined ? event.target.value : event;

    if (changedField === 'condition') {
      // Calculate new condition options
      const newEnabledConditionOptions = [];

      // Condition changed, update the other field
      const otherRowConditionOptions = this.getValidConditionOptions(values[rowNumber][changedField]);

      const otherRowIndex = 1 - rowNumber;

      if (!otherRowConditionOptions.includes(values[otherRowIndex].condition)) {
        // If the other field has no options, or the current one is no longer valid,
        // reset row to blank values.
        values[otherRowIndex].condition = '';
        values[otherRowIndex].datetime = '';
      }

      newEnabledConditionOptions[rowNumber] = Object.keys(DATETIME_FILTERING_CONDITION_OPTIONS);
      newEnabledConditionOptions[otherRowIndex] = otherRowConditionOptions;

      newState.enabledConditionOptions = newEnabledConditionOptions;
    }

    const { isValid, errors } = this.validateValues(values);

    newState.errors = errors;
    newState.isValid = isValid;
    newState.values = values;

    this.setState(newState);
  }

  applyFilter = () => {
    let { values } = this.state;
    const { applyFilter: providedApplyFilter, closeModal } = this.props;

    if (values[0].condition === '' && values[1].condition === '') {
      values = null;
    }

    providedApplyFilter(values);
    closeModal();
  }

  clearFilter = () => {
    const { applyFilter: providedApplyFilter, closeModal } = this.props;

    providedApplyFilter(null);
    closeModal();
  }

  clearRow = (index) => {
    const { enabledConditionOptions, errors, values } = this.state;

    values[index].condition = '';
    values[index].datetime = '';

    errors[index].condition = '';
    errors[index].datetime = '';

    const newEnabledConditionOptions = [];

    newEnabledConditionOptions[1 - index] = Object.keys(DATETIME_FILTERING_CONDITION_OPTIONS);
    newEnabledConditionOptions[index] = enabledConditionOptions[index];

    this.setState({
      errors,
      values,
      enabledConditionOptions: newEnabledConditionOptions
    });
  }

  getConditionOptions = (rowIndex) => {
    const { enabledConditionOptions } = this.state;

    return (
      <>
        <option value="">Select a condition</option>
        {Object.keys(DATETIME_FILTERING_CONDITION_OPTIONS).map(
          (optionCode, index) => (
            <option
              value={optionCode}
              key={index} // eslint-disable-line react/no-array-index-key
              disabled={!enabledConditionOptions[rowIndex].includes(optionCode)}
            >
              {DATETIME_FILTERING_CONDITION_OPTIONS[optionCode]}
            </option>
          )
        )}
      </>
    );
  }

  hasAppliedFilter(values) {
    return values
      ? values.some((row) => row.condition && row.datetime)
      : false;
  }

  render() {
    const {
      enabledConditionOptions, errors, isValid, values
    } = this.state;
    const { appliedFilter, closeModal, modalVisible } = this.props;

    return (
      <Modal size="lg" show={modalVisible} onHide={closeModal} onClick={(e) => e.stopPropagation()}>
        <Modal.Header closeButton>
          <Modal.Title><FontAwesomeIcon icon={faCalendar} className="mr-2" />Date & Time Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="datetime-filter-modal-body">
          <Form className="mb-3 mt-1">
            {values.map((rowValues, index) => (
              <Fragment
                key={index} // eslint-disable-line react/no-array-index-key
              >
                <Row>
                  <Col>
                    <span className="d-block mb-2">Condition {index + 1}</span>
                  </Col>
                  <Col>
                    <span className="d-block mb-2">Value</span>
                  </Col>
                  <Col className="col-sm-2 col-md-1" />
                </Row>
                <Row className={index === 0 && 'mb-4'}>
                  <Col>
                    <Form.Control
                      as="select"
                      onChange={this.onChange(index, 'condition')}
                      value={values[index].condition}
                      isInvalid={errors[index].condition}
                      disabled={enabledConditionOptions[index].length === 0}
                    >
                      {this.getConditionOptions(index)}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      utc
                      closeOnSelect
                      onChange={this.onChange(index, 'datetime')}
                      value={rowValues.datetime}
                      inputProps={{
                        errorMessage: errors[index].datetime,
                        disabled: enabledConditionOptions[index].length === 0
                      }}
                      renderInput={(props) => {
                        const inputProps = { ...props };
                        delete inputProps.errorMessage;

                        return (
                          <div>
                            <Form.Control
                              type="text"
                              {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                              isInvalid={props.errorMessage !== ''}
                            />
                            {props.errorMessage && <div className="invalid-feedback">{props.errorMessage}</div>}
                          </div>
                        );
                      }}
                    />
                  </Col>
                  <Col className="col-sm-2 col-md-1">
                    <IconButton
                      icon={faEraser}
                      placement="top"
                      label="Clear"
                      onClick={() => { this.clearRow(index); }}
                      variant="warning"
                    />
                  </Col>
                </Row>
              </Fragment>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.hasAppliedFilter(appliedFilter) && (
            <Button size="sm" variant="danger" onClick={this.clearFilter}>
              Clear
            </Button>
          )}
          <Button size="sm" variant="primary" onClick={this.applyFilter} disabled={!isValid}>
            Apply
          </Button>
          <Button size="sm" variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DatetimeFilterModal.propTypes = {
  appliedFilter: datetimeFilterPropType,
  applyFilter: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired
};

DatetimeFilterModal.defaultProps = {
  appliedFilter: null
};

export default DatetimeFilterModal;
export { DATETIME_FILTERING_CONDITION_OPTIONS };
