import React, { Component } from 'react';
import { API } from 'aws-amplify';
import {
  Container, Row, Col
} from 'react-bootstrap';
import ReactRouterPropTypes from 'react-router-prop-types';

import Loader from '../components/Loader';
import Title from '../components/Title';
import TypoLibInstructions from '../components/TypoLibInstructions';

class ApiKeyCreationSucceed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      const apiKey = await this.getApiKey();

      this.setState({
        apiKeyId: apiKey.apiKeyId,
        domainFilters: apiKey.domainFilters,
        isLoading: false
      });
    } catch (e) {
      alert(e);
    }
  }

  getApiKey() {
    const { match } = this.props;

    return API.get('typo-dashboard', `/apikeys/${match.params.id}`);
  }

  renderView() {
    const {
      apiKeyId, domainFilters
    } = this.state;

    const domainFiltersListItems = domainFilters.map(
      (domainFilter, index) => <li key={index}>{domainFilter}</li> // eslint-disable-line react/no-array-index-key
    );

    return (
      <>
        <div className="alert alert-success mb-4">
          <strong>{apiKeyId}</strong>&nbsp;
          was successfully created and is ready to be used on the following URLs:
          <ul className="mt-3">
            {domainFiltersListItems}
          </ul>
        </div>

        <h3 className="4">Next Steps</h3>
        <TypoLibInstructions apiKey={apiKeyId} />
      </>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Container>
        <Row>
          <Col>
            <Title text="Repository Successfully Created" />
            {isLoading ? <Loader /> : this.renderView()}
          </Col>
        </Row>
      </Container>
    );
  }
}

ApiKeyCreationSucceed.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
};

export default ApiKeyCreationSucceed;
