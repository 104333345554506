import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import './SpinnerIcon.css';

const SpinnerIcon = React.forwardRef(
  ({
    className, spinDuration, style, ...props
  }, ref) => (
    <FontAwesomeIcon
      ref={ref}
      icon={faCircleNotch}
      className={className ? `spinner-icon ${className}` : 'spinner-icon'}
      style={{ animation: `spin infinite ${spinDuration} linear`, ...style }}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  )
);

SpinnerIcon.propTypes = {
  className: PropTypes.string,
  spinDuration: PropTypes.string,
  style: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

SpinnerIcon.defaultProps = {
  className: '',
  spinDuration: '0.7s',
  style: {}
};

export default SpinnerIcon;
