import React, { Component, Fragment } from 'react';
import { Jumbotron } from 'react-bootstrap';

export default class Welcome extends Component {

    render() {
        return (<Fragment>
            <Jumbotron onContextMenu={this.handleContextMenu}>
                <h1 className="display-4">Welcome to Typo</h1>
                <p className="lead">Unlike traditional data management tools that attempt to resolve data errors after they are saved, Typo detects errors in real-time. This enables immediate correction prior to storage and propagation into downstream systems and reports. This deployment includes Typo core data management platform, TYPO.ai data management including automated intelligence, TYPO.ai data management including streaming data, and Data Quality Barrier including data management for multiple data origins.</p>
                <hr className="my-4" />
            </Jumbotron>
        </Fragment>
        )
    }
}