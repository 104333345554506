// Action types
export const START_DOWNLOAD = 'START_DOWNLOAD';
export const DOWNLOAD_PENDING = 'DOWNLOAD_PENDING';
export const DOWNLOAD_GENERATION_COMPLETED = 'DOWNLOAD_GENERATION_COMPLETED';
export const DOWNLOAD_GENERATION_STARTED = 'DOWNLOAD_GENERATION_STARTED';
export const DOWNLOAD_RESUMED = 'DOWNLOAD_RESUMED';
export const START_DOWNLOADING = 'START_DOWNLOADING';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const BROWSER_DOWNLOAD_STARTED = 'BROWSER_DOWNLOAD_STARTED';
export const DOWNLOAD_ERROR = 'DOWNLOAD_ERROR';
export const CLEAR_STATE = 'CLEAR_STATE';

// Download states
export const STATE_NEW = 'NEW';
export const STATE_PENDING = 'PENDING';
export const STATE_COMPLETED = 'COMPLETED';
export const STATE_ERROR = 'ERROR';
export const STATE_RESUMED = 'RESUMED';
export const STATE_EXPIRED = 'EXPIRED';
