export const DATASELECTOR_INITIALIZED_ACTION = 'DATASELECTOR_INITIALIZED_ACTION';
export const SELECT_REPOSITORY_ACTION = 'SELECT_REPOSITORY_ACTION';
export const SELECT_DATASET_ACTION = 'SELECT_DATASET_ACTION';
export const SELECT_AUDIT_ACTION = 'SELECT_AUDIT_ACTION';
export const TOGGLE_PLOT_ACTION = 'TOGGLE_PLOT_ACTION';
export const TOGGLE_STATS_ACTION = 'TOGGLE_STATS_ACTION';
export const TOGGLE_RESULTS_MATRIX_ACTION = 'TOGGLE_RESULTS_MATRIX_ACTION';
export const DATAGRID_INIT_ACTION = 'DATAGRID_INIT_ACTION';
export const DATAGRID_REFRESH_ACTION = 'DATAGRID_REFRESH_ACTION';
export const TOGGLE_HIDE_NO_ERROR_COLUMNS = 'TOGGLE_HIDE_NO_ERROR_COLUMNS';
export const CLEAR_DATAGRID_FILTERS_ACTION = 'CLEAR_DATAGRID_FILTERS_ACTION';
export const SET_LOADING_SELECTED_RECORDS_ACTION = 'SET_LOADING_SELECTED_RECORDS_ACTION';
export const SET_SELECTED_RECORDS_ACTION = 'SET_SELECTED_RECORDS_ACTION';
