import React, { Component } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

class PlotInput extends Component {
    constructor(props) {
        super(props);

        const PLOT_TYPE_HISTOGRAM = 'histogram';
        const PLOT_TYPE_BOXPLOT = 'boxplot';
        const PLOT_TYPE_SCATTER_2D = 'scatter_2d';

        const plotTypes = [
            {
                type: PLOT_TYPE_HISTOGRAM,
                description: 'Histogram (1D)',
                dimensions: 1
            },
            {
                type: PLOT_TYPE_BOXPLOT,
                description: 'Box plot (1D)',
                dimensions: 1
            },
            {
                type: PLOT_TYPE_SCATTER_2D,
                description: 'Scatter plot (2D)',
                dimensions: 2
            }
        ];

        this.state = {
            disabled: props.disabled,
            plotTypes,
            plotType: null,
            selectedXField: [],
            selectedYField: [],
            fields: props.fields || []
        };
    }

    onPlotTypeChanged = (event) => {
        const { plotTypes, selectedXField, selectedYField } = this.state;
        const plotTypeIndex = event.target.value;
        let xFieldEnabled = false;
        let yFieldEnabled = false;
        let plotType = null;
        if (!isNaN(parseInt(plotTypeIndex))) {
            plotType = plotTypes[plotTypeIndex];
            xFieldEnabled = plotType.dimensions >= 1;
            yFieldEnabled = plotType.dimensions >= 2;
        }
        this.setState({
            plotType, xFieldEnabled, yFieldEnabled
        });

        this.callOnChangeHandler(plotType, this.getSelectedFieldFromSelection(selectedXField), this.getSelectedFieldFromSelection(selectedYField));
    }

    callOnChangeHandler = (plotType, xField, yField) => {
        if (this.props.onChange) {
            this.props.onChange(plotType, xField, yField);
        }
    }

    getSelectedFieldFromSelection = (selection) => {
        if (selection.length > 0) {
            return selection[0].id;
        }
        return null;
    }

    onXFieldChanged = (selection) => {
        const { plotType, selectedYField } = this.state;
        const selectedXField = selection;
        const xField = this.getSelectedFieldFromSelection(selectedXField);
        const yField = this.getSelectedFieldFromSelection(selectedYField);
        
        this.setState({ selectedXField });
        this.callOnChangeHandler(plotType, xField, yField);
    }

    onYFieldChanged = (selection) => {
        const { plotType, selectedXField } = this.state;
        const selectedYField = selection;
        const xField = this.getSelectedFieldFromSelection(selectedXField);
        const yField = this.getSelectedFieldFromSelection(selectedYField);
        
        this.setState({ selectedYField });

        this.callOnChangeHandler(plotType, xField, yField);
    }

    render() {
        const { disabled, fields, plotTypes, plotTypeIndex, xFieldEnabled, yFieldEnabled, selectedXField, selectedYField } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Plot Type</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    as="select"
                                    selected={plotTypeIndex}
                                    onChange={this.onPlotTypeChanged}
                                >
                                    <option>Please Choose Type...</option>
                                    {plotTypes.map((type, index) => (
                                        <option key={index} value={index}>{type.description}</option> // eslint-disable-line
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridXField">
                                {xFieldEnabled && (
                                    <>
                                        <Form.Label>X Field</Form.Label>
                                        <Typeahead
                                            id="xfield"
                                            selected={selectedXField}
                                            clearButton
                                            onChange={this.onXFieldChanged}
                                            highlightOnlyResult
                                            selectHintOnEnter
                                            labelKey="name"
                                            multiple={false}
                                            options={fields}
                                            placeholder="Choose X field..."
                                        />
                                    </>
                                )}
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridYField">
                                {yFieldEnabled && (
                                    <>
                                        <Form.Label>Y Field</Form.Label>
                                        <Typeahead
                                            id="yfield"
                                            selected={selectedYField}
                                            clearButton
                                            onChange={this.onYFieldChanged}
                                            highlightOnlyResult
                                            selectHintOnEnter
                                            labelKey="name"
                                            multiple={false}
                                            options={fields}
                                            placeholder="Choose Y field..."
                                        />
                                    </>
                                )}
                            </Form.Group>
                        </Form.Row>

                    </Col>

                </Row></>
        )

    }

}

export default PlotInput;