import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const PasswordValidationMessage = ({
  validations: {
    minLength, lowercaseLetter, uppercaseLetter, number, specialCharacter
  }
}) => (
  <div style={{ fontSize: '0.9rem' }} className="mt-2">
    <ul className="list-unstyled">
      <li className="mb-1 text-danger font-weight-bold">Your password needs to:</li>
      <li className={minLength ? 'text-success' : 'text-danger'}>
        <FontAwesomeIcon icon={minLength ? faCheck : faTimes} />&nbsp;&nbsp;Be at least 8 characters long
      </li>
      <li className={`mb-1 ${lowercaseLetter ? 'text-success' : 'text-danger'}`}>
        <FontAwesomeIcon icon={lowercaseLetter ? faCheck : faTimes} />&nbsp;&nbsp;Contain one or more lowercase letters
      </li>
      <li className={`mb-1 ${uppercaseLetter ? 'text-success' : 'text-danger'}`}>
        <FontAwesomeIcon icon={uppercaseLetter ? faCheck : faTimes} />&nbsp;&nbsp;Contain one or more uppercase letters
      </li>
      <li className={`mb-1 ${number ? 'text-success' : 'text-danger'}`}>
        <FontAwesomeIcon icon={number ? faCheck : faTimes} />&nbsp;&nbsp;Contain one or more numbers
      </li>
      <li className={`mb-1 ${specialCharacter ? 'text-success' : 'text-danger'}`}>
        <FontAwesomeIcon icon={specialCharacter ? faCheck : faTimes} />
        &nbsp;&nbsp;Contain one or more special characters from the following selection:&nbsp;
        {"^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"}
      </li>
    </ul>
  </div>
);

PasswordValidationMessage.propTypes = {
  validations: PropTypes.shape({
    minLength: PropTypes.bool.isRequired,
    lowercaseLetter: PropTypes.bool.isRequired,
    uppercaseLetter: PropTypes.bool.isRequired,
    number: PropTypes.bool.isRequired,
    specialCharacter: PropTypes.bool.isRequired
  }).isRequired
};

export default PasswordValidationMessage;
