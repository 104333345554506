import PropTypes from 'prop-types';

export const apiKeyPropType = PropTypes.shape({
  Attributes: PropTypes.arrayOf(PropTypes.object),
  Enabled: PropTypes.bool,
  UserCreateDate: PropTypes.string,
  UserLastModifiedDate: PropTypes.string,
  UserStatus: PropTypes.string,
  Username: PropTypes.string
});

export const textFiltersPropType = PropTypes.objectOf(PropTypes.string);

export const datetimeFilterPropType = PropTypes.arrayOf(
  PropTypes.shape({
    condition: PropTypes.string,
    datetime: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  })
);

export const userSettingsPropType = PropTypes.shape({ // TODO: add defaults
  clusterEndpoint: PropTypes.string,
  env: PropTypes.string,
  modifiedOn: PropTypes.number,
  tenant: PropTypes.string
});

export const componentChildrenPropType = PropTypes.oneOfType([PropTypes.node, PropTypes.array]);

export const childPropsPropType = PropTypes.shape({
  isAuthenticated: PropTypes.bool,
  userSettings: userSettingsPropType,
  userHasAuthenticated: PropTypes.func.isRequired,
  settingsWereUpdated: PropTypes.func.isRequired
});

export const faIconPropType = PropTypes.shape({
  icon: PropTypes.array,
  iconName: PropTypes.string,
  prefix: PropTypes.string
});

export const auditHeaderRecordPropType = PropTypes.shape({
  created_on: PropTypes.string,
  dataset_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ended_at: PropTypes.string,
  id: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  started_at: PropTypes.string,
  state: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tenant: PropTypes.number,
  total_audited: PropTypes.number,
  total_errors: PropTypes.number,
  total_records: PropTypes.number,
  updated_on: PropTypes.string
});

export const gridCoordinatePropType = PropTypes.shape({
  row: PropTypes.number,
  column: PropTypes.number
});

export const cellStatePropType = PropTypes.objectOf(PropTypes.shape({
  selected: PropTypes.bool
}));

export const rectangularSelectionsPropType = PropTypes.arrayOf(PropTypes.shape({
  from: PropTypes.shape({
    column: PropTypes.number,
    row: PropTypes.number
  }),
  to: PropTypes.shape({
    column: PropTypes.number,
    row: PropTypes.number
  })
}));

export const refPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any })
]);

export const pagePropType = PropTypes.arrayOf(PropTypes.shape({
  page: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string
  ]),
  active: PropTypes.bool,
  disable: PropTypes.bool,
  title: PropTypes.string
}));

export const columnTogglePropsPropType = PropTypes.shape({
  columns: PropTypes.array.isRequired,
  onColumnToggle: PropTypes.func.isRequired,
  toggles: PropTypes.objectOf(PropTypes.bool)
});

export const recordPropType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  errorsFields: PropTypes.array.isRequired,
  hasAnomalies: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.number.isRequired,
  processedModels: PropTypes.number,
  qualityFeedback: PropTypes.object.isRequired,
  qualityLabel: PropTypes.string.isRequired,
  resultLabel: PropTypes.string.isRequired,
  screenshotsCount: PropTypes.string,
  tag: PropTypes.string,
  totalModels: PropTypes.number,
  userAction: PropTypes.string,
  userFingerprint: PropTypes.string,
  userFingerprintDisplay: PropTypes.string
});
