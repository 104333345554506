import React, {
  Fragment, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { Badge, ProgressBar } from 'react-bootstrap';
import EllipsisTooltipContainer from '../EllipsisTooltipContainer';
import QualityLabelBadge from './QualityLabelBadge';
import formatDate from './formatDate';
import ResultLabel from './ResultLabel';
import { LABEL_GOOD, LABEL_NOTSET } from '../../constants';
import { fetchDatasetResultRecordDetails, fetchAuditResultRecordDetails } from './services';

import './PlotPointHover.scss';

const PlotPointHover = ({
  point, repository, dataset, auditId, onPointDetailsHover, onPointDetailsUnhover, onScreenshotButtonClick,
  showViewScreenshots, xColumnName, yColumnName
}) => {
  const unmounted = useRef(false);
  const innerContainerRef = useRef(null);
  const plotContainerRef = useRef(null);
  const [pointDataDetailsState, setPointDataDetailsState] = useState({ isLoading: false, pointDataDetails: null });

  const { pointDataDetails, isLoading } = pointDataDetailsState;

  // Cleanup
  useEffect(() => () => {
    unmounted.current = true;
  }, []);

  const fetchPointDetailsAndUpdateState = async (recordId) => {
    setPointDataDetailsState({
      ...pointDataDetailsState,
      isLoading: true
    });

    let result;
    if (auditId) {
      result = await fetchAuditResultRecordDetails(repository, dataset, auditId, recordId);
    } else {
      result = await fetchDatasetResultRecordDetails(repository, dataset, recordId);
    }

    if (unmounted.current) return;

    const newPointDataDetails = result.data.record;

    setPointDataDetailsState({
      pointDataDetails: newPointDataDetails, isLoading: false
    });
  };

  useEffect(() => {
    setPointDataDetailsState({
      isLoading: false,
      pointDataDetails: null
    });
    if (point && innerContainerRef.current) {
      innerContainerRef.current.scrollTop = 0;
      const recordId = point.customdata['__typo_id'];
      fetchPointDetailsAndUpdateState(recordId);
    }
  }, [point]); // eslint-disable-line react-hooks/exhaustive-deps

  if (point === null) {
    return null;
  }

  const position = {
    x: point.xaxis.d2p(point.x) + point.xaxis._offset + 20, // eslint-disable-line no-underscore-dangle
    y: point.yaxis.d2p(point.y) + point.yaxis._offset // eslint-disable-line no-underscore-dangle
  };

  const renderColumnData = () => {
    if (!pointDataDetails) {
      return null;
    }

    const errorFields = pointDataDetails['errors_fields'] || [];
    const tag = pointDataDetails['tag']; // eslint-disable-line prefer-destructuring
    const qualityFeedback = pointDataDetails['quality_feedback'] || {};
    const qualityLabel = pointDataDetails['quality_label'];
    const createdAt = pointDataDetails['created_at'];

    const getTdClasses = (columnName) => {
      let tdClassName = errorFields.includes(columnName) ? 'has-errors' : '';

      if (qualityFeedback[columnName] && qualityFeedback[columnName] !== LABEL_NOTSET) {
        if (tdClassName) tdClassName += ' ';

        tdClassName += qualityFeedback[columnName] === LABEL_GOOD ? 'bg-good' : 'bg-bad';
      }

      return tdClassName;
    };

    const table = [];

    table.push(
      <Fragment key="global">
        <tr className="odd">
          <td className="field-name main-field">
            <EllipsisTooltipContainer>{xColumnName}</EllipsisTooltipContainer>
          </td>
          <td className={`field-value main-field ${getTdClasses(xColumnName)}`}>
            <EllipsisTooltipContainer>{pointDataDetails.record[xColumnName]}</EllipsisTooltipContainer>
          </td>
          <td />
          <td className="no-bg" />
          <td className="no-bg" />
        </tr>
        <tr className="even">
          <td className="field-name main-field">
            <EllipsisTooltipContainer>{yColumnName}</EllipsisTooltipContainer>
          </td>
          <td className={`field-value main-field ${getTdClasses(yColumnName)}`}>
            <EllipsisTooltipContainer>{pointDataDetails.record[yColumnName]}</EllipsisTooltipContainer>
          </td>
          <td />
          <td />
          <td />
        </tr>

        <tr className="odd">
          <td className="field-name main-field">
            <EllipsisTooltipContainer>Quality Label</EllipsisTooltipContainer>
          </td>
          <td className="field-value main-field">
            <QualityLabelBadge qualityLabel={qualityLabel} />
          </td>
          <td />
          <td className="field-name main-field">
            <div className="cell-inner">Tag</div>
          </td>
          <td className="field-value main-field">
            <EllipsisTooltipContainer>
              {tag ? (
                <Badge variant="primary">{tag}</Badge>
              ) : '-'}
            </EllipsisTooltipContainer>
          </td>
        </tr>
        <tr className="even">
          <td className="field-name main-field">
            <div className="nowrap cell-inner">Typo Timestamp</div>
          </td>
          <td className="field-value main-field">
            <EllipsisTooltipContainer>
              {formatDate(new Date(createdAt))}
            </EllipsisTooltipContainer>
          </td>
          <td />
          <td className="field-name main-field">
            <div className="cell-inner">Result</div>
          </td>
          <td className="field-value main-field">
            <div className="cell-inner">
              <ResultLabel
                onScreenshotButtonClick={onScreenshotButtonClick}
                record={point.customdata}
                showViewScreenshots={showViewScreenshots}
              />
            </div>
          </td>
        </tr>
      </Fragment>
    );

    const columnNames = Object.keys(pointDataDetails.record).filter((col) => !col.includes('__typo_'));

    const remainingColumnNames = [...columnNames];
    remainingColumnNames.splice(remainingColumnNames.indexOf(xColumnName), 1);
    remainingColumnNames.splice(remainingColumnNames.indexOf(yColumnName), 1);

    const totalRows = Math.ceil(remainingColumnNames.length / 2);

    let currentColumnIndex = 0;

    for (let i = 0; i < totalRows; i += 1) {
      const children = [];

      for (let j = 0; j < 2; j += 1) {
        if (currentColumnIndex === remainingColumnNames.length && j === 1) {
          children.push(
            <td key={`${i}-${j}-1`} />,
            <td key={`${i}-${j}-2`} />,
            <td key={`${i}-${j}-3`} />
          );
          break;
        }

        const currentColumnName = remainingColumnNames[currentColumnIndex];

        if (j === 1) {
          children.push(
            <td key={`${i}-${j}-spacer`} />
          );
        }

        children.push(
          <td className="field-name" key={`${i}-${j}-1`}>
            <EllipsisTooltipContainer>{currentColumnName}</EllipsisTooltipContainer>
          </td>
        );
        children.push(
          <td className={`field-value ${getTdClasses(currentColumnName)}`} key={`${i}-${j}-2`}>
            <EllipsisTooltipContainer>{pointDataDetails.record[currentColumnName]}</EllipsisTooltipContainer>
          </td>
        );

        currentColumnIndex += 1;
      }

      table.push(<tr className={i % 2 === 0 ? 'odd' : 'even'} key={`${i}`}>{children}</tr>);
    }

    return table;
  };

  const openDirection = position.x < window.innerWidth / 2 ? 'right' : 'left';

  return (
    <div
      className={`plot-point-hover open-${openDirection}`}
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      onMouseEnter={onPointDetailsHover}
      onMouseLeave={onPointDetailsUnhover}
      ref={plotContainerRef}
    >
      <div className="plot-point-hover-inner" ref={innerContainerRef}>
        <table>
          <tbody>
            {renderColumnData()}
          </tbody>
        </table>
      </div>
      <svg height="14" width="10" className="right-hover-arrow">
        <polygon points="0,7 10,0 10,14" style={{ fill: '#f4f4f4' }} />
      </svg>
      <svg height="14" width="10" className="left-hover-arrow">
        <polygon points="0,0 10,7 0,14" style={{ fill: '#f4f4f4' }} />
      </svg>
      <div className="plot-point-hover-inner">
        {isLoading && (
          <div className="progress-bar-container">
            <ProgressBar animated now={100} />
          </div>
        )}
      </div>
    </div>
  );
};

PlotPointHover.propTypes = {
  auditId: PropTypes.number,
  dataset: PropTypes.string.isRequired,
  point: PropTypes.shape({
    customdata: PropTypes.object,
    xaxis: PropTypes.object,
    yaxis: PropTypes.object,
    x: PropTypes.any,
    y: PropTypes.any
  }),
  repository: PropTypes.string.isRequired,
  onPointDetailsHover: PropTypes.func.isRequired,
  onPointDetailsUnhover: PropTypes.func.isRequired,
  onScreenshotButtonClick: PropTypes.func.isRequired,
  showViewScreenshots: PropTypes.bool,
  xColumnName: PropTypes.string.isRequired,
  yColumnName: PropTypes.string.isRequired
};

PlotPointHover.defaultProps = {
  auditId: null,
  point: null,
  showViewScreenshots: false
};

export default PlotPointHover;
