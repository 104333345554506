import React, { Component } from "react";
import "./Home.css";
import { Container, Row, Col, ListGroup, ListGroupItem, Table, Button } from "react-bootstrap";
import { API } from "aws-amplify";
import { LinkContainer } from 'react-router-bootstrap';
import Welcome from '../components/Welcome';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            configurations: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const configurations = await this.configurations();
            this.setState({ configurations });
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    configurations() {
        return API.get("typo-dashboard", "/configurations");
    }

    renderConfigurationsList(configurations) {
        return [].concat(configurations).map(
            (config, i) =>
                i !== 0
                    ? <ListGroupItem
                        key={config.configurationId}
                        href={`/configurations/${config.configurationId}`}
                        onClick={this.handleConfigurationClick}
                        header={config.configurationId}
                    >
                        {"Created: " + new Date(config.createdAt).toLocaleString()}
                    </ListGroupItem>
                    : <ListGroupItem
                        key="new"
                        href="/configurations/new"
                        onClick={this.handleConfigurationClick}
                    >
                        <h4>
                            <b>{"\uFF0B"}</b> Create a configuration
                </h4>
                    </ListGroupItem>
        );
    }

    renderConfigurationsRows(configurations) {
        return [].concat(configurations).map(
            (config, i) =>
                <tr>
                    <td>{config.configurationId}</td>
                    <td>{config.name}</td>
                    <td>{"Created: " + new Date(config.createdAt).toLocaleString()}</td>
                    <td><Button bsStyle="primary" bsSize="small">View</Button> | <Button bsStyle="warning" bsSize="small">Delete</Button></td>
                </tr>
        );
    }

    handleConfigurationClick = event => {
        event.preventDefault();
        this.props.history.push(event.currentTarget.getAttribute("href"));
    }

    renderLander() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Welcome></Welcome>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderConfigurationsInTable() {
        return (
            <div className="configurations">
                Configurations
                <LinkContainer to="/configurations/new"><Button bsSize="small" bsStyle="primary">Add Configuration</Button></LinkContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                        {!this.state.isLoading && this.renderConfigurationsRows(this.state.configurations)}
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
            </div>
        );
    }

    renderConfigurations() {
        return (
            <div className="notes">
                Configurations
                <ListGroup>
                    {!this.state.isLoading && this.renderConfigurationsList(this.state.configurations)}
                </ListGroup>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Id</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.renderLander()}
            </div>
        );
    }
}
