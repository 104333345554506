import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ResultsMatrix from '../ResultsMatrix';
import { useStateValue } from './state';

const ResultsMatrixContainer = () => {
  const [{ showResultsMatrix, dataSelector }] = useStateValue();

  if (showResultsMatrix) {
    return (
      <>
        <Row><Col><h5>Results Matrix</h5></Col></Row>
        <Row>
          <Col><ResultsMatrix dataSelector={dataSelector} /></Col>
        </Row>
      </>
    );
  }
  return null;
};

export default ResultsMatrixContainer;
