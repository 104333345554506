import { initialSizePerPage } from '../../components/analytics/datagridOptions';

export const removeAttributeFromCellState = (attributeName, cellState, cellKeys) => {
  // Returns new cellState object removing the provided attribute name
  // Optional parameter cellKeys can be a sub-set of cell keys, otherwise the
  // attribute is removed for all cells.
  const newCellState = { ...cellState };

  const removeAttributeFromCellStateCell = (cellKey) => {
    if (cellState[cellKey] !== undefined && cellState[cellKey][attributeName] !== undefined) {
      if (Object.keys(cellState[cellKey]).length === 1) {
        delete newCellState[cellKey];
      } else {
        delete newCellState[cellKey][attributeName];
      }
    }
  };

  if (cellKeys) {
    cellKeys.forEach((cellKey) => removeAttributeFromCellStateCell(cellKey));
  } else {
    Object.keys(newCellState).forEach((cellKey) => removeAttributeFromCellStateCell(cellKey));
  }

  return newCellState;
};

// Array/object key for row-column coordinates keys
export const getCellKey = (rowIndex, columnIndex) => `r-${rowIndex}-c-${columnIndex}`;

// Turn row indexes relative to their page into absolute
// numbers regardless of pagination
export const getAbsoluteRowIndex = (relativeRowIndex, tableRef) => {
  const [currentPage, currentSizePerPage] = tableRef
    ? [tableRef.paginationContext.currPage, tableRef.paginationContext.currSizePerPage] : [1, initialSizePerPage];

  return relativeRowIndex + (currentPage - 1) * currentSizePerPage;
};

// Check if coordinate is in a specified rectangular selection
export const inRectangularSelection = (coordinates, rectangularSelection) => (
  rectangularSelection.from.row <= coordinates.row && rectangularSelection.from.column <= coordinates.column
    && rectangularSelection.to.row >= coordinates.row && rectangularSelection.to.column >= coordinates.column);

// Check if coordinate is inside at least one rectangular selection
export const inRectangularSelections = (coordinates, rectangularSelections) => (
  rectangularSelections.find((selection) => inRectangularSelection(coordinates, selection)) !== undefined);

// Get the row & column number from a cell array/object key
export const parseCellKey = (cellKey) => {
  const [, row,, column] = cellKey.split('-');

  return {
    row: parseInt(row, 10),
    column: parseInt(column, 10)
  };
};
