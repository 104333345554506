import React from 'react';
import {
  Item, Separator, Submenu
} from 'react-contexify';
import PropTypes from 'prop-types';
import { refPropType } from '../../prop-types';
import { LABEL_GOOD, LABEL_BAD, LABEL_NOTSET } from '../../constants';

const MenuItemContainer = (props) => {
  const {
    cellSelectionActive, selectedCells, propsFromTrigger: eventProps, onMenuItemClick, nativeEvent: event
  } = props;

  const isDisabled = eventProps.hasPendingBulkLabels;

  let titles;
  let submenuTitles;
  let { mode } = eventProps;
  const { column } = eventProps;
  const columnName = column.dataField.replace('data.', '');

  if (!mode) {
    mode = cellSelectionActive ? 'selection' : 'cell';
  }

  const columnMenuTitles = {
    [LABEL_GOOD]: (
      <>Label All Cell Values in Column&nbsp;<span>{columnName}</span>&nbsp;as&nbsp;<strong>Good</strong></>),
    [LABEL_NOTSET]: (
      <>Label All Cell Values Column&nbsp;<strong>{columnName}</strong>&nbsp;as&nbsp;<strong>Not Set</strong></>),
    [LABEL_BAD]: <>Label All Cell Values Column&nbsp;<span>{columnName}</span>&nbsp;as&nbsp;<strong>Bad</strong></>
  };

  if (mode === 'column') {
    return (
      <>
        <Item
          disabled={isDisabled}
          className="datagrid-menu-item-good"
          onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_GOOD, selectedCells)}
        >
          {columnMenuTitles[LABEL_GOOD]}
        </Item>
        <Separator />
        <Item
          disabled={isDisabled}
          onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_NOTSET, selectedCells)}
        >
          {columnMenuTitles[LABEL_NOTSET]}
        </Item>
        <Separator />
        <Item
          disabled={isDisabled}
          className="datagrid-menu-item-bad"
          onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_BAD, selectedCells)}
        >
          {columnMenuTitles[LABEL_BAD]}
        </Item>
      </>
    );
  }

  if (mode === 'selection') {
    titles = {
      [LABEL_GOOD]: <>Label Selected Cells as&nbsp;<strong>Good</strong></>,
      [LABEL_NOTSET]: <>Label Selected Cells as&nbsp;<strong>Not Set</strong></>,
      [LABEL_BAD]: <>Label Selected Cells as&nbsp;<strong>Bad</strong></>
    };
  } else if (mode === 'cell') {
    titles = {
      [LABEL_GOOD]: <>Label Cell Value as&nbsp;<strong>Good</strong></>,
      [LABEL_NOTSET]: <>Label Cell Value as&nbsp;<strong>Not Set</strong></>,
      [LABEL_BAD]: <>Label Cell Value as&nbsp;<strong>Bad</strong></>
    };
    submenuTitles = {
      [LABEL_GOOD]: <>Label All Cells in Row as&nbsp;<strong>Good</strong></>,
      [LABEL_NOTSET]: <>Label All Cells in Row as&nbsp;<strong>Not Set</strong></>,
      [LABEL_BAD]: <>Label All Cells in Row as&nbsp;<strong>Bad</strong></>
    };
  }

  return (
    <>
      <Item
        disabled={isDisabled}
        className="datagrid-menu-item-good"
        onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_GOOD, selectedCells)}
      >{titles[LABEL_GOOD]}
      </Item>
      <Separator />
      <Item
        disabled={isDisabled}
        onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_NOTSET, selectedCells)}
      >
        {titles[LABEL_NOTSET]}
      </Item>
      <Separator />
      <Item
        disabled={isDisabled}
        className="datagrid-menu-item-bad"
        onClick={() => onMenuItemClick(event, eventProps, mode, LABEL_BAD, selectedCells)}
      >
        {titles[LABEL_BAD]}
      </Item>
      {!cellSelectionActive && (
        <Separator />
      )}
      {!cellSelectionActive && (
        <Submenu disabled={isDisabled} label="Row">
          <Item
            className="datagrid-menu-item-good"
            onClick={() => onMenuItemClick(event, eventProps, 'record', LABEL_GOOD)}
          >
            {submenuTitles[LABEL_GOOD]}
          </Item>
          <Separator />
          <Item
            onClick={() => onMenuItemClick(event, eventProps, 'record', LABEL_NOTSET)}
          >
            {submenuTitles[LABEL_NOTSET]}
          </Item>
          <Separator />
          <Item
            className="datagrid-menu-item-bad"
            onClick={() => onMenuItemClick(event, eventProps, 'record', LABEL_BAD)}
          >
            {submenuTitles[LABEL_BAD]}
          </Item>
        </Submenu>
      )}
      {!cellSelectionActive && (
        <Submenu disabled={isDisabled} label="Column">
          <Item
            className="datagrid-menu-item-good"
            onClick={() => onMenuItemClick(event, eventProps, 'column', LABEL_GOOD)}
          >
            {columnMenuTitles[LABEL_GOOD]}
          </Item>
          <Separator />
          <Item
            onClick={() => onMenuItemClick(event, eventProps, 'column', LABEL_NOTSET)}
          >
            {columnMenuTitles[LABEL_NOTSET]}
          </Item>
          <Separator />
          <Item
            className="datagrid-menu-item-bad"
            onClick={() => onMenuItemClick(event, eventProps, 'column', LABEL_BAD)}
          >
            {columnMenuTitles[LABEL_BAD]}
          </Item>
        </Submenu>
      )}
    </>
  );
};

MenuItemContainer.defaultProps = {
  propsFromTrigger: {},
  nativeEvent: null
};

MenuItemContainer.propTypes = {
  cellSelectionActive: PropTypes.bool.isRequired,
  selectedCells: PropTypes.object.isRequired,
  propsFromTrigger: PropTypes.object,
  onMenuItemClick: PropTypes.func.isRequired,
  nativeEvent: refPropType
};

export default MenuItemContainer;
