import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';

class LabelEditor extends React.Component {
    componentDidMount = () => {
      const { onBlur } = this.props;

      document.addEventListener('keydown', (e) => {
        if (e.keyCode === 27) {
          onBlur();
        }
      }, false);
    }

    render() {
      const {
        value, onBlur, onUpdate, options
      } = this.props;

      return (
        <Typeahead
          id="tag-editor"
          allowNew
          newSelectionPrefix="Add a new tag: "
          options={options}
          clearButton
          selected={value ? [value] : []}
          selectHintOnEnter
          positionFixed
          placeholder="Set tag"
          autoFocus
          bsSize="sm"
          flip
          emptyLabel="No tags found"
          onBlur={onBlur}
          onChange={(selection) => {
            let selected = selection[0];
            if (!selected) selected = '';
            // New items will be of type object.
            if (typeof (selected) === 'object') {
              selected = selected.label;
            }
            onUpdate(selected);
          }}
        />
      );
    }
}

LabelEditor.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

LabelEditor.defaultProps = {
  value: ''
};

export default LabelEditor;
