import React, { Component } from 'react';
import {
  Alert, Button,
  Form, Col, Row, Container
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import ReactRouterPropTypes from 'react-router-prop-types';

import AuthButton from '../components/AuthButton';
import { emailValidationError, requiredFieldError } from '../validationErrors';
import { passwordValidator, validateEmailAddress } from '../validators';
import LoaderButton from '../components/LoaderButton';
import { handleFieldBlur, handleFieldChange, validateForm } from '../formMethods';
import './Signup.css';

class Signup extends Component {
  validators = {
    email: (email) => {
      if (!validateEmailAddress(email)) {
        return emailValidationError;
      }

      return null;
    },
    password: passwordValidator,
    confirmPassword: (confirmPassword) => {
      const { fieldValues: { password } } = this.state;

      if (!confirmPassword) {
        return requiredFieldError;
      }

      if (confirmPassword !== password) {
        return 'The passwords don\'t match. Please try again.';
      }

      return null;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      changeEmail: false,
      step: 1,
      isLoading: false,
      errorMessage: undefined,
      fieldValues: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      fieldErrors: {
        email: null,
        password: null,
        confirmPassword: null
      },
      fieldsValidated: {
        email: false,
        password: false,
        confirmPassword: false
      }
    };

    this.handleFieldBlur = handleFieldBlur.bind(this);
    this.handleFieldChange = handleFieldChange.bind(this);
    this.validateForm = validateForm.bind(this);

    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { fieldValues: { email, password }, fieldErrors, step } = this.state;

    if (step === 1) {
      if (this.validators.email(email) === null) {
        this.setState({
          step: 2
        });
        return;
      }
    }

    if (!this.validateForm()) {
      return;
    }

    this.setState({ isLoading: true, errorMessage: undefined });

    try {
      await Auth.signUp({
        username: email,
        password
      });

      if (this.unmounted) {
        return;
      }

      this.setState({
        isLoading: false
      });
      history.push('/login', {
        newUser: true
      });
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        this.setState({
          isLoading: false,
          fieldErrors: {
            ...fieldErrors,
            email: e.message
          }
        });
      } else {
        this.setState({
          errorMessage: e.message,
          isLoading: false
        });
      }
    }
  }

  goToStep2 = () => {
    const { fieldValues: { email } } = this.state;

    if (this.validators.email(email) === null) {
      this.setState({
        step: 2
      });
    }
  }

  changeEmail = () => {
    this.setState({
      changeEmail: true
    });
  }

  signUpWithGoogle = () => {
    Auth.federatedSignIn({ provider: 'accounts.google.com', customState: 'origin-google' });
  }

  signUpWithMicrosoft = () => {
    Auth.federatedSignIn({ provider: 'Microsoft', customState: 'origin-microsoft' });
  }

  renderForm() {
    const {
      changeEmail, fieldErrors, fieldValues, fieldsValidated, errorMessage, isLoading, step
    } = this.state;

    return (
      <>
        <Form onSubmit={this.handleSubmit} noValidate className="signup-form" autoComplete="off">
          <Form.Group controlId="email" variant="large">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={fieldValues.email}
              onChange={this.handleFieldChange}
              onBlur={this.handleFieldBlur}
              isInvalid={fieldErrors.email !== null}
              isValid={fieldsValidated.email && fieldErrors.email === null}
              disabled={step === 2 && changeEmail === false}
            />
            {step === 2 && !changeEmail && (
              <p className="text-right">
                <span
                  className="text-primary"
                  style={{ fontSize: '0.8em', cursor: 'pointer' }}
                  onClick={this.changeEmail}
                  role="button"
                  tabIndex="0"
                  onKeyDown={(e) => { if (e.keyCode === 13) this.changeEmail(); }}
                >
                  Change
                </span>
              </p>
            )}
            {fieldErrors.email && (
              <Form.Control.Feedback type="invalid">
                {fieldErrors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {step === 1 && (
            <Button block type="button" text="Continue" onClick={this.goToStep2}>
              Continue
            </Button>
          )}
          {step === 2 && (
            <>
              <Form.Group controlId="password" variant="large">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  value={fieldValues.password}
                  onChange={this.handleFieldChange}
                  type="password"
                  autoComplete="new-password"
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.password !== null}
                  isValid={fieldsValidated.password && fieldErrors.password === null}
                />
                {fieldErrors.password ? fieldErrors.password : (
                  <small>
                    Must be at least 8 characters long & include lowercase & uppercase characters,
                    numbers and a special character.
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="confirmPassword" variant="large">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  value={fieldValues.confirmPassword}
                  onChange={this.handleFieldChange}
                  type="password"
                  autoComplete="new-password"
                  onBlur={this.handleFieldBlur}
                  isInvalid={fieldErrors.confirmPassword !== null}
                  isValid={fieldsValidated.confirmPassword && fieldErrors.confirmPassword === null}
                />
                {fieldErrors.confirmPassword && (
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors.confirmPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <p className="text-secondary" style={{ fontSize: '0.9em' }}>
                By clicking below, you agree to the Terms and conditions and Privacy policy.
              </p>
              <LoaderButton
                block
                type="submit"
                isLoading={isLoading}
                text="Signup"
                loadingText="Signing up…"
              />
            </>
          )}
          {errorMessage
            && <Alert variant="danger">{errorMessage}</Alert>}
          <p className="text-center text-secondary mt-2 mb-2" style={{ fontSize: '0.8em' }}>OR</p>
          <AuthButton onClick={this.signUpWithGoogle} type="google" text="Sign up with Google" />
          <AuthButton onClick={this.signUpWithMicrosoft} type="microsoft" text="Sign up with Microsoft" />
        </Form>
      </>
    );
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col className="small-centered-container">
            <h2>Create an account</h2>
            {this.renderForm()}
          </Col>
        </Row>
      </Container>


    );
  }
}

Signup.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default Signup;
