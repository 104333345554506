import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Title extends Component {
    render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={true}>
                        <h3>{this.props.text}</h3>
                    </Col>
                    <Col>
                        <div className="float-sm-right">
                            {this.props.toolbar}
                        </div>
                    </Col>
                </Row>
                <hr/>
            </Fragment>
        );
    }
}
