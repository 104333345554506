import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, DropdownButton } from 'react-bootstrap';

const CustomSizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <DropdownButton
    onSelect={(eventKey) => onSizePerPageChange(eventKey)}
    title={currSizePerPage}
    drop="up"
    variant="secondary"
  >
    {options.map((option) => (
      <Dropdown.Item eventKey={option.page} active={currSizePerPage === `${option.page}`} key={option.page}>
        { option.text }
      </Dropdown.Item>
    ))}
  </DropdownButton>
);

CustomSizePerPageRenderer.propTypes = {
  currSizePerPage: PropTypes.number.isRequired,
  onSizePerPageChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    page: PropTypes.number
  })).isRequired
};

export default CustomSizePerPageRenderer;
