import { Auth } from 'aws-amplify';

export const refreshToken = async () => { // eslint-disable-line import/prefer-default-export
  // Gets new token from AWS and updates the local state
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  const currentSession = await Auth.currentSession();

  await user.refreshSession(currentSession.refreshToken, (error) => {
    if (error) {
      throw new Error(error);
    }
    return true;
  });
};
