import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompleteSignup from './containers/CompleteSignup';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import EmailVerification from './containers/EmailVerification';
import ForgotPassword from './containers/ForgotPassword';
import Login from './containers/Login';
import AppliedRoute from './components/AppliedRoute';
import Signup from './containers/Signup';
import NewConfiguration from './containers/NewConfiguration';
import ListConfigurations from './containers/ListConfigurations';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import ListRepositories from './containers/ListRepositories';
import ManageRepository from './containers/ManageRepository';
import PasswordReset from './containers/PasswordReset';
import ApiKeyCreationSucceed from './containers/ApiKeyCreationSucceed';
import Usage from './containers/Usage';
import Account from './containers/Account';
import DuplicateChecks from './containers/DuplicateChecks';
import Datasets from './containers/Datasets';
import ResultsAnalytics from './containers/ResultsAnalytics';
import DatasetsAudits from './containers/DatasetsAudits';
import OAuthAuthentication from './containers/OAuthAuthentication';
import OAuthLogout from './containers/OAuthLogout';
import { childPropsPropType } from './prop-types';

const Routes = ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute
      path="/duplicate-checks/:repository/:dataset"
      exact
      component={DuplicateChecks}
      props={childProps}
    />
    <AuthenticatedRoute path="/datasets/web-app" exact component={Datasets} props={childProps} />
    <AuthenticatedRoute path="/datasets" exact component={Datasets} props={childProps} />
    <AuthenticatedRoute path="/analytics" exact component={ResultsAnalytics} props={childProps} />
    <AuthenticatedRoute path="/analytics/:repository" exact component={ResultsAnalytics} props={childProps} />
    <AuthenticatedRoute path="/analytics/:repository/:dataset" exact component={ResultsAnalytics} props={childProps} />
    <AuthenticatedRoute
      path="/analytics/:repository/:dataset/:audit"
      exact
      component={ResultsAnalytics}
      props={childProps}
    />
    <AuthenticatedRoute path="/audits" exact component={DatasetsAudits} props={childProps} />
    <AuthenticatedRoute path="/audits/:repository/:dataset" exact component={DatasetsAudits} props={childProps} />
    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
    <AuthenticatedRoute path="/repositories/view/:id" exact component={ApiKeyCreationSucceed} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute
      path="/email-verification/:email/:code"
      exact
      component={EmailVerification}
      props={childProps}
    />
    <UnauthenticatedRoute path="/forgot-password" exact component={ForgotPassword} props={childProps} />
    <UnauthenticatedRoute
      path="/password-reset/:email/:code"
      exact
      component={PasswordReset}
      props={childProps}
    />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/oauth-authentication" exact component={OAuthAuthentication} props={childProps} />
    <UnauthenticatedRoute path="/oauth-logout" exact component={OAuthLogout} props={childProps} />
    {!childProps.signupCompleted
      && <AuthenticatedRoute path="/complete-signup" exact component={CompleteSignup} props={childProps} />}
    <AuthenticatedRoute path="/usage" exact component={Usage} props={childProps} />
    <AuthenticatedRoute path="/configurations/new" exact component={NewConfiguration} props={childProps} />
    <AuthenticatedRoute path="/configurations" exact component={ListConfigurations} props={childProps} />
    <AuthenticatedRoute path="/repositories/create" exact component={ManageRepository} props={childProps} />
    <AuthenticatedRoute path="/repositories/:name" exact component={ManageRepository} props={childProps} />
    <AuthenticatedRoute path="/repositories" exact component={ListRepositories} props={childProps} />
    <Route component={NotFound} />
  </Switch>
);

Routes.propTypes = {
  childProps: childPropsPropType.isRequired
};

export default Routes;
