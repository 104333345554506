import React, { useContext, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Col, Row } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { faCheckCircle, faDownload, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import asyncDownloaderContext from '../context/asyncDownloader/asyncDownloaderContext';
import {
  STATE_PENDING, STATE_COMPLETED, STATE_NEW, STATE_ERROR
} from '../context/asyncDownloader/asyncDownloaderTypes';
import { dataExportFormats } from '../constants';
import SpinnerIcon from './SpinnerIcon';
import './AsyncDownloader.scss';

const AsyncDownloader = () => {
  // Generates download status toasts and downloads files via Iframe inserted in the DOM
  const { downloads, closeNotification, startResumedDownload } = useContext(asyncDownloaderContext);

  const completedDownloads = downloads.filter((download) => download.download === true);

  // Create a ref for communication with each iframe
  const iframeRefs = useRef({});

  completedDownloads.filter(
    (download) => iframeRefs.current[download.exportId] === undefined
  ).forEach((download) => {
    iframeRefs.current[download.exportId] = null;
  });

  const handleIframeReady = async (event) => {
    if (iframeRefs.current[event.detail.exportId] === undefined) return;

    const session = await Auth.currentSession();
    const { idToken: { jwtToken } } = session;

    const e = new CustomEvent(
      'startDownload',
      { detail: { jwtToken } }
    );

    iframeRefs.current[event.detail.exportId].contentDocument.dispatchEvent(e);
  };

  useEffect(() => {
    document.addEventListener('iframeReady', handleIframeReady, false);
  }, []);

  return (
    <>
      <div className="async-downloader-toast-wrapper toast-wrapper-center-top">
        {downloads.map(
          (download) => {
            const isProcessing = [STATE_NEW, STATE_PENDING].includes(download.state);

            return (
              <Toast
                key={download.downloadId}
                className={`async-downloader-toast state-${download.state.toLowerCase().replace('_', '-')}`
                    + `${isProcessing ? ' border-warning' : ''}`
                    + `${download.state === STATE_COMPLETED ? ' border-success' : ''}`
                    + `${download.state === STATE_ERROR ? ' border-danger' : ''}`}
                onClose={() => closeNotification(download.downloadId)}
                style={{ display: download.notificationVisible ? 'block' : 'none' }}
              >
                <Toast.Header>
                  <strong className="mr-auto">{dataExportFormats[download.data.fileType].displayName} export</strong>
                </Toast.Header>
                <Toast.Body>
                  {isProcessing && !download.resumed && (
                    <Row>
                      <Col className="flex-grow-0">
                        <SpinnerIcon className="text-warning" spinDuration="1.1s" />
                      </Col>
                      <Col className="d-flex flex-column justify-content-center">
                        <span className="h5 mb-1">Your export is being prepared.</span>
                        <span>Please wait while we generate your file.</span>
                      </Col>
                    </Row>
                  )}
                  {isProcessing && download.resumed && (
                    <Row>
                      <Col className="flex-grow-0">
                        <SpinnerIcon className="text-warning" spinDuration="1.1s" />
                      </Col>
                      <Col className="d-flex flex-column justify-content-center">
                        <span className="h5 mb-1">
                          Export download resumed
                        </span>
                        <p>
                          {download.data.auditId ? (
                            <>
                              Your export for repository <b>{download.data.repositoryName}</b>,
                              dataset <b>{download.data.datasetName}</b>, audit <b>{download.data.auditName}</b>&nbsp;
                              is being prepared.
                            </>
                          ) : (
                            <>
                              Your export for repository <b>{download.data.repositoryName}</b> &amp;
                              dataset <b>{download.data.datasetName}</b> is being prepared.
                            </>
                          )}
                        </p>
                        <p className="mb-0">
                          Please wait while we generate your file.
                        </p>
                      </Col>
                    </Row>
                  )}
                  {download.state === STATE_COMPLETED && download.resumed && !download.download && (
                    <Row>
                      <Col className="flex-grow-0">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                      </Col>
                      <Col className="d-flex flex-column justify-content-center">
                        <span className="h5 mb-1">Export download resumed</span>
                        <p>
                          {download.data.auditId ? (
                            <>
                              Your export for repository <b>{download.data.repositoryName}</b>,
                              dataset <b>{download.data.datasetName}</b>, audit <b>{download.data.auditName}</b>&nbsp;
                              is ready for download.
                            </>
                          ) : (
                            <>
                              Your export for repository <b>{download.data.repositoryName}</b> &amp;
                              dataset <b>{download.data.datasetName}</b> is ready for download.
                            </>
                          )}
                        </p>
                        <p>
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => { startResumedDownload(download.downloadId); }}
                          >
                            <FontAwesomeIcon icon={faDownload} />&nbsp;
                            Download now
                          </Button>
                        </p>
                      </Col>
                    </Row>
                  )}
                  {download.state === STATE_COMPLETED && download.download && (
                    <Row>
                      <Col className="flex-grow-0">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                      </Col>
                      <Col className="d-flex flex-column justify-content-center">
                        <span className="h5 mb-1">Browser instructed to download file.</span>
                        <span>Please check your downloads folder.</span>
                      </Col>
                    </Row>
                  )}
                  {download.state === STATE_ERROR && (
                    <Row>
                      <Col className="flex-grow-0">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
                      </Col>
                      <Col className="d-flex flex-column justify-content-center">
                        <span className="h5 mb-1">An error occurred while generating your download.</span>
                        <span>Please try again later.</span>
                      </Col>
                    </Row>
                  )}
                </Toast.Body>
              </Toast>
            );
          }
        )}
      </div>
      {completedDownloads.length > 0 && (
        <div className="d-none">
          {completedDownloads.map(
            (download) => (
              <iframe
                key={download.exportId}
                title="Downloading"
                src={
                  `${process.env.PUBLIC_URL}/downloader/downloader.html?`
                + `downloadUrl=${encodeURIComponent(download.downloadUrl)}&`
                + `exportId=${encodeURIComponent(download.exportId)}`
                }
                ref={(el) => { iframeRefs.current[download.exportId] = el; }}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default AsyncDownloader;
