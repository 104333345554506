import padStart from 'lodash/padStart';

export const getTypoLibScriptTag = (apiKey) => '<script type="text/javascript" src="https://cdn.typo.ai/typo-lib.min.js'
  + `?APIKey=${apiKey}"></script>`;

export const timestampToUTC = (timestamp) => {
  const date = new Date(timestamp);

  return `${date.getUTCFullYear()}-${padStart(date.getUTCMonth() + 1, 2, '0')}-`
    + `${padStart(date.getUTCDate(), 2, '0')} ${padStart(date.getUTCHours(), 2, '0')}:`
    + `${padStart(date.getUTCMinutes(), 2, '0')}:${padStart(date.getUTCSeconds(), 2, '0')}`;
};

export const wait = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(ms);
  }, ms);
});

export const getTimestampFromDate = (date) => date.substring(0, 19);
