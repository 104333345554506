import React, { useEffect, useReducer, useRef } from 'react';
import { isEqual } from 'lodash';

import DatagridCellContext from './datagridCellContext';
import datagridCellReducer from './datagridCellReducer';
import {
  CONTEXT_MENU_OPENED, DESELECT_CELL, MOVE_CURSOR,
  RESET_CELL_STATE, SELECT_CELL, SELECT_RECTANGLE
} from './datagridCellTypes';
import { componentChildrenPropType } from '../../prop-types';


const DatagridCellStateProvider = ({
  children, dataSelector, filters, page, sizePerPage, sort
}) => {
  const initialState = {
    // Individually selected cells
    cellState: {},
    cursorPosition: {
      row: null,
      column: null
    },
    selectedCells: new Set(),
    rectangularSelections: []
  };

  const [state, dispatch] = useReducer(datagridCellReducer, initialState);

  // NOTE: Using a ref to avoid re-rendering all the cells.
  const cellsRef = useRef({}); // 2 dimensional array to represent the grid.

  // True only the first time the component is rendered
  const firstExecution = useRef(true);

  const passCellRef = (cellKey, forceUpdate) => {
    const obj = cellsRef.current;

    obj[cellKey] = forceUpdate;
  };

  const moveCursorTo = (row, column) => {
    dispatch({
      type: MOVE_CURSOR,
      payload: {
        row,
        column
      }
    });
  };

  const deselectCell = (row, column) => {
    dispatch({
      type: DESELECT_CELL,
      payload: {
        row,
        column,
        selected: false
      }
    });
  };

  const selectCell = (row, column) => {
    const action = {
      type: SELECT_CELL,
      payload: {
        row,
        column,
        selected: true
      }
    };

    dispatch(action);
  };

  const selectRectangle = (to) => {
    dispatch({
      type: SELECT_RECTANGLE,
      payload: {
        to
      }
    });
  };

  const contextMenuOpened = (row, column) => {
    dispatch({
      type: CONTEXT_MENU_OPENED,
      payload: {
        row,
        column
      }
    });
  };

  const resetCellState = () => {
    dispatch({
      type: RESET_CELL_STATE
    });
  };

  const previousDataSelector = useRef();
  const previousFilters = useRef();
  const previousPage = useRef();
  const previousSizePerPage = useRef();
  const previousSort = useRef();

  useEffect(() => {
    // Only execute when the page number, size per page, filters, sorting or
    // dataset/audit changes.
    if (firstExecution.current) {
      firstExecution.current = false;
    } else if (
      !isEqual(previousDataSelector.current, dataSelector)
      || !isEqual(previousFilters.current, filters)
      || !isEqual(previousPage.current, page)
      || !isEqual(previousSizePerPage.current, sizePerPage)
      || !isEqual(previousSort.current, sort)
    ) {
      resetCellState();
    }

    // Destructure to create a new object instead of copying object reference
    previousDataSelector.current = { ...dataSelector };
    previousFilters.current = [...filters];
    previousPage.current = page;
    previousSizePerPage.current = sizePerPage;
    previousSort.current = { ...sort };
  });

  return (
    <DatagridCellContext.Provider
      value={{
        cursorPosition: state.cursorPosition,
        cellState: state.cellState,
        selectedCells: state.selectedCells,
        rectangularSelections: state.rectangularSelections,
        contextMenuOpened,
        deselectCell,
        moveCursorTo,
        selectCell,
        selectRectangle,
        passCellRef
      }}
    >
      {children}
    </DatagridCellContext.Provider>
  );
};

DatagridCellStateProvider.propTypes = {
  children: componentChildrenPropType.isRequired
};

export default DatagridCellStateProvider;
