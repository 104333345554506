import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Button, Container, Form, Nav, NavDropdown, Navbar
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

import logo from '../typo-logo.png';
import ClusterStatusIcon from './ClusterStatusIcon';
import NotificationIcon from './NotificationIcon';

const Header = ({
  isAuthenticated, clusterEndpoint, email, handleLogout, signupCompleted
}) => {
  const location = useLocation();

  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container fluid>
        <Navbar.Brand>
          <LinkContainer to="/" activeClassName="active" exact>
            <Nav.Link active={false}><img src={logo} width="80" alt="" /></Nav.Link>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {isAuthenticated && signupCompleted && (
              <>
                {/* Setting active={false} because of bug: https://github.com/react-bootstrap/react-router-bootstrap/issues/242 */}
                <LinkContainer to="/repositories"><Nav.Link active={false}>Repositories</Nav.Link></LinkContainer>
                <LinkContainer to="/configurations"><Nav.Link active={false}>Configurations</Nav.Link></LinkContainer>
                <LinkContainer to="/datasets"><Nav.Link active={false}>Datasets</Nav.Link></LinkContainer>
                <LinkContainer to="/analytics"><Nav.Link active={false}>Analytics</Nav.Link></LinkContainer>
                <LinkContainer to="/audits"><Nav.Link active={false}>Audits</Nav.Link></LinkContainer>
                <LinkContainer to="/usage"><Nav.Link active={false}>Usage</Nav.Link></LinkContainer>
              </>
            )}
          </Nav>
          <Nav>
            {isAuthenticated && (
              <>
                {signupCompleted && (
                  <Navbar.Text>
                    <NotificationIcon />
                    &nbsp;|&nbsp;
                    <ClusterStatusIcon clusterEndpoint={clusterEndpoint} />
                    &nbsp;|
                  </Navbar.Text>
                )}
                <NavDropdown
                  title={(
                    <>
                      <FontAwesomeIcon icon={faUser} />
                      &nbsp;&nbsp;
                      {email}
                    </>
                  )}
                  id="basic-nav-dropdown"
                >
                  {signupCompleted && (
                    <>
                      <LinkContainer to="/account" exact>
                        <NavDropdown.Item active={false}>My Account</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                    </>
                  )}
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            {!isAuthenticated && location.pathname !== '/oauth-authentication' && (
              <>
                <Form inline>
                  <LinkContainer to="/signup" exact>
                    <Button size="sm" variant="outline-secondary">Sign Up</Button>
                  </LinkContainer>
                  &nbsp;
                  <LinkContainer to="/login" exact>
                    <Button size="sm" variant="primary">Log In</Button>
                  </LinkContainer>
                </Form>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  clusterEndpoint: PropTypes.string,
  email: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signupCompleted: PropTypes.bool.isRequired
};

Header.defaultProps = {
  clusterEndpoint: '',
  email: ''
};

export default Header;
