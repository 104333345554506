import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { componentChildrenPropType } from '../prop-types';
import './EllipsisTooltipContainer.scss';

const EllipsisTooltipContainer = ({ children }) => {
  const containerRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const checkOverflow = useCallback(() => {
    if (containerRef.current) {
      if (containerRef.current.scrollWidth > containerRef.current.offsetWidth) {
        if (!showTooltip) {
          setShowTooltip(true);
        }
      } else if (showTooltip) {
        setShowTooltip(false);
      }
    }
  }, [showTooltip]);

  useEffect(() => {
    checkOverflow();
  }, [checkOverflow]);

  if (showTooltip) {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={(
          <Tooltip>
            {children}
          </Tooltip>
        )}
      >
        <div className="ellipsis-tooltip-container" ref={containerRef}>{children}</div>
      </OverlayTrigger>
    );
  }

  return (
    <div className="ellipsis-tooltip-container" ref={containerRef}>{children}</div>
  );
};

EllipsisTooltipContainer.propTypes = {
  children: componentChildrenPropType.isRequired
};

export default EllipsisTooltipContainer;
