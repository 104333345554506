export const handleFieldBlur = function handleFieldBlur(event) {
  const { fieldErrors, fieldValues, fieldsValidated } = this.state;

  const fieldId = event.target.id;

  // Validate blured fields and mark them as validated
  this.setState({
    fieldErrors: {
      ...fieldErrors,
      [fieldId]: this.validators[fieldId](fieldValues[fieldId])
    },
    fieldsValidated: {
      ...fieldsValidated,
      [fieldId]: true
    }
  });
};

export const handleFieldChange = function handleFieldChange(event) {
  const {
    fieldErrors, fieldsValidated, fieldValues
  } = this.state;

  const fieldId = event.target.id;
  const fieldValue = event.target.value;

  // Update field value
  const newState = {
    fieldValues: {
      ...fieldValues,
      [fieldId]: fieldValue
    }
  };

  // If field has been validated, revalidate
  if (fieldsValidated[fieldId]) {
    newState.fieldErrors = {
      ...fieldErrors,
      [fieldId]: this.validators[fieldId](fieldValue)
    };
  }

  this.setState(newState);
};

export const validateForm = function validateForm() {
  // Validate all fields
  const { fieldValues } = this.state;
  const newFieldErrors = {};

  let errors = false;

  Object.keys(this.validators).forEach((fieldName) => {
    newFieldErrors[fieldName] = this.validators[fieldName](fieldValues[fieldName]);

    errors = errors || newFieldErrors[fieldName] !== null;
  });

  this.setState({
    fieldErrors: newFieldErrors
  });

  return !errors;
};
