class Index {
  constructor(state) {
    const { fields } = state || {};
    this.fields = fields || [];
    this.name = this.computeNameFromFields(this.fields);
  }

  computeNameFromFields(fields) {
    const suffix = fields.slice().sort().join('_');
    const name = `idx_${suffix}`;
    return name;
  }

  addField(field) {
    const fields = this.fields.concat([field]);
    return new Index({ fields });
  }

  removeField(field) {
    const fields = this.fields.filter((f) => f !== field);
    return new Index({ fields });
  }

  isValid() {
    return this.fields.length > 0;
  }
}

export default Index;
