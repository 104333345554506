import React, { useState, useMemo, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Loader from './Loader';
import { fetchResultsMatrix } from './analytics/services';
import NoData from './NoData';
import { isAuditSelection, isDatasetSelection } from './analytics/DataSelector';

const ResultsMatrix = ({ dataSelector }) => {
  const [{ data, isLoading }, setState] = useState({ data: null, isLoading: false });

  const computeAccuracy = (currentData) => {
    const totalNoFeedback = currentData['predicted_error_no_feedback'] + currentData['predicted_good_no_feedback'];
    if (totalNoFeedback > 0) {
      return '';
    }

    const tn = parseInt(currentData.predicted_error_admin_error, 10);
    const tp = parseInt(currentData.predicted_good_admin_good, 10);
    const fp = parseInt(currentData.predicted_error_admin_good, 10);
    const fn = parseInt(currentData.predicted_good_admin_error, 10);
    const total = tn + tp + fp + fn;
    if (total > 0) {
      return `Accuracy: ${(((tn + tp) / total) * 100).toFixed(2)} %`;
    }
    return '';
  };

  const fetchData = async (currentDataSelector) => {
    if (
      !isDatasetSelection(currentDataSelector.selectionType)
      && !isAuditSelection(currentDataSelector.selectionType)
    ) {
      return null;
    }

    const { selectedRepository, selectedDataset, selectedAudit } = currentDataSelector;
    const repository = selectedRepository ? selectedRepository.id : null;
    const dataset = selectedDataset ? selectedDataset.id : null;
    const audit = selectedAudit ? selectedAudit.id : null;
    const results = await fetchResultsMatrix(repository, dataset, audit);
    return results.data.length === 0 ? null : results.data[0];
  };

  const fetchDataAndUpdateState = async (currentDataSelector) => {
    try {
      setState({
        data, isLoading: true
      });
      const newData = await fetchData(currentDataSelector);

      setState({
        isLoading: false,
        data: newData
      });
    } catch (e) {
      this.setState({ data: null, isLoading: false });
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState(dataSelector);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchDataAndUpdateState(dataSelector);
  }, [dataSelector]); // eslint-disable-line react-hooks/exhaustive-deps

  const fmt = (val) => (val === undefined || val === null || val === '' || val === 'n/a' ? '—' : val);

  const renderDatasetResultsMatrix = (currentData) => {
    const accuracy = computeAccuracy(currentData);
    return (
      <Table bordered size="sm">
        <tbody>
          <tr>
            <th colSpan="2" rowSpan="3" className="text-center align-middle">{accuracy}</th>
            <th className="bg-light text-center" colSpan="6">Actual</th>
            <th colSpan="2" />
          </tr>
          <tr>
            <th className="bg-light text-center" colSpan="3">Error</th>
            <th className="bg-light text-center" colSpan="3">Valid</th>
            <th className="bg-light text-center align-middle" rowSpan="2">Total Disagree %</th>
            <th className="bg-light text-center align-middle" rowSpan="2">No Feedback</th>
            <th className="bg-light text-center align-middle" rowSpan="2">Total Records</th>
          </tr>
          <tr>
            <th className="bg-light text-center">Admin</th>
            <th className="bg-light text-center">User</th>
            <th className="bg-light text-center">Disagree %</th>
            <th className="bg-light text-center">Admin</th>
            <th className="bg-light text-center">User</th>
            <th className="bg-light text-center">Disagree %</th>
          </tr>
          <tr>
            <th className="bg-light text-center align-middle" rowSpan="2">Predicted</th>
            <th className="bg-light text-center">Error</th>
            <td>{fmt(currentData.predicted_error_admin_error)}</td>
            <td>{fmt(currentData.predicted_error_user_error)}</td>
            <td>{fmt(currentData.predicted_error_admin_user_error_disagree)}</td>
            <td>{fmt(currentData.predicted_error_admin_good)}</td>
            <td>{fmt(currentData.predicted_error_user_good)}</td>
            <td>{fmt(currentData.predicted_error_admin_user_good_disagree)}</td>
            <td>{fmt(currentData.total_disagree)}</td>
            <td>{fmt(currentData.predicted_error_no_feedback)}</td>
            <td>{fmt(currentData.predicted_error_total)}</td>
          </tr>
          <tr>
            <th className="bg-light text-center">Valid</th>
            <td>{fmt(currentData.predicted_good_admin_error)}</td>
            <td>{fmt(currentData.predicted_good_user_error)}</td>
            <td>{fmt(currentData.predicted_good_admin_user_error_disagree)}</td>
            <td>{fmt(currentData.predicted_good_admin_good)}</td>
            <td>{fmt(currentData.predicted_good_user_good)}</td>
            <td>{fmt(currentData.predicted_good_admin_user_good_disagree)}</td>
            <td>—</td>
            <td>{fmt(currentData.predicted_good_no_feedback)}</td>
            <td>{fmt(currentData.predicted_good_total)}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderAuditResultsMatrix = (currentData) => {
    const accuracy = computeAccuracy(currentData);

    return (
      <Table bordered size="sm">
        <tbody>
          <tr>
            <th colSpan="2" rowSpan="2" className="text-center  align-middle">{accuracy}</th>
            <th className="bg-light text-center" colSpan="2">Actual</th>
            <th colSpan="2" />
          </tr>
          <tr>
            <th className="bg-light text-center">Error</th>
            <th className="bg-light text-center">Valid</th>
            <th className="bg-light text-center align-middle">No Feedback</th>
            <th className="bg-light text-center align-middle">Total Records</th>
          </tr>
          <tr>
            <th className="bg-light text-center align-middle" rowSpan="2">Predicted</th>
            <th className="bg-light text-center">Error</th>
            <td>{fmt(currentData.predicted_error_admin_error)}</td>
            <td>{fmt(currentData.predicted_error_admin_good)}</td>
            <td>{fmt(currentData.predicted_error_no_feedback)}</td>
            <td>{fmt(currentData.predicted_error_total)}</td>
          </tr>
          <tr>
            <th className="bg-light text-center">Valid</th>
            <td>{fmt(currentData.predicted_good_admin_error)}</td>
            <td>{fmt(currentData.predicted_good_admin_good)}</td>
            <td>{fmt(currentData.predicted_good_no_feedback)}</td>
            <td>{fmt(currentData.predicted_good_total)}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    if (data === null) {
      return <NoData message="No data available. Please select a Dataset or Audit." />;
    }

    if (isAuditSelection(dataSelector)) {
      return renderAuditResultsMatrix(data);
    }
    return renderDatasetResultsMatrix(data);
  }, [isLoading, data]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default ResultsMatrix;
