import React, { Component } from 'react';
import filterFactory, {
  textFilter, numberFilter
} from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Table } from 'react-bootstrap';
import { contextMenu } from 'react-contexify';
import ToolkitProvider from './CustomToolkitProvider';
import { pageSizeOptions } from './datagridOptions';
import CustomPaginationPageListRenderer from '../CustomPaginationPageListRenderer';
import CustomPaginationTotalRenderer from '../CustomPaginationTotalRenderer';
import CustomSizePerPageRenderer from '../CustomSizePerPageRenderer';
import DatetimeFilterTableHeader from '../DatetimeFilterTableHeader';
import DatagridMenu from './DatagridMenu';
import DatagridCellContext from '../../context/datagridCell/datagridCellContext';
import DatagridCellStateProvider from '../../context/datagridCell/DatagridCellStateProvider';
import OptimizedDatagridCell from './OptimizedDatagridCell';
import { getBaseColumns, generateGetCellClasses, generateGetCellInlineStyles } from './defaults';
import DatagridTableWrapper from './DatagridTableWrapper';
import ClearFilters from './ClearFilters';
import RefreshDatagrid from './RefreshDatagrid';
import PendingBulkLabelInfo from './PendingBulkLabelInfo';
import {
  updateAuditResultRecord, updateDatasetResultRecord, updateAuditResultRecords, updateDatasetRecords,
  postDatasetBulkLabel, postAuditBulkLabel, getAuditHeader, getDatasetHeader
} from './services';
import { isAuditSelection, getSelectedValues } from './DataSelector';
import ModalOkCancel from '../ModalOkCancel';
import ModalOk from '../ModalOk';

import {
  LABEL_NOTSET, LABEL_BAD, LABEL_GOOD, COLUMN_TYPE_INTEGER, COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_FLOAT, HEADER_POLL_MILLISECONDS
} from '../../constants';
import './Datagrid.scss';

class Datagrid extends Component {
  constructor(props) {
    super(props);

    this.timeoutHandle = null;

    this.state = {
      showLabelConfirmModal: false,
      labelConfirmMessage: '',
      labelConfirmOkHandler: null,
      showRefreshControls: false,
      hasPendingBulkLabels: false,
      showErrorModal: false
    };

    this.latestColumns = null;
    this.trackedFilters = {};
    this.clearingFilters = false;
    this.currentFilters = [];

    this.unmounted = false;
  }

  componentDidMount() {
    const headerPoller = async () => {
      const pollstartTime = Date.now();
      const { dataSelector } = this.props;

      const { repository, dataset, audit } = getSelectedValues(dataSelector);

      let headerResult;
      if (audit) {
        headerResult = await getAuditHeader(repository, dataset, audit);
      } else {
        headerResult = await getDatasetHeader(repository, dataset);
      }

      if (this.unmounted) return;

      const { hasPendingBulkLabels, lastBulkAction } = this.state;
      if (lastBulkAction && lastBulkAction > pollstartTime) {
        return;
      }

      headerResult = headerResult.data;
      const header = headerResult.data;

      // Set new state
      if (hasPendingBulkLabels && !header['has_pending_bulk_labels']) {
        this.setState({
          hasPendingBulkLabels: false,
          showRefreshControls: true,
          lastBulkAction: null
        });
      } else if (hasPendingBulkLabels !== header['has_pending_bulk_labels']) {
        this.setState({
          hasPendingBulkLabels: header['has_pending_bulk_labels']
        });
      }
      const timeoutHandle = window.setTimeout(headerPoller, HEADER_POLL_MILLISECONDS);
      this.timeoutHandle = timeoutHandle;
    };

    const timeoutHandle = window.setTimeout(headerPoller, HEADER_POLL_MILLISECONDS);
    this.timeoutHandle = timeoutHandle;
  }

  componentWillUnmount() {
    this.unmounted = true;

    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  getColumnName = (columnNumber) => {
    const { dataField } = this.columns[columnNumber + 1];
    return dataField.replace('data.', '');
  };

  handleHeaderContextMenu(e, column) {
    const { hasPendingBulkLabels } = this.state;
    e.preventDefault();
    contextMenu.show({
      id: 'datagrid_menu',
      event: e,
      props: {
        column,
        mode: 'column',
        hasPendingBulkLabels
      }
    });
  }

  handleContextMenu(e, column, columnIndex, row, rowIndex) {
    // always prevent default behavior
    e.preventDefault();

    const { hasPendingBulkLabels } = this.state;
    // Don't forget to pass the id and the event and voila!
    contextMenu.show({
      id: 'datagrid_menu',
      event: e,
      props: {
        column,
        columnIndex,
        row,
        rowIndex,
        hasPendingBulkLabels
      }
    });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 16) {
      // Shift key pressed
      document.body.classList.add('shift-key-pressed');
    }

    if (e.keyCode === 18) {
      // Alt key pressed
      document.body.classList.add('alt-key-pressed');
    }
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 16) {
      // Shift key depressed
      document.body.classList.remove('shift-key-pressed');
    }
    if (e.keyCode === 18) {
      // Alt key depressed
      document.body.classList.remove('alt-key-pressed');
    }
  }

  toggleCellLabel = (rowNumber, columnNumber) => {
    const { data } = this.props;

    const row = data[rowNumber];

    const fieldName = this.getColumnName(columnNumber);

    const currentLabel = row.qualityFeedback[fieldName] !== undefined ? row.qualityFeedback[fieldName] : LABEL_NOTSET;

    let newLabel = LABEL_BAD;
    if (currentLabel === LABEL_GOOD) newLabel = LABEL_NOTSET;
    if (currentLabel === LABEL_BAD) newLabel = LABEL_GOOD;

    this.setFieldLabel(row, fieldName, newLabel);
  }

  handleContextMenuMenuItemClick = (event, props, mode, label, selectedCells) => {
    const getCellInlineStyles = (row, field) => {
      if (row.errorsFields.includes(field)) {
        return {
          border: '3px dotted orange'
        };
      }
      return {};
    };

    const renderTable = (row) => {
      return (
        <Table size="sm" striped bordered hover>
          <tbody>
            {Object.keys(row.data).map((fieldName) => {
              let cellClasses = '';
              if (row.qualityFeedback[fieldName] === LABEL_GOOD) {
                cellClasses += ' bg-good text-white';
              }

              if (row.qualityFeedback[fieldName] === LABEL_BAD) {
                cellClasses += ' bg-bad text-white';
              }

              if (row.hasMultivariateAnomalies) {
                cellClasses += ' bg-multivariate-anomaly';
              }
              return <tr key={fieldName}><th>{fieldName}</th><td style={getCellInlineStyles(row, fieldName)} className={cellClasses}>{row.data[fieldName]}</td></tr>;
            })}
          </tbody>
        </Table>
      );
    };

    if (mode === 'cell') {
      this.setFieldLabel(props.row, props.column.dataField, label);
    } else if (mode === 'record') {
      this.setState({
        showLabelConfirmModal: true,
        labelConfirmOkHandler: () => this.setAllFieldsLabel(props.row, label),
        labelConfirmTitle: 'Row Label Confirm',
        labelConfirmMessage: (
          <>
            <p>
              The requested bulk action will label all values as &apos;{label}&apos; for the row. This
              change cannot be undone once applied. Would you like to apply this bulk action?`
            </p>
            {renderTable(props.row)}
          </>
        )
      });
    } else if (mode === 'selection') {
      this.setSelectedCellsLabel(selectedCells, label);
    } else if (mode === 'column') {
      this.setColumnLabel(props.column.dataField.replace('data.', ''), label);
    }
  }

  getUniqueUserLabels(records) {
    // TODO: Get the unique labels from the server.
    const set = new Set(records.map((rec) => (rec && rec.tag) || undefined));
    set.delete(undefined); // Delete the undefined value.
    set.delete(null);
    return Array.from(set);
  }

  setFieldLabel(row, field, label) {
    const fieldName = field.replace('data.', '');
    row.qualityFeedback[fieldName] = label; // eslint-disable-line no-param-reassign
    row.qualityLabel = this.calculateQualityLabelState(row); // eslint-disable-line no-param-reassign
    this.updateRecord(row, true);
  }

  setColumnLabel(columnName, label) {
    const { repository, dataset, audit } = getSelectedValues(this.props.dataSelector);
    const labelConfirmOkHandler = async () => {
      try {
        if (this.props.onBulkColumnConfirmation) {
          this.props.onBulkColumnConfirmation();
        }
        if (audit) {
          await postAuditBulkLabel(repository, dataset, audit, columnName, label);
        } else {
          await postDatasetBulkLabel(repository, dataset, columnName, label);
        }

        if (this.unmounted) return;
      } catch (e) {
        this.setState({
          showErrorModal: true
        });
      }
    };
    this.setState({
      showLabelConfirmModal: true,
      labelConfirmTitle: 'Please Confirm',
      labelConfirmMessage: `The requested bulk action will label all values as '${label}' for the selected column '${columnName}'. This change cannot be undone once applied. Would you like to apply this bulk action?`,
      labelConfirmOkHandler
    });
  }

  updateSelectedRecords = async (rows, triggerUpdateActions) => {
    const { onRowChanged, dataSelector } = this.props;

    const isAudit = isAuditSelection(dataSelector.selectionType);
    const { repository, dataset, audit } = getSelectedValues(dataSelector);

    const recordsLabel = [];
    rows.forEach((row) => {
      recordsLabel.push(
        {
          id: row.id,
          tag: row.tag,
          qualityLabel: row.qualityLabel,
          qualityFeedback: row.qualityFeedback
        }
      );
    });

    try {
      if (isAudit) {
        await updateAuditResultRecords(repository, dataset, audit, recordsLabel);
      } else {
        await updateDatasetRecords(repository, dataset, recordsLabel);
      }

      if (this.unmounted) return;

      if (triggerUpdateActions) {
        onRowChanged();
      }
    } catch (e) {
      console.log(e);
    }
  }


  updateRecord = async (row, triggerUpdateActions) => {
    const { dataSelector } = this.props;
    const isAudit = isAuditSelection(dataSelector.selectionType);
    const { repository, dataset, audit } = getSelectedValues(dataSelector);

    const { onRowChanged } = this.props;
    const data = {
      tag: row.tag,
      qualityLabel: row.qualityLabel,
      qualityFeedback: row.qualityFeedback
    };

    if (triggerUpdateActions) {
      this.forceUpdate();
    }

    try {
      if (isAudit) {
        updateAuditResultRecord(repository, dataset, audit, row.id, data);
      } else {
        updateDatasetResultRecord(repository, dataset, row.id, data);
      }

      if (triggerUpdateActions) {
        onRowChanged();
      }
    } catch (e) {
      // TODO: Missing action on error
    }
  }

  calculateQualityLabelState(row) {
    if (this.hasBadFields(row)) {
      return LABEL_BAD;
    }

    if (this.hasGoodFields(row)) {
      return LABEL_GOOD; // Does not has any bad fields and all the detected anomaly fields are in good state.
    }

    return LABEL_NOTSET;
  }

  handleDocumentBlur = () => {
    document.body.classList.remove('shift-key-pressed', 'alt-key-pressed');
  }

  hasBadFields(row) {
    return Object.keys(row.qualityFeedback).some((key) => row.qualityFeedback[key] === LABEL_BAD);
  }

  hasGoodFields(row) {
    return Object.keys(row.qualityFeedback).some((key) => row.qualityFeedback[key] === LABEL_GOOD);
  }

  setAllFieldsLabel(row, label) {
    const feedback = {};

    Object.keys(row.data).forEach((field) => { feedback[field] = label; });

    row.qualityFeedback = feedback; // eslint-disable-line no-param-reassign
    row.qualityLabel = this.calculateQualityLabelState(row); // eslint-disable-line no-param-reassign

    this.updateRecord(row, true);
  }

  setSelectedCellsLabel(selectedCells, label) {
    const { onRowChange, data, dataSelector } = this.props;
    const recordIds = data.map((record) => record.id);
    const records = data;
    const rows = [];
    Object.keys(selectedCells).forEach((rowId) => {
      const rowNumber = recordIds.indexOf(parseInt(rowId, 10));

      if (!records[rowNumber]) {
        return;
      }

      const row = records[rowNumber];

      if (row.qualityFeedback === undefined) {
        row.qualityFeedback = {};
      }

      selectedCells[rowId].forEach(
        (columnNumber) => { row.qualityFeedback[this.getColumnName(columnNumber)] = label; }
      );

      row.qualityLabel = this.calculateQualityLabelState(row); // eslint-disable-line no-param-reassign
      rows.push(row);
    });

    console.log('ROWS', rows);

    const isAudit = isAuditSelection(dataSelector.selectionType);
    if (isAudit) {
      this.updateSelectedRecords(rows);
    } else {
      rows.forEach(row => {
        this.updateRecord(row);
      });
    }

    this.forceUpdate();
    onRowChange();
  }

  createFieldColumn = (columnName, columnIndex, schema) => {
    const createFilter = (columnName) => {
      const columnSchema = schema[columnName];
      if (columnSchema !== undefined && [COLUMN_TYPE_FLOAT, COLUMN_TYPE_INTEGER, COLUMN_TYPE_NUMBER].includes(columnSchema['type'])) {
        return numberFilter({
          placeholder: ' ', // NOTE: Put an space char to clear the placeholder.
          className: 'form-control-sm',
          delay: 800,
          getFilter: (filter) => {
            this.trackedFilters[columnName] = filter;
          }
        });
      }
      return textFilter({
        placeholder: ' ', // NOTE: Put an space char to clear the placeholder.
        className: 'form-control-sm',
        delay: 700,
        getFilter: (filter) => {
          this.trackedFilters[columnName] = filter;
        }
      });
    }

    const {
      applyDatetimeFilter, datetimeFilterableFields, datetimeFilters
    } = this.props;

    const column = {
      dataField: `data.${columnName}`,
      text: columnName,
      classes: generateGetCellClasses(columnName),
      style: generateGetCellInlineStyles(columnName),
      events: {
        onContextMenu: (e, column, colIndex, row, rowIndex) => {
          this.handleContextMenu(e, column, colIndex, row, rowIndex);
        }
      },
      editable: false,
      sort: true,
      sorting: true,
      formatter: (cell, row, rowIndex) => (
        <DatagridCellContext.Consumer>
          {(contextValues) => (
            <OptimizedDatagridCell
              columnIndex={columnIndex}
              // Turn relative rowIndexes into absolute
              relativeRowIndex={rowIndex}
              content={cell}
              tableRef={this.node}
              toggleCellLabel={this.toggleCellLabel}
              {...contextValues} // eslint-disable-line react/jsx-props-no-spreading
            />
          )}
        </DatagridCellContext.Consumer>
      )
    };

    // Datetime or text filter ?
    if (datetimeFilterableFields.has(columnName)) {
      column.headerFormatter = (column, colIndex, { sortElement }) => (
        <div className="datagrid-header-wrapper" onContextMenu={(e) => this.handleHeaderContextMenu(e, column)}>
          <DatetimeFilterTableHeader
            schema={schema}
            applyFilter={
              // Pass through the col's name
              (newValues) => {
                applyDatetimeFilter(columnName, newValues);
              }
            }
            appliedFilter={datetimeFilters[columnName] !== undefined ? datetimeFilters[columnName] : null}
          />
          <div className="datagrid-header sortable">
            {column.text}
            {sortElement}
          </div>
        </div>
      );
    } else {
      column.filter = createFilter(columnName);
      column.headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
        <div className="datagrid-header-wrapper" onContextMenu={(e) => this.handleHeaderContextMenu(e, column)}>
          {filterElement}<br />
          <div className="datagrid-header sortable">
            {column.text}
            {sortElement}
          </div>
        </div>
      );
    }

    return column;
  }

  onTableChangeHandler = (changeType, changeParams) => {
    if (this.clearingFilters) {
      return;
    }

    if (changeType === 'cellEdit') {
      return;
    }

    if (!this.props.onTableChange) {
      return;
    }

    let { sortOrder, sortField, filters, page, sizePerPage } = changeParams;
    const sort = {}
    if (sortField) {
      sortField = sortField.replace('data.', '');
      sort[sortField] = sortOrder;
    }

    if (changeType === 'filter') {
      const operatorsMap = {
        '=': 'eq',
        '!=': 'neq',
        '>': 'gt',
        '>=': 'ge',
        '<': 'lt',
        '<=': 'le',
        'LIKE': 'like'
      };

      const getFilterOp = (filterType, comparator, filterVal) => {
        if (filterType === 'TEXT' && comparator === 'LIKE') {
          return 'like';
        } else if (filterType === 'SELECT') {
          return 'eq';
        } else if (filterType === 'NUMBER') {
          if (typeof filterVal === 'object') {
            comparator = filterVal.comparator;
          }
          return operatorsMap[comparator];
        }
      }

      const translateField = (field) => {
        if (field === '__typo_qualityLabel') {
          return '__typo_quality_label';
        } else if (field === '__typo_hasErrors') {
          return '__typo_has_errors';
        }

        return field;
      }

      filters = Object.keys(filters).map(field => {
        let value = filters[field].filterVal;

        const op = getFilterOp(filters[field].filterType, filters[field].comparator, filters[field].filterVal);
        if (filters[field].filterType === 'NUMBER') {
          value = filters[field].filterVal['number'];
        }

        if (op === 'like' && !value.includes('%')) {
          value = `%${value}%`;
        }

        if (field.includes('data.')) {
          field = field.replace('data.', '');
        } else {
          field = translateField(`__typo_${field}`);
        }
        return { field, op, value };
      });
      filters = filters.filter((f) => f.value && f.op);

      // Compare with current filters to check if there is actually a change on the filters.
      if (JSON.stringify(this.currentFilters) === JSON.stringify(filters)) {
        return;
      }
      this.currentFilters = filters;
    } else {
      // NOTE: We use existing filters for all operations besides filtering.
      filters = this.currentFilters;
    }

    this.props.onTableChange(changeType, { page, sizePerPage, sort, filters });
  }

  getColumns(records, schema, hasFilters, isAudit) {
    this.customLabelOptions = this.getUniqueUserLabels(records);
    const defaultColumns = getBaseColumns(isAudit, null, null, this.customLabelOptions,
      (row, updatedCustomLabelOptions) => {
        this.updateRecord(row, true);
        this.customLabelOptions = updatedCustomLabelOptions;
      }, this.trackedFilters);

    const baseColumnIndex = defaultColumns.length - 1;
    const record = records.length > 0 && records[0].data;

    if (record) {
      const recordColumns = Object.keys(record).map(
        (columnName, index) => this.createFieldColumn(columnName, baseColumnIndex + index, schema)
      );
      this.latestColumns = defaultColumns.concat(recordColumns);
      return this.latestColumns;
    }

    if (records.length === 0) {
      // NOTE: Keep columns when table is empty and filters were applied.
      if (hasFilters > 0 && this.latestColumns) {
        return this.latestColumns;
      }
      // NOTE: This is important to enable sorting. There is a bug in the BootstrapTable
      // component that does not creates the SortConetxt if we do not set an initial column.
      const dummyColumns = [{ dataField: '', text: '', sort: true }];
      return dummyColumns; // NOTE: Default column. Check how we can set no columns at all.
    }

    return undefined;
  }

  getNoErrorFields(records) {
    if (records.length === 0) {
      return [];
    }
    let allErrorFields = records.reduce((acc, record) => {
      const errorFields = record.errorsFields || [];
      return acc.concat(errorFields);
    }, []);
    allErrorFields = Array.from(new Set(allErrorFields));

    // Get listing of columns with no detected errors
    const allFields = Object.keys(records[0].data);
    const noErrorColumns = allFields.filter((field) => !allErrorFields.includes(field));
    return noErrorColumns;
  }

  passTableRef = (ref) => {
    this.node = ref;
  }

  render() {
    let { schema } = this.props;
    schema = schema || {};

    const {
      data, dataSelector, datetimeFilters, filters, hideNoErrorColumns,
      onClearFiltersEnd, page, sizePerPage, sort, totalSize, onRefreshDatagrid
    } = this.props;
    const { labelConfirmTitle, labelConfirmMessage, showLabelConfirmModal, showRefreshControls, hasPendingBulkLabels } = this.state;

    const labelConfirmOkHandler = () => {
      const { labelConfirmOkHandler } = this.state;
      if (labelConfirmOkHandler) {
        labelConfirmOkHandler();
      }

      this.setState({
        showLabelConfirmModal: false,
        labelConfirmHandler: null,
        labelConfirmMessage: ''
      });
    };

    const labelConfirmCancelHandler = () => {

      this.setState({
        showLabelConfirmModal: false,
        labelConfirmHandler: null,
        labelConfirmMessage: ''
      });
    };

    const isAudit = isAuditSelection(dataSelector.selectionType);
    const hasFilters = (filters && filters.length > 0) || (datetimeFilters && Object.keys(datetimeFilters).length > 0);

    const columns = this.getColumns(data, schema, hasFilters, isAudit);
    this.columns = columns;

    const paginationOptions = {
      totalSize,
      sizePerPage,
      sizePerPageList: pageSizeOptions.map((num) => ({ text: num, value: num })),
      page,
      showTotal: true,
      pageListRenderer: CustomPaginationPageListRenderer,
      paginationTotalRenderer: CustomPaginationTotalRenderer,
      sizePerPageRenderer: CustomSizePerPageRenderer,
      alwaysShowAllBtns: true
    };

    const handleOnRefreshDatagridClick = () => {
      onRefreshDatagrid();
      this.setState({
        showRefreshControls: false
      });
    };

    // NOTE: We start with the columns that represent the record data as visible and then
    // we hide the ones that are good.
    const columnVisibility = {};

    columns.forEach((col) => {
      if (col.dataField.includes('data.')) {
        columnVisibility[col.dataField] = true;
      }
    });

    const noErrorFields = this.getNoErrorFields(data);
    if (hideNoErrorColumns) {
      noErrorFields.forEach((field) => {
        columnVisibility[`data.${field}`] = false;
      });
    }

    this.columnVisibility = columnVisibility;
    const noResults = !data || data.length === 0;

    return (
      <div
        className={`bootstrap-table-wrapper with-header-separator${noResults ? ' no-results' : ''}`}
        ref={this.wrapperRef}
      >
        {hasPendingBulkLabels && <PendingBulkLabelInfo />}
        {showRefreshControls && <RefreshDatagrid onClick={handleOnRefreshDatagridClick} />}
        {hasFilters && (
          <ClearFilters onClick={() => {
            this.clearingFilters = true;
            Object.keys(this.trackedFilters).forEach((key) => {
              const func = this.trackedFilters[key];
              if (func) {
                func('');
              }
            });
            this.clearingFilters = false;
            this.currentFilters = [];
            onClearFiltersEnd();
          }}
          />
        )}
        <ModalOk show={this.state.showErrorModal} title="Cannot Apply Label" message="There is a bulk label action going on. Try again after it completes." okLabel="Close" onOkClick={() => { this.setState({ showErrorModal: false }) }} />
        <ModalOkCancel
          size="lg"
          cancelOnClick={labelConfirmCancelHandler}
          title={labelConfirmTitle}
          message={labelConfirmMessage}
          onHide={labelConfirmCancelHandler}
          okLabel="Yes"
          cancelLabel="No"
          okOnClick={labelConfirmOkHandler}
          show={showLabelConfirmModal}
        />
        {noResults && (
          <div className="no-results-label">
            No results found.
          </div>
        )}
        <DatagridCellStateProvider
          dataSelector={dataSelector}
          filters={filters}
          page={page}
          sizePerPage={sizePerPage}
          sort={sort}
        >
          <ToolkitProvider
            bootstrap4
            columns={columns}
            keyField="id"
            columnToggle
            {...this.props} // eslint-disable-line react/jsx-props-no-spreading
          >
            {(props) => (
              <DatagridTableWrapper
                bordered
                classes="text-nowrap"
                condensed
                hover
                noDataIndication={<div className="no-results-spacer" />}
                cellEdit={cellEditFactory({ mode: 'click' })}
                filter={filterFactory()}
                pagination={paginationFactory(paginationOptions)}
                striped
                passRef={this.passTableRef}
                ref={this.node}
                wrapperClasses="table-responsive"
                {...props.baseProps} // eslint-disable-line react/jsx-props-no-spreading
                columnToggleProps={props.columnToggleProps}
                columnVisibility={this.columnVisibility}
                {...this.props} // eslint-disable-line react/jsx-props-no-spreading
                // NOTE: We want our own handler here. Putting this above could couse an override
                // of this handler from the one passed by props.
                onTableChange={this.onTableChangeHandler}
              />
            )}
          </ToolkitProvider>
          <DatagridCellContext.Consumer>
            {({ selectedCells, moveCursorTo }) => (
              <DatagridMenu
                selectedCells={selectedCells}
                moveCursorTo={moveCursorTo}
                onMenuItemClick={this.handleContextMenuMenuItemClick}
                tableRef={this.node}
                hasPendingBulkLabels={hasPendingBulkLabels}
              />
            )}
          </DatagridCellContext.Consumer>
        </DatagridCellStateProvider>
      </div>
    );
  }
}

//  Datagrid.propTypes = {
//  schema
//  data, dataSelector, datetimeFilters, filters, onClearFiltersEnd
//  }

export default Datagrid;
