import React from 'react';
import { Spinner } from 'react-bootstrap';
import InfoHelp from '../InfoHelp';

const PendingBulkLabelInfo = () => {
  return (
    <InfoHelp icon={<Spinner animation="border" size="md" />} variant="primary" dismissible={false}>
      Applying bulk label action. You will not be able to update labels until the bulk labeling is complete.
    </InfoHelp>
  );
};

export default PendingBulkLabelInfo;
