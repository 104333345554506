import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import {
  Alert, Badge, Breadcrumb, Button, Col, Modal, Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine, faCode, faCog, faPen, faStop
} from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import ReactRouterPropTypes from 'react-router-prop-types';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import CustomPaginationPageListRenderer from '../components/CustomPaginationPageListRenderer';
import CustomPaginationTotalRenderer from '../components/CustomPaginationTotalRenderer';
import CustomSizePerPageRenderer from '../components/CustomSizePerPageRenderer';
import tableHeaderFormatterFactory from '../components/tableHeaderFormatterFactory';
import Loader from '../components/Loader';
import Title from '../components/Title';
import Info from '../components/Info';
import IconButton from '../components/IconButton';
import TypoLibInstructions from '../components/TypoLibInstructions';

import './ListRepositories.scss';


class ListRepositories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      apiKeys: [],
      showDisableModal: false,
      selectedApiKeyIndex: -1,
      typoLibScriptTagApiKey: null,
      updatedAlert: null
    };

    this.updatedAlertTimeout = null;
    this.unmounted = false;
  }

  async componentDidMount() {
    try {
      const repositories = await this.getRepositories();

      if (this.unmounted) return;

      this.setState({
        apiKeys: repositories
      });
    } catch (e) {
      alert(e);
    }

    const { history, location: { search } } = this.props;

    const qs = queryString.parse(search);
    const updatedAlert = qs.updated !== undefined ? qs.updated : null;

    if (updatedAlert) {
      this.updatedAlertTimeout = setTimeout(() => {
        this.setState({ updatedAlert: null });
      }, 5000);

      history.push('/repositories');
    }

    this.setState({ isLoading: false, updatedAlert });
  }

  componentWillUnmount() {
    this.unmounted = true;

    if (this.updatedAlertTimeout) {
      clearTimeout(this.updatedAlertTimeout);
    }
  }

  getRepositories() {
    return API.get('typo-dashboard', '/repositories');
  }

  handleShowDisableModal = (apiKeyId) => {
    const { apiKeys } = this.state;

    const selectedApiKeyIndex = apiKeys.findIndex((x) => x.apiKeyId === apiKeyId);
    const selectedApiKey = apiKeys[selectedApiKeyIndex];

    if (selectedApiKey) {
      this.setState({
        selectedApiKeyIndex,
        selectedApiKeyId: selectedApiKey.apiKeyId,
        showDisableModal: true
      });
    }
  }

  handleHideDisableModal = () => {
    this.setState({
      showDisableModal: false
    });
  }

  confirmDisable = () => {
    const { selectedApiKeyIndex } = this.state;
    this.setState({ showDisableModal: false });
    this.updateEnable(selectedApiKeyIndex, false);
  }

  showTypoLibScriptTag = (apiKey) => {
    this.setState({
      typoLibScriptTagApiKey: apiKey
    });
  }

  hideTypoLibScriptTag = () => {
    this.setState({
      typoLibScriptTagApiKey: null
    });
  }

  updateRepository(repository) {
    return API.put('typo-dashboard', `/apikeys/${repository.apiKeyId}`, {
      body: repository
    });
  }

  async updateEnable(index, enabled) {
    const { apiKeys: { index: apiKey } } = this.state;

    apiKey.enabled = enabled;

    try {
      await this.updateRepository(apiKey);

      if (this.unmounted) return;

      this.forceUpdate();
    } catch (e) {
      alert(e);
    }
  }

  enable(apiKeyId) {
    const { apiKeys } = this.state;

    const index = apiKeys.findIndex((x) => x.apiKeyId === apiKeyId);
    this.updateEnable(index, true);
  }

  renderRepositoryList() {
    const { apiKeys } = this.state;

    apiKeys.map(
      ({
        apiKeyId, description, domainFilters, enabled
      }, index) => (
        <tr
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <td>
            <Link to={`/repositories/view/${apiKeyId}`}>{apiKeyId}</Link>
          </td>
          <td>{description}</td>
          <td>
            {domainFilters.map((domain, i) => (
              <Fragment
                key={i} // eslint-disable-line react/no-array-index-key
              >
                <Badge>{domain}</Badge><br />
              </Fragment>
            ))}
          </td>
          <td>{enabled ? <Badge variant="success">Yes</Badge> : <Badge variant="danger">No</Badge>}</td>
          <td />
        </tr>
      )
    );

    const columns = [
      {
        text: 'Name',
        dataField: 'apiKeyId',
        classes: 'nowrap',
        filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
        headerFormatter: tableHeaderFormatterFactory(),
        sort: true
      },
      {
        text: 'Activation URLs',
        formatter: (cell, row) => row.domainFilters.map(
          (domain, index) => (
            <Fragment
              key={index} // eslint-disable-line react/no-array-index-key
            >
              <Badge>{domain}</Badge><br />
            </Fragment>
          )
        ),
        headerStyle: () => ({ width: '100%', textAlign: 'left' }),
        filter: textFilter({ placeholder: ' ', className: 'form-control-sm' }),
        headerFormatter: tableHeaderFormatterFactory(),
        dataField: 'activationUrl',
        sort: true
      },
      {
        text: 'Enabled',
        headerStyle: () => ({ width: '10%', textAlign: 'left' }),
        formatter: (cell, row) => (row.enabled ? (
          <Badge variant="success">Yes</Badge>
        ) : (
          <Badge variant="danger">No</Badge>
        )),
        headerFormatter: tableHeaderFormatterFactory(),
        dataField: 'enabled',
        sort: true
      },
      {
        text: 'Actions',
        formatter: (cell, row) => (
          <div className="text-nowrap">
            <IconButton to={`/repositories/${row.apiKeyId}`} label="Edit repository" icon={faPen} />
            <IconButton
              variant="secondary"
              label="Configure repository"
              icon={faCog}
              to={`/configurations/new?repository=${row.apiKeyId}`}
            />
            <IconButton
              variant="warning"
              label="View repository usage"
              icon={faChartLine}
              to={`/usage?apiKeyIds=${row.apiKeyId}`}
            />
            <IconButton
              variant="info"
              label="Use Typo on a Website"
              icon={faCode}
              onClick={() => { this.showTypoLibScriptTag(row.apiKeyId); }}
            />
          </div>
        ),
        headerFormatter: tableHeaderFormatterFactory(),
        headerStyle: () => ({ textAlign: 'left' }),
        dataField: 'actions'
      }
    ];

    const formatDate = (date) => {
      const isoString = date.toISOString();
      const dateParts = isoString.split('T');

      return `${dateParts[0]} at ${dateParts[1].substring(0, 8)}`;
    };

    const expandRow = {
      renderer: (row) => (
        <div index={row.apiKeyId}>
          {row.description && (
            <p><strong>Description: </strong>{row.description}</p>
          )}
          <p><strong>Created at: </strong>{formatDate(new Date(row.createdAt))}</p>
          <p>
            {row.enabled ? (
              <Button variant="outline-danger" size="sm" onClick={() => this.handleShowDisableModal(row.apiKeyId)}>
                <FontAwesomeIcon icon={faStop} /> Disable
              </Button>
            ) : (
              <Button variant="success" size="sm" onClick={() => this.enable(row.apiKeyId)}>Enable</Button>
            )}
          </p>
        </div>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => (
        <div className="datagrid-header expand-collapse-toggle"><b>{isAnyExpands ? '–' : '+'}</b></div>
      ),
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <b style={{ cursor: 'pointer' }}>–</b>
          );
        }
        return (
          <b style={{ cursor: 'pointer' }}>···</b>
        );
      }
    };

    const paginationOptions = {
      sizePerPage: 25,
      sizePerPageList: [10, 25, 50, 100, 250, 500].map((num) => ({ text: num, value: num })),
      showTotal: true,
      pageListRenderer: CustomPaginationPageListRenderer,
      paginationTotalRenderer: CustomPaginationTotalRenderer,
      sizePerPageRenderer: CustomSizePerPageRenderer,
      alwaysShowAllBtns: true
    };

    return (
      <div className="bootstrap-table-wrapper with-header-separator">
        <BootstrapTable
          filter={filterFactory()}
          wrapperClasses="table-responsive"
          noDataIndication="There aren't any results."
          expandRow={expandRow}
          hover
          bootstrap4
          striped
          bordered
          pagination={paginationFactory(paginationOptions)}
          columns={columns}
          keyField="apiKeyId"
          data={apiKeys}
        />
      </div>
    );
  }

  renderContent() {
    const { apiKeys, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (apiKeys.length === 0) {
      return (
        <Info title="No repositories found" wrappingParagraph={false}>
          <p>
            Typo requires a repository to store your data for validation.
          </p>
          <p>
            <Link className="btn btn-primary" to="/repositories/create">Create your first repository</Link>
          </p>
        </Info>
      );
    }

    return this.renderRepositoryList();
  }

  render() {
    const {
      selectedApiKeyId, showDisableModal, typoLibScriptTagApiKey, updatedAlert
    } = this.state;

    return (
      <>
        <Row>
          <Col>
            <Modal show={showDisableModal} onHide={this.handleHideDisableModal}>
              <Modal.Header closeButton>
                <Modal.Title>Disable Repository</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure that you want to disable the repository <br /><strong>{selectedApiKeyId}</strong>?
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={this.handleHideDisableModal}>Cancel</Button>
                <Button size="sm" variant="outline-danger" onClick={this.confirmDisable}>Disable</Button>
              </Modal.Footer>
            </Modal>
            <Breadcrumb>
              <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
              <Breadcrumb.Item active>Repositories</Breadcrumb.Item>
            </Breadcrumb>
            <Title
              text="Repositories"
              toolbar={(
                <Link to="/repositories/create">
                  <Button size="sm" className="text-nowrap">Create Repository</Button>
                </Link>
              )}
            />
            {updatedAlert && (
              <Alert variant="success">
              Successfully updated repository <b>{updatedAlert}</b>.
              </Alert>
            )}
            {this.renderContent()}
          </Col>
        </Row>
        {typoLibScriptTagApiKey && (
          <Modal show onHide={this.hideTypoLibScriptTag} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Use Typo on a Website</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TypoLibInstructions apiKey={typoLibScriptTagApiKey} />
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.hideTypoLibScriptTag}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

ListRepositories.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired
};

export default ListRepositories;
