import { memo } from 'react';

import Plot from 'react-plotly.js';
import {
  PLOT_TYPE_HISTOGRAM, PLOT_TYPE_BOXPLOT, PLOT_TYPE_SCATTER_2D, PLOT_INLIER_COLOR,
  PLOT_OUTLIER_COLOR, PLOT_OUTLIER_NOT_XY_COLOR, LABEL_GOOD, LABEL_BAD, LABEL_NOTSET
} from '../../constants';
import { findSelectedRecordsIndexes } from './utils';

export default memo(Plot);

export const getPlotDataForHistogram = (records, xField) => {
  if (!xField) {
    return {};
  }
  const xs = records.map((rec) => rec[xField]);
  const data = [{
    x: xs,
    type: 'histogram',
    name: 'Histogram',
    marker: {
      color: 'rgb(8,81,156)'
    }
  }];

  const newLayout = {
    autosize: true,
    title: 'Histogram',
    xaxis: {
      title: xField,
      zeroline: false
    },
    yaxis: {
      title: 'Count'
    }
  };

  return {
    data,
    layout: newLayout,
    title: 'Histogram'
  };
};

export const getPlotDataForBoxPlot = (records, xField, plotType) => {
  if (!xField) {
    return {};
  }
  const xs = records.map((rec) => rec[xField]);
  const trace = {
    x: xs,
    type: 'box',
    name: 'Whiskers and<br>Outliers',
    marker: {
      color: PLOT_INLIER_COLOR,
      outliercolor: PLOT_OUTLIER_COLOR,
      line: {
        outliercolor: PLOT_OUTLIER_COLOR,
        outlierwidth: 2
      }
    },
    boxpoints: 'suspectedoutliers',
    orientation: 'h'
  };

  return {
    data: [trace],
    layout: {
      autosize: true,
      title: plotType.description,
      xaxis: {
        title: xField,
        zeroline: false
      },
      boxmode: 'overlay'
    },
    title: plotType.description
  };
};

export const getPlotDataForScatterPlot2D = (records, xField, yField, plotType, record, layout, selectedRecords) => {
  if (!records || records.length === 0 || !xField || !yField) {
    return {};
  }

  const userMarkedGoodRecords = records.filter((rec) => rec.qualityLabel === LABEL_GOOD);
  const xsUserInliners = userMarkedGoodRecords.map((rec) => rec[xField]);
  const ysUserInliners = userMarkedGoodRecords.map((rec) => rec[yField]);

  const userMarkedBadRecords = records.filter((rec) => rec.qualityLabel === LABEL_BAD);
  const xsUserOutliers = userMarkedBadRecords.map((rec) => rec[xField]);
  const ysUserOutliers = userMarkedBadRecords.map((rec) => rec[yField]);

  const typoPredictedInliers = records.filter(
    (rec) => (rec.qualityLabel === LABEL_NOTSET || rec.qualityLabel === undefined) && !rec.hasErrors
  );
  const xsTypoPredictedInliners = typoPredictedInliers.map((rec) => rec[xField]);
  const ysTypoPredictedInliners = typoPredictedInliers.map((rec) => rec[yField]);

  // Separate predicted outliers between those involving xField or yField and those not
  const typoPredictedOutliersForXY = [];
  const typoPredictedOutliersNotForXY = [];

  records.filter(
    (rec) => (rec.qualityLabel === LABEL_NOTSET || rec.qualityLabel === undefined)
      && rec.hasErrors
  ).forEach((r) => {
    if (r.errorsFields.includes(xField) || r.errorsFields.includes(yField)) {
      typoPredictedOutliersForXY.push(r);
    } else {
      typoPredictedOutliersNotForXY.push(r);
    }
  });
  const xsTypoPredictedOutliersForXY = typoPredictedOutliersForXY.map((rec) => rec[xField]);
  const ysTypoPredictedOutliersForXY = typoPredictedOutliersForXY.map((rec) => rec[yField]);

  const xsTypoPredictedOutliersNotForXY = typoPredictedOutliersNotForXY.map((rec) => rec[xField]);
  const ysTypoPredictedOutliersNotForXY = typoPredictedOutliersNotForXY.map((rec) => rec[yField]);

  let xsCurrentRecord = [];
  let ysCurrentRecord = [];

  let color = PLOT_INLIER_COLOR;
  if (record) {
    xsCurrentRecord = [record.data[xField]];
    ysCurrentRecord = [record.data[yField]];
    if (record.qualityLabel === LABEL_BAD || (record.qualityLabel === LABEL_NOTSET && record.hasErrors)) {
      color = PLOT_OUTLIER_COLOR;
    }
  }

  // Set selected points to null will select all points.
  let userMarkedBadRecordsSelectedIndexes = null;
  let userMarkedGoodRecordsSelectedIndexes = null;
  let typoPredictedInliersSelectedIndexes = null;
  let typoPredictedOutliersForXYSelectedIndexes = null;
  let typoPredictedOutliersNotForXYSelectedIndexes = null;

  // Set selected points to [] will not select any points.
  // TODO: this should be removed, every time this function is called selectedRecords will be reset
  if (selectedRecords && selectedRecords.length > 0) {
    userMarkedBadRecordsSelectedIndexes = findSelectedRecordsIndexes(userMarkedBadRecords, selectedRecords);
    userMarkedGoodRecordsSelectedIndexes = findSelectedRecordsIndexes(userMarkedGoodRecords, selectedRecords);
    typoPredictedInliersSelectedIndexes = findSelectedRecordsIndexes(typoPredictedInliers, selectedRecords);
    typoPredictedOutliersForXYSelectedIndexes = findSelectedRecordsIndexes(
      typoPredictedOutliersForXY, selectedRecords
    );
    typoPredictedOutliersNotForXYSelectedIndexes = findSelectedRecordsIndexes(
      typoPredictedOutliersNotForXY, selectedRecords
    );
  }

  const currentRecord = {
    x: xsCurrentRecord,
    y: ysCurrentRecord,
    mode: 'markers',
    type: 'scatter',
    customdata: [record],
    marker: {
      symbol: 'square',
      color,
      line: {
        color: 'yellow',
        width: 1
      },
      size: 10
    },
    name: 'Current Record',
    text: '',
    hoverinfo: 'none'
  };

  // User inliers
  const userLabeledGood = {
    x: xsUserInliners,
    y: ysUserInliners,
    customdata: userMarkedGoodRecords,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'star',
      color: PLOT_INLIER_COLOR,
      size: 10
    },
    name: 'Row Labeled Good',
    selectedpoints: userMarkedGoodRecordsSelectedIndexes,
    text: '',
    hoverinfo: 'none'
  };

  // User outliers
  const userLabeledBad = {
    x: xsUserOutliers,
    y: ysUserOutliers,
    customdata: userMarkedBadRecords,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'star',
      color: PLOT_OUTLIER_COLOR,
      size: 10
    },
    name: 'Row Labeled Bad',
    selectedpoints: userMarkedBadRecordsSelectedIndexes,
    text: [],
    hoverinfo: 'none'
  };

  // Typo predicted inliners
  const predictedGood = {
    x: xsTypoPredictedInliners,
    y: ysTypoPredictedInliners,
    customdata: typoPredictedInliers,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'dot',
      color: PLOT_INLIER_COLOR,
      size: 10
    },
    name: 'Predicted Good',
    selectedpoints: typoPredictedInliersSelectedIndexes,
    text: [],
    hoverinfo: 'none'
  };

  // Typo predicted outliers involving X or Y fields
  const predictedBadXY = {
    x: xsTypoPredictedOutliersForXY,
    y: ysTypoPredictedOutliersForXY,
    customdata: typoPredictedOutliersForXY,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'dot',
      color: PLOT_OUTLIER_COLOR,
      size: 10
    },
    name: 'X or Y Predicted Bad',
    selectedpoints: typoPredictedOutliersForXYSelectedIndexes,
    text: [],
    hoverinfo: 'none'
  };

  // Typo predicted outliers not involving X or Y fields
  const predictedBadNotXY = {
    x: xsTypoPredictedOutliersNotForXY,
    y: ysTypoPredictedOutliersNotForXY,
    customdata: typoPredictedOutliersNotForXY,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'dot',
      color: PLOT_OUTLIER_NOT_XY_COLOR,
      size: 10
    },
    name: 'Other Fields Predicted Bad',
    selectedpoints: typoPredictedOutliersNotForXYSelectedIndexes,
    text: [],
    hoverinfo: 'none'
  };

  const traces = [currentRecord, predictedBadXY, predictedBadNotXY, predictedGood, userLabeledBad, userLabeledGood];
  const annotations = [];

  let newLayout = {};

  if (!layout) {
    newLayout = {
      hovermode: 'closest',
      autosize: true,
      title: plotType.description,
      xaxis: {
        title: xField
      },
      yaxis: {
        title: yField
      },
      annotations
    };
  } else {
    newLayout = layout;
  }

  return {
    data: traces,
    layout: newLayout,
    title: plotType.description
  };
};

// TODO: selectedRecord, null & newSelectedRecords should be removed as are unnecessary
export const getPlotData = (records, plotType, fields, record, layout, selectedRecords) => {
  if (!plotType) {
    return {};
  }

  const { x: xField, y: yField } = fields;

  if (plotType === PLOT_TYPE_HISTOGRAM) {
    return getPlotDataForHistogram(records, xField);
  }

  if (plotType === PLOT_TYPE_BOXPLOT) {
    return getPlotDataForBoxPlot(records, xField, plotType);
  }

  if (plotType === PLOT_TYPE_SCATTER_2D) {
    return getPlotDataForScatterPlot2D(records, xField, yField, plotType, record, layout, selectedRecords);
  }

  return {};
};
