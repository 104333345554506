import { AUDIT_STATE_COMPLETED } from '../constants';

const GET_AUDIT_SUCCESS = 'GET_AUDIT_SUCCESS';

class AuditStatePoller {
  constructor(
    datasetService, repositoryName, datasetName, auditId,
    auditUpdateCallback = () => {}, interval = 5000
  ) {
    this.pollingStopped = false;
    this.pollerInterval = null;
    this.registeredProgress = null;

    this.record = null;
    this.state = null;
    this.stateChanged = false;
    this.totalErrors = null;
    this.totalRecords = null;
    this.totalAudited = null;

    const pollFunction = async () => {
      // Poll service for audit status
      try {
        const response = await datasetService.getAuditRecord(repositoryName, datasetName, auditId);

        if (this.pollingStopped) return;

        if (!typeof (response.data) === 'undefined' || response.data.code !== GET_AUDIT_SUCCESS) {
          // A server side error has occurred
          auditUpdateCallback(this.preparePayload(true));
          return;
        }

        const { data: { data } } = response;

        this.record = data;

        // Update data on class instance
        if (this.state !== data.state && this.state !== null) {
          this.stateChanged = true;
        }

        this.state = data.state;
        this.totalErrors = data.total_errors;
        this.totalRecords = data.total_records;
        this.totalAudited = data.total_audited;

        if (this.state === AUDIT_STATE_COMPLETED) {
          // No more polling when audit is completed
          this.stopPolling();
        }

        auditUpdateCallback(this.preparePayload());
      } catch (e) {
        // Silently fail on error
        auditUpdateCallback(this.preparePayload(true));
      }
    };

    this.pollerInterval = setInterval(pollFunction, interval);
    pollFunction(true);
  }

  preparePayload = (error) => {
    const payload = {
      state: this.state,
      stateChanged: this.stateChanged,
      totalErrors: this.totalErrors,
      totalRecords: this.totalRecords,
      totalAudited: this.totalAudited,
      fullRecord: this.record
    };

    this.stateChanged = false; // Reset

    if (error) {
      // An error has occurred, as we don't want the UI to be blocked,
      // in case of error, we just mark the response as an error,
      // and return the last available data
      payload[error] = true;
      return payload;
    }

    return payload;
  }

  stopPolling = () => {
    clearInterval(this.pollerInterval);
    this.pollingStopped = true;
  }
}

export default AuditStatePoller;
