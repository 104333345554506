import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
// TODO: The following import should be removed, but it breaks the DataSelectors at the top
// of the Analytics page
import DataSelector from '../components/analytics/DataSelector';
import { StateProvider } from '../components/analytics/state';
import { reducer } from '../components/analytics/reducer';
import { DATA_SELECTION_TYPE_NONE } from '../constants';
import DatasetAnalyticsTitle from '../components/analytics/DatasetAnalyticsTitle';
import DatagridContainer from '../components/analytics/DatagridContainer';
import PlotContainer from '../components/analytics/PlotContainer';
import StatsContainer from '../components/analytics/StatsContainer';
import ResultsMatrixContainer from '../components/analytics/ResultsMatrixContainer';

import './ResultsAnalytics.scss';

const initDataSelector = (match) => {
  const repositories = [];
  const datasets = [];
  const audits = [];
  const selectedRepository = null;
  const selectedDataset = null;
  const selectedAudit = null;
  const defaultRepositoryId = match.params.repository;
  const defaultDatasetId = match.params.dataset;
  const defaultAuditId = match.params.audit;
  const selectionType = DATA_SELECTION_TYPE_NONE;

  return {
    defaultRepositoryId,
    defaultDatasetId,
    defaultAuditId,
    repositories,
    selectedRepository,
    datasets,
    selectedDataset,
    audits,
    selectedAudit,
    selectionType
  };
};

const ResultsAnalytics = ({ match }) => {
  const dataSelector = initDataSelector(match);

  const showPlots = false;
  const showStats = false;
  const showResultsMatrix = false;
  const filters = [];
  const sort = {};
  const datetimeFilters = {};
  const datetimeFilterableFields = {};
  const page = 1;
  const sizePerPage = 10;
  const hideNoErrorColumns = false;
  const schema = {};
  const selectedRecords = [];

  const records = null;
  const plotInput = {
    plotType: null,
    xField: null,
    yField: null
  };

  const initialState = {
    dataSelector,
    showPlots,
    showStats,
    showResultsMatrix,
    filters,
    sort,
    datetimeFilters,
    schema,
    datetimeFilterableFields,
    page,
    sizePerPage,
    records,
    selectedRecords,
    hideNoErrorColumns,
    plotInput
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <DatasetAnalyticsTitle />
      <DataSelector />
      <StatsContainer />
      <ResultsMatrixContainer />
      <PlotContainer />
      <DatagridContainer />
    </StateProvider>
  );
};

ResultsAnalytics.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
};

export default ResultsAnalytics;
