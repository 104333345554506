export default {
  prod: {
    application: {
      BASE_URL: 'https://console.typo.ai',
      ENVIRONMENT_NAME: 'prod'
    },
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://h4y3gcgxak.execute-api.us-east-1.amazonaws.com/prod'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_k6VkHU8tu',
      APP_CLIENT_ID: '12d56kmf6ihirfjlal9bfhmocp',
      IDENTITY_POOL_ID: 'us-east-1:0e97703a-4d50-4ba6-b094-eeaa52555cfd',
      OAUTH_DOMAIN: 'auth.typo.ai'
    }
  },
  demo: {
    application: {
      BASE_URL: 'https://demo.typo.ai',
      ENVIRONMENT_NAME: 'demo'
    },
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://wl58lcwgfi.execute-api.us-east-1.amazonaws.com/demo'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_4N8HKPvN9',
      APP_CLIENT_ID: '7tf8c4vvo143q1evgmkj8eo6ib',
      IDENTITY_POOL_ID: 'us-east-1:b0bf2b30-5adc-48b2-9dab-3bd66bc1d1f7',
      OAUTH_DOMAIN: 'auth-demo.typo.ai'
    }
  },
  dev: {
    application: {
      BASE_URL: 'https://dev.typo.ai',
      ENVIRONMENT_NAME: 'dev'
    },
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://79pdce3wm1.execute-api.us-east-1.amazonaws.com/dev'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_scgo90teR',
      APP_CLIENT_ID: '2ssg3livgso24p2ggurhmfof5h',
      IDENTITY_POOL_ID: 'us-east-1:634c3044-dee6-4117-b9d9-399b422a45bb',
      OAUTH_DOMAIN: 'typo-user-pool-dev.auth.us-east-1.amazoncognito.com'
    }
  },
  localhost: {
    application: {
      BASE_URL: 'http://localhost:4000',
      ENVIRONMENT_NAME: 'dev'
    },
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://79pdce3wm1.execute-api.us-east-1.amazonaws.com/dev'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_scgo90teR',
      APP_CLIENT_ID: '14aq9q3f34mc299i59r453c924',
      IDENTITY_POOL_ID: 'us-east-1:634c3044-dee6-4117-b9d9-399b422a45bb',
      OAUTH_DOMAIN: 'typo-user-pool-dev.auth.us-east-1.amazoncognito.com'
    }
  }
};
