import React, { useEffect, useMemo, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Row, Col, Form } from 'react-bootstrap';
import { useStateValue } from './state';
import {
  SELECT_REPOSITORY_ACTION, SELECT_DATASET_ACTION, SELECT_AUDIT_ACTION, DATASELECTOR_INITIALIZED_ACTION
} from './actions';
import { fetchAllRepositories, fetchRepositoryDatasets, fetchDatasetAudits } from './services';
import {
  DATA_SELECTION_TYPE_DATASET, DATA_SELECTION_TYPE_AUDIT, DATA_SELECTION_TYPE_NONE, DATASET_AUDIT_TYPE
} from '../../constants';
import { getTimestampFromDate } from '../../utils';

function DataSelector() {
  const [state, dispatch] = useStateValue();
  const { dataSelector } = state;

  const unmounted = useRef(false);

  const fetchRepositories = async () => {
    let repositories = await fetchAllRepositories();

    repositories = repositories || [];
    repositories = repositories.map((repository) => ({ id: repository['apiKeyId'], name: repository['apiKeyId'] }));

    return repositories;
  };

  const fetchDatasets = async (repositoryId) => {
    let datasets = await fetchRepositoryDatasets(repositoryId);

    datasets = datasets || [];
    datasets = datasets.map((dataset) => ({ id: dataset['name'], name: dataset['display_name'], type: dataset.type }));
    datasets.sort((a, b) => (a['name'] < b['name'] ? -1 : 1));

    return datasets;
  };

  const fetchAudits = async (repositoryId, datasetId) => {
    const result = await fetchDatasetAudits(repositoryId, datasetId);
    let audits = result.data || [];

    audits = audits.map((audit) => ({ id: audit.id, name: `${getTimestampFromDate(audit.created_at)} (${audit.id})` }));

    return audits;
  };

  const initialize = async () => {
    const { defaultRepositoryId, defaultDatasetId, defaultAuditId } = dataSelector;
    let repositories = [];
    let selectedRepository = null;
    let datasets = [];
    let selectedDataset = null;
    let audits = [];
    let selectedAudit = null;

    repositories = await fetchRepositories();
    if (unmounted.current) return;

    selectedRepository = repositories.find((repository) => repository.id === defaultRepositoryId);
    selectedRepository = selectedRepository || null;

    if (selectedRepository) {
      datasets = await fetchDatasets(selectedRepository.id);
      if (unmounted.current) return;

      selectedDataset = datasets.find((dataset) => dataset.id === defaultDatasetId);

      if (selectedDataset && selectedDataset.type === DATASET_AUDIT_TYPE) {
        audits = await fetchAudits(selectedRepository.id, selectedDataset.id);
        if (unmounted.current) return;
        selectedAudit = audits.find((audit) => audit.id === parseInt(defaultAuditId, 10));
      }
    }
    const action = {
      type: DATASELECTOR_INITIALIZED_ACTION,
      param: {
        repositories,
        selectedRepository,
        datasets,
        selectedDataset,
        audits,
        selectedAudit
      }
    };
    dispatch(action);
  };

  // Load repositories after component mount.
  useEffect(() => {
    initialize();

    return () => {
      // Cleanup
      unmounted.current = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => {
    let {
      repositories, datasets, audits
    } = dataSelector;

    const {
      selectedAudit, selectedDataset, selectedRepository
    } = dataSelector;

    // NOTE: Set defaults in case no proper state was provided.
    repositories = repositories || [];
    datasets = datasets || [];
    audits = audits || [];

    // NOTE: Make selections compatible with TypeAhead.
    const selectedRepositoryList = selectedRepository ? [selectedRepository] : [];
    const selectedDatasetList = selectedDataset ? [selectedDataset] : [];
    const selectedAuditList = selectedAudit ? [selectedAudit] : [];

    const getFromSelection = (selection) => {
      let selected = null;

      if (selection.length > 0) {
        [selected] = selection;
      }

      return selected;
    };

    const onRepositoryChangeHandler = async (selection) => {
      const newSelectedRepository = getFromSelection(selection);
      let newDatasets = [];

      if (newSelectedRepository) {
        newDatasets = await fetchDatasets(newSelectedRepository.id);
        if (unmounted.current) return;
      }

      const action = {
        type: SELECT_REPOSITORY_ACTION,
        param: {
          selectedRepository: newSelectedRepository,
          datasets: newDatasets,
          selectedDataset: null,
          audits: [],
          selectedAudit: null
        }
      };

      dispatch(action);
    };

    const onDatasetChangeHandler = async (selection) => {
      const newSelectedDataset = getFromSelection(selection);
      let newAudits = [];

      if (newSelectedDataset && newSelectedDataset.type === DATASET_AUDIT_TYPE) {
        newAudits = await fetchAudits(selectedRepository.id, newSelectedDataset.id);
        if (unmounted.current) return;
      }

      const action = {
        type: SELECT_DATASET_ACTION,
        param: {
          selectedDataset: newSelectedDataset,
          audits: newAudits,
          selectedAudit: null
        }
      };

      dispatch(action);
    };

    const onAuditChangeHandler = async (selection) => {
      const newSelectedAudit = getFromSelection(selection);
      const action = {
        type: SELECT_AUDIT_ACTION,
        param: {
          selectedAudit: newSelectedAudit
        }
      };

      dispatch(action);
    };

    const repositorySelectorDisabled = repositories.length === 0;
    const datasetSelectorDisabled = !selectedRepository;
    const auditSelectorDisabled = !selectedDataset || selectedDataset.type !== DATASET_AUDIT_TYPE;
    const auditsCountLabel = !auditSelectorDisabled ? `(${audits.length})` : '';

    return (
      <Row className="mb-3">
        <Col>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridRepository">
                <Form.Label>Repository</Form.Label>
                <Typeahead
                  id="repository"
                  disabled={repositorySelectorDisabled}
                  clearButton
                  selected={selectedRepositoryList}
                  onChange={onRepositoryChangeHandler}
                  highlightOnlyResult
                  selectHintOnEnter
                  multiple={false}
                  labelKey="name"
                  options={repositories}
                  placeholder="Choose a repository..."
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridDataset">
                <Form.Label>Dataset</Form.Label>
                <Typeahead
                  id="dataset"
                  disabled={datasetSelectorDisabled}
                  clearButton
                  selected={selectedDatasetList}
                  onChange={onDatasetChangeHandler}
                  highlightOnlyResult
                  selectHintOnEnter
                  multiple={false}
                  labelKey="name"
                  options={datasets}
                  placeholder="Choose a dataset..."
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridRecord">
                <Form.Label>
                  Audit {auditsCountLabel}
                </Form.Label>
                <Typeahead
                  id="audit"
                  disabled={auditSelectorDisabled}
                  clearButton
                  selected={selectedAuditList}
                  onChange={onAuditChangeHandler}
                  highlightOnlyResult
                  selectHintOnEnter
                  labelKey="name"
                  multiple={false}
                  options={audits}
                  placeholder="Choose an audit..."
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    );
  }, [dataSelector]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default DataSelector;

export const isAuditSelection = (selectionType) => selectionType === DATA_SELECTION_TYPE_AUDIT;

export const isDatasetSelection = (selectionType) => selectionType === DATA_SELECTION_TYPE_DATASET;

export const isNoneSelection = (selectionType) => selectionType === DATA_SELECTION_TYPE_NONE;

export const getSelectedValues = ({ selectedRepository, selectedDataset, selectedAudit }) => ({
  repository: selectedRepository ? selectedRepository.id : null,
  dataset: selectedDataset ? selectedDataset.id : null,
  audit: selectedAudit ? selectedAudit.id : null
});
