import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default class extends Component {

    render () {
        return (
            <OverlayTrigger placement="bottom" key="tooltip-notifications" overlay={<Tooltip id={`tooltip-notifications`}>No notifications.</Tooltip>}>
            <FontAwesomeIcon style={{"color": "gray" }} icon={faBell}>&nbsp;</FontAwesomeIcon>
        </OverlayTrigger>
        );
    }

}