import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ResultLabel = ({ record, showViewScreenshots, onScreenshotButtonClick }) => (
  <>
    {record.hasErrors ? (
      <>
        <OverlayTrigger
          overlay={(
            <Tooltip>
              Detected as <strong>erroneous</strong><br /> by&nbsp;
              <strong>{record.processedModels} of {record.totalModels}</strong> models executed.
            </Tooltip>
          )}
        >
          <FontAwesomeIcon style={{ color: '#FF6384' }} icon={faExclamationTriangle} />
        </OverlayTrigger>
        &nbsp;Error&nbsp;
      </>
    ) : (
      <>
        <OverlayTrigger
          overlay={(
            <Tooltip>
              Detected as <strong>normal</strong><br /> by&nbsp;
              <strong>{record.processedModels} of {record.totalModels}</strong> models executed.
            </Tooltip>
          )}
        >
          <FontAwesomeIcon style={{ color: 'rgb(60,179,113)' }} icon={faCheckCircle} />
        </OverlayTrigger>
        &nbsp;OK&nbsp;
      </>
    )}

    {showViewScreenshots && record.userFingerprint && record.screenshotsCount && (
      <OverlayTrigger
        overlay={(
          <Tooltip id="tooltip-view-payload">
            View Screenshots
          </Tooltip>
        )}
      >
        <Button variant="light" size="sm" className="btn-tiny">
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => {
              if (onScreenshotButtonClick !== undefined) onScreenshotButtonClick(record.userFingerprint, record.id);
            }}
          />
        </Button>
      </OverlayTrigger>
    )}
  </>
);

ResultLabel.propTypes = {
  onScreenshotButtonClick: PropTypes.func,
  record: PropTypes.object.isRequired,
  showViewScreenshots: PropTypes.bool
};

ResultLabel.defaultProps = {
  onScreenshotButtonClick: undefined,
  showViewScreenshots: false
};

export default ResultLabel;
