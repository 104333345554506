import {
  DATASELECTOR_INITIALIZED_ACTION,
  SELECT_REPOSITORY_ACTION,
  SELECT_DATASET_ACTION,
  SELECT_AUDIT_ACTION,
  TOGGLE_PLOT_ACTION,
  TOGGLE_STATS_ACTION,
  TOGGLE_RESULTS_MATRIX_ACTION,
  DATAGRID_INIT_ACTION,
  DATAGRID_REFRESH_ACTION,
  TOGGLE_HIDE_NO_ERROR_COLUMNS,
  CLEAR_DATAGRID_FILTERS_ACTION,
  SET_SELECTED_RECORDS_ACTION,
  SET_LOADING_SELECTED_RECORDS_ACTION
} from './actions';
import { DATA_SELECTION_TYPE_DATASET, DATA_SELECTION_TYPE_AUDIT, DATA_SELECTION_TYPE_NONE } from '../../constants';

const computeDataSelectionType = (dataSelector) => {
  const { selectedDataset, selectedAudit } = dataSelector;
  if (selectedAudit) {
    return DATA_SELECTION_TYPE_AUDIT;
  }

  if (selectedDataset) {
    return DATA_SELECTION_TYPE_DATASET;
  }

  return DATA_SELECTION_TYPE_NONE;
};

export const computeTitle = (dataSelector) => {
  const { selectedAudit, selectionType } = dataSelector;
  if (selectionType === DATA_SELECTION_TYPE_DATASET) {
    return 'Dataset Analytics';
  }
  if (selectionType === DATA_SELECTION_TYPE_AUDIT) {
    return `Audit ${selectedAudit.id} Analytics`;
  }
  return 'Analytics';
};

const selectRepositoryReducer = (state, action) => {
  const dataSelector = {
    ...state.dataSelector,
    ...action.param
  };
  dataSelector.selectionType = computeDataSelectionType(dataSelector);

  return {
    ...state,
    dataSelector,
    records: null,
    datetimeFilters: {},
    datetimeFilterableFields: {},
    schema: {},
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    filters: [],
    sort: {},
    selectedRecords: []
  };
};

const selectDatasetReducer = (state, action) => {
  const dataSelector = {
    ...state.dataSelector,
    ...action.param
  };

  dataSelector.selectionType = computeDataSelectionType(dataSelector);

  const newState = {
    ...state,
    dataSelector,
    records: null,
    datetimeFilters: {},
    datetimeFilterableFields: {},
    schema: {},
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    filters: [],
    sort: {},
    selectedRecords: [],
    loadingSelectedRecords: false
  };

  return newState;
};

const selectAuditReducer = (state, action) => {
  const dataSelector = {
    ...state.dataSelector,
    ...action.param
  };
  dataSelector.selectionType = computeDataSelectionType(dataSelector);
  return {
    ...state,
    dataSelector,
    records: null,
    datetimeFilters: {},
    datetimeFilterableFields: {},
    schema: {},
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    filters: [],
    sort: {},
    selectedRecords: []
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING_SELECTED_RECORDS_ACTION: {
      return {
        ...state,
        loadingSelectedRecords: action.param
      };
    }
    case SET_SELECTED_RECORDS_ACTION: {
      return {
        ...state,
        selectedRecords: action.param,
        loadingSelectedRecords: false
      };
    }
    case DATASELECTOR_INITIALIZED_ACTION: {
      const dataSelector = { ...state.dataSelector, ...action.param };
      dataSelector.selectionType = computeDataSelectionType(dataSelector);

      return {
        ...state,
        dataSelector,
        records: null,
        datetimeFilters: {},
        datetimeFilterableFields: {},
        schema: {},
        page: 1,
        sizePerPage: 10,
        totalSize: 0,
        filters: [],
        sort: {}
      };
    }
    case SELECT_REPOSITORY_ACTION: {
      const newState = selectRepositoryReducer(state, action);
      return newState;
    }
    case SELECT_DATASET_ACTION: {
      return selectDatasetReducer(state, action);
    }
    case SELECT_AUDIT_ACTION: {
      return selectAuditReducer(state, action);
    }
    case TOGGLE_PLOT_ACTION: {
      return {
        ...state,
        showPlots: !state.showPlots
      };
    }
    case TOGGLE_STATS_ACTION: {
      return {
        ...state,
        showStats: !state.showStats
      };
    }
    case TOGGLE_RESULTS_MATRIX_ACTION: {
      return {
        ...state,
        showResultsMatrix: !state.showResultsMatrix
      };
    }
    case DATAGRID_INIT_ACTION: {
      const {
        records, totalSize, datetimeFilterableFields, schema
      } = action.param;

      return {
        ...state,
        records,
        totalSize,
        datetimeFilterableFields,
        schema
      };
    }
    case DATAGRID_REFRESH_ACTION: {
      const {
        page, sizePerPage, filters, sort, datetimeFilters, records, totalSize
      } = action.param;

      return {
        ...state,
        page,
        sizePerPage,
        filters,
        datetimeFilters,
        sort,
        records,
        totalSize
      };
    }
    case TOGGLE_HIDE_NO_ERROR_COLUMNS: {
      return {
        ...state,
        hideNoErrorColumns: !state.hideNoErrorColumns
      };
    }
    case CLEAR_DATAGRID_FILTERS_ACTION: {
      return {
        ...state,
        filters: [],
        datetimeFilters: {}
      };
    }
    default:
  }
  return state;
};
