import React from 'react';

const tableHeaderFormatterFactory = (title) => (column, colIndex, { sortElement, filterElement }) => (
  <>
    {filterElement && (
      <>{filterElement}<br /></>
    )}
    <div className="datagrid-header">
      {title || column.text}
      {sortElement}
    </div>
  </>
);

export default tableHeaderFormatterFactory;
