import React, { Component } from 'react';
import {
  Breadcrumb, Button, Col, Form, Row
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { API, Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'react-global-configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReactRouterPropTypes from 'react-router-prop-types';

import Title from '../components/Title';
import InfoHelp from '../components/InfoHelp';
import LoaderButton from '../components/LoaderButton';
import Loader from '../components/Loader';
import { validateRepositoryName } from '../validators';
import AsyncValidatorFormControl from '../components/AsyncValidatorFormControl';
import { INVALID_FORMAT, REPOSITORY_ALREADY_EXISTS } from '../errorCodes';
import { existingRepositoryName, invalidRepositoryName } from '../validationErrors';

class ManageRepository extends Component {
  constructor(props) {
    super(props);

    this.mode = typeof (props.match.params.name) === 'undefined' ? 'create' : 'edit';

    if (this.mode === 'edit') {
      this.editRepositoryName = props.match.params.name;
    }

    this.state = {
      isLoadingRepositoryData: this.mode === 'edit',
      isLoading: false,
      repositoryName: '',
      description: '',
      domainFilters: [],
      domainFilter: '',
      repositoryNameError: '',
      validatingRepositoryName: false
    };
  }

  componentDidMount = () => {
    if (this.mode === 'edit') this.loadEditRepository();
  }

  loadEditRepository = async () => {
    try {
      const response = await API.get('typo-dashboard', `/repositories/${this.editRepositoryName}`);
      this.setState({
        isLoadingRepositoryData: false,
        repositoryName: response.apiKeyId,
        description: response.description,
        domainFilters: response.domainFilters
      });
    } catch (e) {
      alert(e);
    }
  }

  processRepositoryNameErrorResponse = (e) => {
    if (typeof (e.response) !== 'undefined' && e.response.status === 400) {
      if (!e.response.data.success) {
        let errorMessage = '';

        switch (e.response.data.errorCode) {
          case INVALID_FORMAT:
            errorMessage = invalidRepositoryName;
            break;
          case REPOSITORY_ALREADY_EXISTS:
            errorMessage = existingRepositoryName;
            break;
          default:
        }
        this.setState({
          validatingRepositoryName: false,
          repositoryNameError: errorMessage,
          isLoading: false
        });
      }
    } else {
      alert(e);
    }
  }

  handleChange = (event) => {
    let { value } = event.target;

    const newState = {
      [event.target.id]: value
    };

    if (event.target.id === 'repositoryName') {
      value = value.toLowerCase();
      newState.validatingRepositoryName = true;
      newState.repositoryNameError = '';
    }

    this.setState(newState);
  }

  deleteDomainFilter = (item) => {
    const { domainFilters } = this.state;

    const index = domainFilters.indexOf(item);

    if (index > -1) {
      const newDomainFilters = domainFilters.slice();
      newDomainFilters.splice(index, 1);
      this.setState({
        domainFilters: newDomainFilters
      });
    }
  }

  deleteDomainFilterFor = (item) => (event) => {
    event.preventDefault();
    this.deleteDomainFilter(item);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { description, domainFilters, repositoryName } = this.state;
    const { history } = this.props;

    this.setState({ isLoading: true });

    try {
      if (this.mode === 'create') {
        await this.createRepository({
          repositoryName,
          description,
          domainFilters
        });
        await this.createClusterRepository({
          name: repositoryName
        });
        history.push(`/repositories/view/${repositoryName}`);
      } else {
        await this.editRepository({
          repositoryName,
          description,
          domainFilters
        });
        history.push(`/repositories?updated=${this.editRepositoryName}`);
      }
    } catch (e) {
      this.processRepositoryNameErrorResponse(e);
    }
  }

  handleDomainFilterAdd = () => {
    const { domainFilter } = this.state;

    if (domainFilter.length === 0) {
      return;
    }

    this.setState((prevState) => ({
      domainFilters: prevState.domainFilters.concat([prevState.domainFilter]),
      domainFilter: ''
    }));
  }

  validateRepositoryNameResult = (result) => {
    this.setState({
      validatingRepositoryName: false,
      repositoryNameError: result
    });
  }

  async createClusterRepository(data) {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    return axios.post(`${config.get('clusterManagementEndpoint')}/repositories`, data,
      { headers: { Authorization: `Bearer ${token}` } });
  }

  createRepository({
    repositoryName,
    description,
    domainFilters
  }) {
    return API.post('typo-dashboard', '/repositories', {
      body: {
        apiKeyId: repositoryName,
        description,
        domainFilters
      }
    });
  }

  editRepository({
    repositoryName,
    description,
    domainFilters
  }) {
    return API.put('typo-dashboard', `/repositories/${repositoryName}`, {
      body: {
        description,
        domainFilters
      }
    });
  }

  validateDomainFilter() {
    const { domainFilter } = this.state;

    return (domainFilter.trim() !== '');
  }

  validateForm() {
    const { repositoryName, repositoryNameError, validatingRepositoryName } = this.state;

    return (
      repositoryName
      && repositoryNameError === ''
      && !validatingRepositoryName
    );
  }

  renderDomainFiltersTable() {
    const { domainFilters } = this.state;

    const domainFiltersRows = domainFilters.map((domainFilter, index) => (
      <tr
        key={index} // eslint-disable-line react/no-array-index-key
      >
        <td>{domainFilter}</td>
        <td>
          <Button
            type="button"
            value={domainFilter}
            onClick={this.deleteDomainFilterFor(domainFilter)}
            size="sm"
            variant="danger"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
            &nbsp;Remove
          </Button>
        </td>
      </tr>
    ));

    return (
      domainFilters.length > 0 ? (
        <table className="table table-striped table-condensed">
          <thead>
            <tr>
              <th>Activation RegEx</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {domainFiltersRows}
          </tbody>
        </table>
      ) : (
        <div className="alert alert-info">
        Add one by filling up the form below. It can be a
        URL string or a RegEx expression to match many URLs
        like <strong>http://www.app[0-9]+\.com/</strong>.
        </div>
      )
    );
  }


  render() {
    const {
      description, domainFilter, isLoading, isLoadingRepositoryData, repositoryName,
      repositoryNameError, validatingRepositoryName
    } = this.state;

    if (isLoadingRepositoryData) {
      return <Loader />;
    }

    const pageTitle = this.mode === 'create' ? 'Create Repository' : 'Edit Repository';

    return (
      <>
        <Breadcrumb>
          <LinkContainer to="/" exact><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
          <LinkContainer to="/repositories">
            <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col">
            <Title text={pageTitle} />
            <div className="panel panel-default limited-width">
              <div className="panel-body">
                <InfoHelp dismissible={false}>
                Repositories allows you to store data which can be validated through
                Typo. A Repository has associated configurations to enable Typo on Web
                Applications.
                </InfoHelp>
                <Form noValidate>
                  <Form.Group as={Row} controlId="repositoryName">
                    <Form.Label column sm={2}>
                      <strong>Name*</strong>
                    </Form.Label>
                    <Col sm={10}>
                      {this.mode === 'create' ? (
                        <AsyncValidatorFormControl
                          // Generic props
                          autoFocus
                          type="text"
                          value={repositoryName}
                          onChange={this.handleChange}
                          isValid={
                            repositoryName !== ''
                            && repositoryNameError === ''
                            && !validatingRepositoryName
                          }
                          disabled={isLoading || this.mode === 'edit'}
                          placeholder="Only lowercase letters, numbers and hyphens allowed (a-z, 0-9, -)."
                          // AsyncValidatorFormControl specific props
                          errorMessage={repositoryNameError}
                          isRequired
                          isInvalid={repositoryNameError !== ''}
                          validating={validatingRepositoryName}
                          validatorFunction={validateRepositoryName}
                          validationResult={this.validateRepositoryNameResult}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          value={repositoryName}
                          isInvalid={false}
                          disabled
                        />
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="description">
                    <Form.Label column sm={2}>
                    Description
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={description === null ? '' : description}
                        onChange={this.handleChange}
                        placeholder="An optional description"
                        disabled={isLoading}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="urls">
                    <Form.Label column sm={2}>
                    Activation URLs
                    </Form.Label>
                    <Col sm={10}>
                      {this.renderDomainFiltersTable()}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 5, offset: 2 }}>
                      <Form.Control
                        type="text"
                        id="domainFilter"
                        value={domainFilter}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="http://youwebsite.com/section"
                        isValid={domainFilter.trim() !== ''}
                        disabled={isLoading}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        disabled={!this.validateDomainFilter()}
                        onClick={this.handleDomainFilterAdd}
                      >
                        Add activation URL
                      </Button>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                      <LoaderButton
                        variant="primary"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={isLoading}
                        text={this.mode === 'create' ? 'Create' : 'Save'}
                        loadingText={this.mode === 'create' ? 'Creating...' : 'Saving...'}
                        onClick={this.handleSubmit}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              <div className="panel-footer" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ManageRepository.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

export default ManageRepository;
