import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Form, Col, Row, Alert, Breadcrumb
} from 'react-bootstrap';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { API } from 'aws-amplify';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import queryString from 'query-string';
import Loader from '../components/Loader';
import 'codemirror/mode/javascript/javascript';

import getConfigExplanations from './ConfigurationExplainer';
import LoaderButton from '../components/LoaderButton';
import Title from '../components/Title';


class NewConfiguration extends Component {
  constructor(props) {
    super(props);

    this.DEFAULT_CONFIG = JSON.stringify({
      requests: []
    });

    this.file = null;

    this.state = {
      isLoading: true,
      apiKeys: [],
      config: this.DEFAULT_CONFIG,
      domainFilters: [],
      selectedApiKeyIndex: -1,
      selectedDomainFilterIndex: -1,
      selectedApiKeyDescription: '',
      isValid: true
    };
  }

  componentDidMount = async () => {
    const params = queryString.parse(window.location.search);
    const apiKeyIdParam = params.repository;
    let domainFilterParam;

    if (params.domainFilter) {
      domainFilterParam = decodeURI(params.domainFilter);
    }

    try {
      const apiKeys = await this.getRepositories();
      const configurations = await this.getConfigurations();
      this.setState({
        apiKeys,
        configurations,
        domainFilterParam,
        isLoading: false
      });

      if (apiKeyIdParam) {
        const selectedApiKeyIndex = apiKeys.findIndex((apiKey) => apiKey.apiKeyId === apiKeyIdParam);
        this.selectApiKeyByIndex(selectedApiKeyIndex);
      }
    } catch (e) {
      alert(e);
    }
  }

  getRepositories() {
    return API.get('typo-dashboard', '/repositories');
  }

  getConfigurations() {
    return API.get('typo-dashboard', '/configurations');
  }

  getConfigurationExplanations = (configString) => {
    const { isValid } = this.state;

    if (!isValid) {
      return [];
    }

    return getConfigExplanations(configString);
  }

  getConfiguration(selectedDomainFilterIndex) {
    const {
      apiKeys, configurations, domainFilters, selectedApiKeyIndex,
      selectedDomainFilterIndex: stateSelectedDomainFilterIndex
    } = this.state;

    const newSelectedDomainFilterIndex = selectedDomainFilterIndex !== undefined
      ? selectedDomainFilterIndex : stateSelectedDomainFilterIndex;

    if (selectedApiKeyIndex === -1 || newSelectedDomainFilterIndex === -1) {
      return undefined;
    }

    const apiKey = apiKeys[selectedApiKeyIndex];
    const domainFilter = domainFilters[newSelectedDomainFilterIndex];

    return configurations.find(
      (config) => config.apiKeyId === apiKey.apiKeyId && config.domainFilter === domainFilter
    );
  }

  handleChangeEditor = (editor, data, value) => {
    const isValid = this.configIsValid(value);
    this.setState({
      config: value,
      isValid
    });
  }

  handleSubmit = async (event) => {
    const {
      apiKeys, config, domainFilters, selectedApiKeyIndex, selectedDomainFilterIndex
    } = this.state;
    const { history } = this.props;

    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.createOrUpdateConfiguration({
        domainFilter: domainFilters[selectedDomainFilterIndex],
        apiKeyId: apiKeys[selectedApiKeyIndex].apiKeyId,
        content: config
      });
      history.push('/configurations');
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeDomainFilter = (event) => {
    // Verify if there is an existing configuration for the domain key
    const selectedDomainFilterIndex = event.target.value;
    this.selectDomainFilterByIndex(selectedDomainFilterIndex);
  }

  handleChangeApiKey = (event) => {
    const selectedApiKeyIndex = event.target.value;
    this.selectApiKeyByIndex(selectedApiKeyIndex);
  }

  selectApiKeyByIndex(selectedApiKeyIndex) {
    const { apiKeys, domainFilterParam } = this.state;

    let domainFilters = [];

    let selectedApiKeyDescription = '';

    if (selectedApiKeyIndex !== -1) {
      const apiKey = apiKeys[selectedApiKeyIndex];
      selectedApiKeyDescription = apiKey.description ? apiKey.description : '';

      domainFilters = apiKey.domainFilters;
    }

    this.setState({
      selectedApiKeyIndex,
      selectedDomainFilterIndex: -1,
      domainFilters,
      selectedApiKeyDescription
    });

    // TODO: Refactor so we can call setState just one time with the full state.
    if (selectedApiKeyIndex !== -1) {
      if (domainFilterParam) {
        const selectedDomainFilterIndex = domainFilters.findIndex(
          (domainFilter) => domainFilter === domainFilterParam
        );

        this.selectDomainFilterByIndex(selectedDomainFilterIndex);
      }
    }
  }

  selectDomainFilterByIndex(selectedDomainFilterIndex) {
    let config = this.DEFAULT_CONFIG;
    this.setState({
      selectedDomainFilterIndex
    });
    // TODO: change the getConfiguration to accept the domainFilter
    if (selectedDomainFilterIndex !== -1) {
      config = this.getConfiguration(selectedDomainFilterIndex);
      config = (config && config.content) || this.DEFAULT_CONFIG;
    }

    this.setState({
      config
    });
  }

  apiKeySelected() {
    const { selectedApiKeyIndex } = this.state;

    return selectedApiKeyIndex !== -1;
  }

  configIsValid() {
    try {
          eval("(" + this.state.config + ")"); // eslint-disable-line
    } catch (e) {
      return false;
    }
    return true;
  }

  createOrUpdateConfiguration(configuration) {
    const existingConfig = this.getConfiguration();
    if (existingConfig) {
      return API.put('typo-dashboard', `/configurations/${existingConfig.configurationId}`, {
        body: configuration
      });
    }
    return API.post('typo-dashboard', '/configurations', {
      body: configuration
    });
  }

  validateForm() {
    const { config, selectedDomainFilterIndex, selectedApiKeyIndex } = this.state;
    return this.configIsValid(config) && selectedApiKeyIndex !== -1 && selectedDomainFilterIndex !== -1;
  }

  async updateConfigurations() {
    try {
      const configurations = await this.getConfigurations();
      this.setState({
        configurations
      });
    } catch (e) {
      alert(e);
    }
  }

  renderScreen() {
    const {
      apiKeys, config, domainFilters, isLoading, isValid, selectedApiKeyDescription,
      selectedApiKeyIndex, selectedDomainFilterIndex
    } = this.state;

    let configurationExplanations = '';
    const configurationExplanationsItems = this.getConfigurationExplanations(config).map(
      (explanation, index) => <li key={index}>{explanation}</li> // eslint-disable-line react/no-array-index-key
    );

    if (configurationExplanationsItems.length > 0) {
      configurationExplanations = <ul>{configurationExplanationsItems}</ul>;
    }

    let apiKeyOptions = apiKeys.map((apiKey, index) => {
      const { apiKeyId } = apiKey;
      return (
        <option
          style={{ backgroundColor: '#ccc' }}
          key={index} // eslint-disable-line react/no-array-index-key
          value={index}
        >
          {apiKeyId}
        </option>
      );
    });

    apiKeyOptions = apiKeyOptions.concat([<option key={-1} value={-1}>-- Select a Repository ---</option>]);

    let domainFilterOptions = domainFilters.map((domainFilter, index) => {
      const options = (
        <option
          key={index} // eslint-disable-line react/no-array-index-key
          value={index}
        >
          {domainFilter}
        </option>
      );
      return options;
    });

    domainFilterOptions = domainFilterOptions.concat(
      [<option key={-1} value={-1}>-- Select an activation URL --</option>]
    );

    return (
      <>
        <Alert variant="primary">
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
          You must configure each Activation URL to define which requests and forms will be processed by Typo.
        </Alert>
        <div className="panel panel-default">

          <div className="panel-body">
            <Form>
              <Form.Group as={Row} controlId="apiKey">
                <Form.Label column sm={2}>
                  <strong>Repository*</strong>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control as="select" value={selectedApiKeyIndex} onChange={this.handleChangeApiKey}>
                    {apiKeyOptions}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="description">
                <Form.Label column sm={2}>Description</Form.Label>
                <Col sm={10}>
                  <Form.Control readOnly plaintext value={selectedApiKeyDescription} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="domainFilter">
                <Form.Label column sm={2}>
                                  Activation URL
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    disabled={!this.apiKeySelected()}
                    value={selectedDomainFilterIndex}
                    onChange={this.handleChangeDomainFilter}
                  >
                    {domainFilterOptions}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="config">
                <Form.Label column sm={2}>
                                  Configuration
                </Form.Label>
                <Col sm={10}>
                  {(selectedDomainFilterIndex !== -1)
                    ? (
                      <>
                        {isValid ? (
                          <span>
                            <FontAwesomeIcon icon={faCheck} /> Valid configuration
                          </span>
                        ) : (
                          <span>
                            <FontAwesomeIcon icon={faExclamation} /> Invalid configuration format
                          </span>
                        )}
                        <CodeMirror
                          value={config}
                          autoCursor={false}
                          options={{
                            mode: 'javascript',
                            theme: 'material',
                            lineNumbers: true
                          }}
                          onChange={this.handleChangeEditor}
                        />
                      </>
                    ) : (
                      <Form.Control readOnly plaintext value="Select a URL filter above to change its configuration." />
                    )}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={2}>Explanation</Form.Label>
                <Col sm={10}>
                  {selectedDomainFilterIndex !== -1 && (
                    <Alert variant="info">
                      <Alert.Heading as="p"><strong>Above configuration will:</strong></Alert.Heading>
                      {configurationExplanations}
                    </Alert>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                  <LoaderButton
                    variant="primary"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={isLoading}
                    text="Save"
                    loadingText="Saving…"
                    onClick={this.handleSubmit}
                  />
                </Col>
              </Form.Group>
            </Form>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to="/"><Breadcrumb.Item href="/">Home</Breadcrumb.Item></LinkContainer>
            <LinkContainer to="/repositories">
              <Breadcrumb.Item href="/repositories">Repositories</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to="/configurations">
              <Breadcrumb.Item href="/configurations">Configurations</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
          <Title text="Configure Repository" />
          {isLoading ? <Loader /> : this.renderScreen()}
        </Col>
      </Row>
    );
  }
}

NewConfiguration.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default NewConfiguration;
